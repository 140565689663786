const Version251 = () => {
  const scrollToSection = sectionId => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div>
      <h1 className="blog-title">DISGENET v25.1: What’s New?</h1>
      <div>
        <p className="blog-paragraph">
          Discover our biggest data expansion to date with DISGENET v25.1—your platform for deeper, more
          actionable disease-genomics insights to accelerate research and advance precision medicine.
        </p>
        <h5 className="blog-sub-subtitle">DISGENET version 25.1 includes:</h5>
        <ul className="blog-list">
          <li className="blog-paragraph" onClick={() => scrollToSection('expanded-sources')}>
            <button>
              <strong>Expanded Data Sources for In-Depth Research</strong>
            </button>
          </li>
          <li className="blog-paragraph" onClick={() => scrollToSection('integrated-gwas')}>
            <button>
              <strong>Integrated GWAS Data for Targeted Filtering</strong>
            </button>
          </li>
          <li className="blog-paragraph" onClick={() => scrollToSection('ancestry')}>
            <button>
              <strong>Personalized Ancestry-Specific Insights</strong>
            </button>
          </li>
          <li className="blog-paragraph" onClick={() => scrollToSection('enhanced-biomarkers')}>
            <button>
              <strong>Enhanced Biomarker Discovery with Improved NLP</strong>
            </button>
          </li>
          <li className="blog-paragraph" onClick={() => scrollToSection('improved-chemical-searches')}>
            <button>
              <strong>Improved Chemical Searches & Pharmacogenomic Discovery</strong>
            </button>
          </li>
        </ul>
      </div>
      <br />
      <div id="expanded-sources">
        {' '}
        <h3 className="blog-subtitle">A Data Revolution to Power Your Discoveries</h3>
        <p className="blog-paragraph">
          In our latest release, we’re not just expanding the breadth of our data—we’re enhancing its depth to
          empower your research with unprecedented detail.
        </p>
        <p className="blog-paragraph">
          DISGENET v25.1 introduces a comprehensive update to our data sources, providing you with the most
          robust, up-to-date evidence available for target identification, drug safety assessments, and
          biomarker discovery.
        </p>
        <img
          src="../../static/images/blog/8_disgenet-version-25.1-data-increase.jpg"
          alt="DISGENET v25.1 Data Increase"
          className="blog-image"
          style={{
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '90%',
            height: 'auto',
          }}
        />
        <br />
        <h5 className="blog-sub-subtitle">Key data increases include:</h5>
        <ul className="blog-list">
          <li className="blog-paragraph">
            <strong>Variants:</strong> Up by 96% (over 1.4 million)
          </li>
          <li className="blog-paragraph">
            <strong>Chemicals & Drugs:</strong> Up by 262% (over 14,000)
          </li>
          <li className="blog-paragraph">
            <strong>Variant-Disease Associations (VDAs):</strong> Up by 253% (over 4.3 million)
          </li>
        </ul>
        <p className="blog-paragraph">
          This significant expansion helps you cut through data silos and offers a richer, more reliable
          dataset, enabling you to base your research decisions on a solid foundation of high-quality
          evidence.
        </p>
      </div>
      <br />
      <div id="integrated-gwas">
        <h3 className="blog-subtitle">Clarity in the Complexity</h3>
        <h5 className="blog-sub-subtitle">Advanced GWAS Integration & Filtering</h5>
        <p className="blog-paragraph">
          For drug R&D teams and clinical labs, faster, more targeted research is now possible. DISGENET v25.1
          integrates advanced Genome-Wide Association Study (GWAS) data from both the UK Biobank and FinnGen.
        </p>
        <p className="blog-paragraph">Our new biobank filters allow you to:</p>
        <ul className="blog-list">
          <li className="blog-paragraph">
            Isolate large-scale GWAS data by choosing UK Biobank, FinnGen, or both to focus on
            population-scale insights.
          </li>
          <li className="blog-paragraph">
            Contextualize your findings by easily comparing biobank-sourced data with curated datasets and the
            latest scientific literature, helping you quickly focus on the most relevant associations for your
            research.
          </li>
        </ul>
        <p className="blog-paragraph">
          These improvements enable faster, more targeted research, ensuring you can focus on what truly
          matters.
        </p>
      </div>
      <br />
      <div id="ancestry">
        <h3 className="blog-subtitle">Precision Medicine in Action</h3>
        <h5 className="blog-sub-subtitle">Ancestry-Specific Insights</h5>
        <p className="blog-paragraph">
          Precision medicine relies on understanding the genetic diversity that defines patient populations.
          With DISGENET v25.1, you can now access enhanced ancestry-specific insights that are critical for
          developing targeted therapies.
        </p>
        <img
          src="../../static/images/blog/8_Ancestry-disgenet-v25.1.jpg"
          alt="Ancestry specific insights"
          className="blog-image"
          style={{
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '90%',
            height: 'auto',
          }}
        />
        <br />
        <h5 className="blog-sub-subtitle">For Web Platform Users:</h5>
        <ul className="blog-list">
          <li className="blog-paragraph">
            Gain deeper insights into genetic variation across diverse populations with detailed GWAS summary
            statistics—now including ancestry data.
          </li>
          <li className="blog-paragraph">
            Achieve clearer, more actionable insights for biomarker or target validation with refined
            metrics—Odds Ratio and Beta coefficients are now separated into distinct columns in the VDA
            evidence table.
          </li>
          <li className="blog-paragraph">
            Ensure more accurate findings across a wide range of populations with the inclusion of published
            ancestry data, reflecting real-world genetic diversity in your analysis.
          </li>
        </ul>
        <h5 className="blog-sub-subtitle">For REST API Users:</h5>
        <ul className="blog-list">
          <li className="blog-paragraph">
            Optimize your research for better clinical outcomes by targeting specific populations through
            advanced ancestry filtering—filter variant-disease associations by ancestry using standard
            identifiers from the Hancestro ontology.
          </li>
        </ul>
        <p className="blog-paragraph">
          By integrating these advanced tools into your workflow, you can accelerate your journey toward
          precision medicine that is truly personalized.
        </p>
      </div>
      <br />
      <div id="enhanced-biomarkers">
        {' '}
        <h3 className="blog-subtitle">Faster, More Reliable Biomarker Discovery</h3>
        <h5 className="blog-sub-subtitle">Improved NLP Pipeline for Clinical Trials</h5>
        <p className="blog-paragraph">
          In drug discovery and clinical diagnostics, speed and accuracy are critical. In v25.1, we’ve
          enhanced our Natural Language Processing (NLP) pipeline for clinical trials to improve the capture
          and summarization of biomarker evidence.
        </p>
        <p className="blog-paragraph">
          Now, a quick glance at the GDA evidence table’s Sentence column provides a concise text snippet that
          highlights key biomarker insights—-helping you to accelerate target validation and improve
          diagnostic precision.
        </p>
        <img
          src="../../static/images/blog/8_biomarker-discovery-disgenet-v25.1.jpg"
          alt="Biomarker insights"
          className="blog-image"
          style={{
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '90%',
            height: 'auto',
          }}
        />
        <br />
      </div>
      <br />
      <div id="improved-chemical-searches">
        <h3 className="blog-subtitle">More Flexible Chemical Searches</h3>
        <h5 className="blog-sub-subtitle">Discover Pharmacogenomic Associations</h5>
        <p className="blog-paragraph">
          Navigating complex chemical data is now simpler. Our improved search capabilities support multiple
          identifiers—including ChEMBL, PubChem, DrugBank, and ChEBI—across GDA, VDA, and Chemical endpoints
          in our REST API.
        </p>
        <p className="blog-paragraph">This allows you to:</p>
        <ul className="blog-list">
          <li className="blog-paragraph">
            Easily identify drugs associated with gene-disease associations (GDAs) and variant-disease
            associations (VDAs).
          </li>
          <li className="blog-paragraph">
            Pinpoint potential pharmacogenomic interactions, offering new avenues for drug repurposing and
            biomarker discovery, thanks to the improved recognition of chemicals and drugs.
          </li>
        </ul>
        <img
          src="../../static/images/blog/8_chemical-disgenet-v25.1.jpg"
          alt="Chemical searches"
          className="blog-image"
          style={{
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '90%',
            height: 'auto',
          }}
        />
        <br />
      </div>
      <div>
        <h3 className="blog-subtitle">Your Roadmap to Future Innovations</h3>
        <p className="blog-paragraph">
          DISGENET v25.1 combines rigorous technical enhancements with actionable research benefits to help
          you reduce R&D costs, accelerate target validation, and drive breakthrough discoveries.
        </p>
        <p className="blog-paragraph">
          Whether you’re advancing drug discovery in biotech and pharma or refining diagnostics in clinical
          genomics, our latest release equips you with the data and tools needed to stay ahead.
        </p>
        <div>
          <ul className="blog-list">
            <li>
              <a
                href="https://calendly.com/medbioinformatics/disgenet-demo"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>See DISGENET v25.1 in action</strong>
              </a>
            </li>
            <li>
              <a href="/" target="_blank" rel="noopener noreferrer">
                <strong>Explore the new data (no account required)</strong>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export { Version251 };
