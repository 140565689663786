import React, { useState, useCallback, useEffect } from 'react';

import fetchEndpoint from '../../../Services/fetchEndpoint';
import { getLegendValues, setHeatmapXMargins } from '../Charts/Heatmap/HeatmapHelpers';

import {
  getIdentifiersList,
  getSecondUrl,
  getRemainingPages,
} from '../Charts/Common/MultipleRequestsHelpers';

import { userAppContext } from '../../../Context/Context';

function useHeatmapChart({
  chartInfo,
  initialChartData,
  keySearch,
  source,
  baseUrl,
  parent,
  pageSize,
  view,
  getHeatmapData,
  unifyData,
  width,
  isTopAxisDiseases,
  uniqueIdentViewsVariables,
  names,
}) {
  const [identReqParam, setIdentReqParam] = useState('');
  const [chartData, setChartData] = useState(initialChartData);
  const [pagingInfo, setPagingInfo] = useState({
    currentPage: 0,
    remainingPages: null,
    pageSize: pageSize,
  });
  const [legendValues, setLegendValues] = useState({
    min: 0,
    max: 1,
    divergeAt: 0.5,
  });
  const [isPayloadEmpty, setIsPayloadEmpty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [height, setHeight] = useState(null);
  const [xMargin, setXMargin] = useState(null);

  const { authorization, setIsAuthenticated, setGroups, setAuthError, setAuthorization } =
    userAppContext() || {};

  const getIdentReqData = useCallback(() => {
    setIsLoading(true);

    const orderByParam =
      chartInfo.variable === 'numPMIDsupportingAssociation'
        ? `${chartInfo.variable}_${source}`
        : chartInfo.variable;

    // construct first URL
    const url = `${baseUrl}?source=${source}&${keySearch}&page_number=${0}&page_size=${pageSize}&order_by=${orderByParam}&order=desc`;

    // do the request
    return fetchEndpoint(url, authorization, setIsAuthenticated, setGroups, setAuthError).then(
      responseData => {
        // get back the data
        if (responseData === undefined) {
          return;
        }
        const data = responseData.payload;

        // if payload is empty, show payloadEmpty screen
        if (data?.length === 0) {
          setIsPayloadEmpty(true);
          setIsLoading(false);
          return;
        } else {
          setIsPayloadEmpty(false);
        }

        // get identifiersList and set the state
        const identName = uniqueIdentViewsVariables[parent].identList[view];
        const retrievedIdents = getIdentifiersList(data, identName);
        setIdentReqParam(retrievedIdents);
      },
    );
  }, [source, keySearch, view, chartInfo]);

  const setHeatmapData = useCallback(() => {
    // check if identReqParam hasn't updated and quit the function in that case
    if (identReqParam === '') {
      return;
    }

    // if the remaining pages are 0, set loading state to false and quit the function
    if (pagingInfo.remainingPages === 0) {
      setIsLoading(false);
      return;
    }

    // construct orderByParam variable for second+ requests
    const orderByParam =
      chartInfo.variable === 'numPMIDsupportingAssociation'
        ? `${chartInfo.variable}_${source}`
        : chartInfo.variable;

    // get the param to use for second+ requests
    const identParam = uniqueIdentViewsVariables[parent].secondReqIdent[view];

    // construct the url for second+ requests
    const url = getSecondUrl(baseUrl, source, keySearch, identParam, identReqParam, pagingInfo, orderByParam);

    // do the request
    return fetchEndpoint(
      url,
      authorization,
      setIsAuthenticated,
      setGroups,
      setAuthError,
      setAuthorization,
    ).then(responseData => {
      if (responseData === undefined) {
        return;
      }

      // get back the data and paging info
      const data = responseData.payload;
      const currentPagingInfo = responseData.paging;

      // get the model and the variables to use in the mapping for getting the Heatmap data
      const mappingVariables = uniqueIdentViewsVariables[parent].mappingIdents[view];
      const model = uniqueIdentViewsVariables[parent].model;

      // transform the data into the format the Heatmap component needs it
      const transformedHeatmapChartData = getHeatmapData(data, mappingVariables, model, chartInfo.variable);

      // set the xMargins
      setXMargin(() => setHeatmapXMargins(width, identReqParam));

      // set the data for the chart by unifiying previous data with new one
      setChartData(prevData => {
        const completeData = unifyData(prevData, transformedHeatmapChartData);

        // set the height of the heatmap chart depending on the number of results.
        const heightToAdd = isTopAxisDiseases ? 260 : 190;
        const calculatedHeight = completeData.length * 75 + heightToAdd;
        setHeight(calculatedHeight < 1250 ? calculatedHeight : 1250);

        return completeData;
      });

      // set the values for the legend
      getLegendValues(chartInfo.variable, data, currentPagingInfo, setLegendValues);

      // update the paging info for next requests
      setPagingInfo(prevPagInfo => {
        return {
          currentPage: prevPagInfo.currentPage + 1,
          remainingPages: getRemainingPages(currentPagingInfo),
          pageSize: pageSize,
        };
      });
    });
  }, [identReqParam, pagingInfo.currentPage]);

  useEffect(() => {
    getIdentReqData();

    return () => {
      // DO NOT ERASE. The component won't work correctly otherwise
      setChartData(initialChartData);
      setPagingInfo({
        currentPage: 0,
        remainingPages: null,
        pageSize: pageSize,
      });
      setLegendValues({
        min: 0,
        max: 1,
        divergeAt: 0.5,
      });
      setIdentReqParam('');
      setIsLoading(false);
    };
  }, [getIdentReqData]);

  useEffect(() => {
    setHeatmapData();
  }, [setHeatmapData]);

  return {
    chartData,
    isLoading,
    isPayloadEmpty,
    legendValues,
    height,
    identReqParam,
    xMargin,
    setXMargin,
  };
}

export default useHeatmapChart;
