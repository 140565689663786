// replace the user pool region, id, and app client id details
const awsExports_Obj = {
  REGION: process.env.REACT_APP_COGNITO_REGION,
  USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  USER_POOL_APP_CLIENT_ID: process.env.REACT_APP_COGNITO_USER_POOL_APP_CLIENT_ID,
};

export const awsconfig = {
  Auth: {
    Cognito: {
      region: process.env.REACT_APP_COGNITO_REGION,
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      userPoolClientId: process.env.REACT_APP_COGNITO_USER_POOL_APP_CLIENT_ID,
      // identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
      loginWith: {
        email: true,
        oauth: {
          domain: 'disgenet.auth.eu-central-1.amazoncognito.com',
          scopes: ['openid'],
          redirectSignIn: ['https://disgenet.com/'],
          redirectSignOut: ['https://disgenet.com/'],
          responseType: 'code',
          providers: [{ custom: 'entra-test' }, { custom: 'otsuka' }],
        },
      },
      signUpVerificationMethod: 'code',
      userAttributes: {
        email: {
          required: true,
        },
      },
      allowGuestAccess: true,
    },
  },
};

export default awsExports_Obj;
