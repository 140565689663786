import React from 'react';
import { Link, useParams } from 'react-router-dom';
import PostInfo from '../Blog/PostInfo';
import { studies } from './CaseStudiesContent';

const PostImg = ({ image }) => (
  <img
    src={image}
    alt="Case study card image"
    style={{ width: '100%', height: '250px', borderRadius: '10px', objectFit: 'cover' }}
  />
);

const CaseList = () => {
  const { category, tag } = useParams();

  const filteredPosts = studies.filter(study => {
    const matchesCategory = category ? study.category.includes(category) : true;
    const matchesTag = tag ? study.tags.includes(tag) : true;
    return matchesCategory && matchesTag;
  });

  return (
    <div className="container">
      <ul className="archive-posts">
        {filteredPosts.length > 0 ? (
          filteredPosts.map(study => (
            <li key={study.slug} className="glide__slide">
              <Link to={`/${study.slug}`}>
                <div className="post-thumbnail">
                  <PostImg image={study.image} />
                </div>
                <h3 className="gradient-color h3-post">{study.title}</h3>
              </Link>
              <PostInfo className="post-info" category={study.category} date={study.date} />
              <p className="p-post">{study.description}</p>
            </li>
          ))
        ) : (
          <p>No case found for this filter.</p>
        )}
      </ul>
    </div>
  );
};

export { CaseList };
