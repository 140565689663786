import React from 'react';
import { Link } from 'react-router-dom';

function SummaryStudy() {
  return (
    <section>
      <h2 className="mt-3">
        <Link className="gradient-color" to="/case-studies">
          Case Studies
        </Link>
      </h2>
      {/* add the link to the case studies in order here */}
      <Link to="/how-harvard-accelerates-research-with-disgenet" className="summary-list text-wrap">
        How Harvard Accelerates Research & Student Learning With DISGENET
      </Link>
    </section>
  );
}

export default SummaryStudy;
