import React from 'react';
import { TopRightMenu } from '../NavMenus/TopNavigation';
import Logo from '../Common/Logo';

export default function CommonHeader() {
  return (
    <section className="common-header">
      <div className="header-content">
        <div>
          <Logo />
        </div>
        <div>
          <TopRightMenu />
        </div>
      </div>
    </section>
  );
}

