import React from 'react';

const data = [
  {
    category: 'Genes',
    v244: 26798,
    v251: 29460,
    TotalIncrease: 2667,
    percentIncrease: 9.95,
  },
  {
    category: 'Diseases/traits',
    v244: 39972,
    v251: 42410,
    TotalIncrease: 2438,
    percentIncrease: 6.1,
  },
  {
    category: 'Variants',
    v244: 728547,
    v251: 1425708,
    TotalIncrease: 697161,
    percentIncrease: 95.69,
  },
  {
    category: 'Chemicals',
    v244: 4044,
    v251: 14652,
    TotalIncrease: 10608,
    percentIncrease: 262.31,
  },
  {
    category: 'GDAs',
    v244: 1957356,
    v251: 2001092,
    TotalIncrease: 43496,
    percentIncrease: 2.22,
  },
  {
    category: 'VDAs',
    v244: 1245570,
    v251: 4397758,
    TotalIncrease: 3152188,
    percentIncrease: 253.07,
  },
  {
    category: 'Publications',
    v244: 1572260,
    v251: 1526599,
    TotalIncrease: -45661,
    percentIncrease: 2.9,
  },
];

function StatisticsSummaryTable() {
  return (
    <div>
      <h3 className="mt-5">Summary stats for latest release:</h3>
      <div className="table-responsive">
        <table className="table panther-table" style={{ float: 'middle', margin: '10px 20px 60px 10px' }}>
          <ReleasesTables />
        </table>
      </div>
      <br />
      <img
        src="../../static/images/Release_GenoPheno.svg"
        style={{
          float: 'middle',
        }}
        alt="DISGENET statistics"
      />
    </div>
  );
}

function ReleasesTables() {
  return (
    <>
      <thead className="text-center">
        <tr>
          <th></th>
          <th colSpan="1" className="border-right">
            2024
          </th>
          <th colSpan="1" className="border-right">
            2025
          </th>
          <th colSpan="3"></th>
        </tr>
      </thead>
      <thead className="text-center">
        <tr>
          <th></th>
          <th className="border-right">V24.4</th>
          <th className="border-right">V25.1</th>
          <th>
            Total increase <br />
            (25.1 vs 24.4)
          </th>
          <th>
            % increase <br />
            (25. vs 24.4)
          </th>
        </tr>
      </thead>
      <tbody className="text-center">
        {data.map((row, index) => (
          <tr key={index}>
            <td className="border-right">
              <strong>{row.category}</strong>
            </td>
            <td className="border-right">{row.v244}</td>
            <td className="border-right">{row.v251}</td>
            <td>{row.TotalIncrease}</td>
            <td>{row.percentIncrease}</td>
          </tr>
        ))}
      </tbody>
    </>
  );
}

export default StatisticsSummaryTable;
