import React from 'react';
import { TableFromFile } from '../Tsv_parser';

function AboutVariantTypes() {
  return (
    <div>
      {' '}
      <h2 className="mt-5">
        Distribution of variants, and variant-disease associations according to the type of variant
      </h2>
      <div className="table-responsive">
        <table className="table panther-table" style={{ float: 'middle', margin: '10px 10px 0px 30px' }}>
          <thead className="text-center">
            <tr>
              <th>Type of Variant</th>
              <th>Number of variants</th>
              <th>Number of VDAS</th>
            </tr>
          </thead>
          <tbody className="text-center">
            <TableFromFile filePath="../../static/files/variant_type_stats.tsv" />
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default AboutVariantTypes;
