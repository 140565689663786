import React from 'react';

function Statistics251() {
  return (
    <div>
      <p>The current version of DISGENET (v25.1) contains:</p>
      <ul>
        <li className="bullet-item">
          <b>2,001,092</b> gene-disease associations (GDAs), between <b>29,460</b> genes and <b>42,280</b>{' '}
          diseases and phenotypes
          <ul>
            <li className="bullet-item">
              <b>6,405,781</b> evidences for GDAs, supported by <b>1,591,451</b> publications
            </li>
            <li className="bullet-item">
              <b>340,000</b> GDAs involving <b>13,000</b> diseases that have been detected in animal models
            </li>
            <li className="bullet-item">
              <b>375,380</b> GDAs are annotated with <b>14,625</b> chemicals (relating <b>14,093</b> genes and{' '}
              <b>13,595</b> diseases)
            </li>
          </ul>
        </li>
        <li className="bullet-item">
          <b>4,397,758</b> variant-disease associations (VDAs), between <b>1,425,708</b> variants and{' '}
          <b>19,075</b> diseases and phenotypes.
          <ul>
            <li className="bullet-item">
              <b>6,889,441</b> evidences for the VDAs, supported by <b>188,695</b> publications
            </li>
            <li className="bullet-item">
              <b>26,778</b> VDAs are annotated with <b>2,095</b> chemicals (relating <b>7,892</b> variants and{' '}
              <b>2,903</b> diseases)
            </li>
          </ul>
        </li>
        <li className="bullet-item">
          Over <b>28M</b> disease-disease associations.
        </li>
        <li className="bullet-item">
          This release provides annotations for <b>14,652</b> chemicals and drugs associated with GDAs and
          VDAs.
        </li>
        <li className="bullet-item">
          In total, DISGENET contains a corpus of <b>1,526,599</b> publications that support the
          genotype-phenotype associations.
        </li>
        <li className="bullet-item">
          There are <b>13,295,404</b> individual pieces of evidence in the database supporting{' '}
          <b>6,398,610</b> genotype-phenotype associations (GDAs & VDAs)
        </li>
      </ul>
    </div>
  );
}

export default Statistics251;
