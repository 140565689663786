import React, { useState } from 'react';

const ActivationErrorContent = () => {
  const [copied, setCopied] = useState(false);

  const copyEmail = () => {
    const email = 'support@disgenet.com';
    navigator.clipboard.writeText(email);
    setCopied(true);
  };

  const getUrlParams = () => {
    const params = new URLSearchParams(window.location.search);
    const entries = params.entries();
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  };

  const urlParams = getUrlParams();
  let urlStatus = urlParams.status ? urlParams.status : '';
  // Only 3 allowed params:
  if (!['BrokenLink', 'ExpiredCode', 'ResentCode'].includes(urlStatus)) {
    urlStatus = '';
  }

  return (
    <section>
      <style>{`
        .copy-info {
          cursor: pointer;
          display: inline-flex;
          align-items: center;
          text-decoration: underline;
          color: rgb(190,0,128);
        }
        .a-search{
          color: rgb(190,0,128);
        }
      `}</style>
      <div className="container">
        <div data-wow-delay="0.3s" className="p-4">
          {!urlStatus && (
            <>
              <h2 className="pb-4">Activation Error</h2>
              <h5 className="pb-4">There has been an error.</h5>
              <h6>
                Please contact us at{' '}
                <span className="copy-info" title="copy" onClick={copyEmail}>
                  support@disgenet.com
                </span>
                {copied && <span> copied!</span>}
              </h6>
            </>
          )}
          {urlStatus == 'BrokenLink' && (
            <>
              <h2 className="pb-4">Activation Error</h2>
              <h5 className="pb-4">Something went wrong with your link</h5>
              <h6>
                Please try again or contact us at{' '}
                <span className="copy-info" title="copy" onClick={copyEmail}>
                  support@disgenet.com
                </span>
                {copied && <span> copied!</span>}
              </h6>
            </>
          )}
          {urlStatus == 'ExpiredCode' && (
            <>
              <h2 className="pb-4">Activation Error</h2>
              <h5 className="pb-4">Your confirmation link has expired.</h5>
              <h6>
                Please contact us at{' '}
                <span className="copy-info" title="copy" onClick={copyEmail}>
                  support@disgenet.com
                </span>{' '}
                to manually activate your account.
                {copied && <span> copied!</span>}
              </h6>
            </>
          )}
          {urlStatus == 'ResentCode' && (
            <>
              <h2 className="pb-4">Your confirmation link has expired.</h2>
              <h5 className="pb-4">Please check your inbox, a new one has been resent.</h5>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default ActivationErrorContent;
