import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { posts } from './PostGenerator';
import PostInfo from './PostInfo';

const PostImg = ({ image }) => (
  <img
    src={image}
    alt="Post"
    style={{ width: '100%', height: '250px', borderRadius: '10px', objectFit: 'cover' }}
  />
);

const BlogList = () => {
  const { category, tag } = useParams();

  const filteredPosts = posts.filter(post => {
    const matchesCategory = category ? post.category.includes(category) : true;
    const matchesTag = tag ? post.tags.includes(tag) : true;
    return matchesCategory && matchesTag;
  });

  return (
    <div className="container">
      <ul className="archive-posts">
        {filteredPosts.length > 0 ? (
          filteredPosts.map(post => (
            <li key={post.postName} className="glide__slide">
              <Link to={`/${post.postName}`}>
                <div className="post-thumbnail">
                  <PostImg image={post.image} />
                </div>
                <h3 className="gradient-color h3-post">{post.title}</h3>
              </Link>
              <PostInfo className="post-info" category={post.category} date={post.date} />
              <p className="p-post">{post.description}</p>
            </li>
          ))
        ) : (
          <p>No posts found for this filter.</p>
        )}
      </ul>
    </div>
  );
};

export { BlogList };
