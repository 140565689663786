import React from 'react';
import { HashLink } from 'react-router-hash-link';

function AboutAttributes() {
  return (
    <div>
      <h2>Data Attributes</h2>
      <p>
        In order to ease the interpretation and analysis of gene-disease, variant-disease associations, and
        disease-disease associations we provide the following information for the data.
      </p>
      <div id="section41">
        <h3>Genes</h3>
        <p>
          <strong>Genes in DISGENET are annotated with: </strong>
        </p>
        <ul>
          <li className="bullet-item">
            The official gene symbol, from the{' '}
            <a className="tt" target="_blank" rel="noreferrer" href="http://www.ncbi.nlm.nih.gov/gene/">
              NCBI
            </a>
          </li>
          <li className="bullet-item">
            The{' '}
            <a className="tt" target="_blank" rel="noreferrer" href="http://www.ncbi.nlm.nih.gov/gene/">
              NCBI
            </a>{' '}
            Official Full Name
          </li>
          <li className="bullet-item">
            The{' '}
            <a className="tt" target="_blank" rel="noreferrer" href="https://www.ensembl.org/">
              {' '}
              Ensembl
            </a>{' '}
            Gene identifier
          </li>
          <li className="bullet-item">
            The{' '}
            <a className="tt" target="_blank" rel="noreferrer" href="http://www.uniprot.org/uniprot/">
              Uniprot{' '}
            </a>{' '}
            accession
          </li>
          <li className="bullet-item">
            The{' '}
            <HashLink to="#specificity" data-target="#metrics" data-toggle="tab" className="js-fix-scroll">
              Disease Specificity Index{' '}
            </HashLink>
            (DSI)
          </li>
          <li className="bullet-item">
            The{' '}
            <HashLink to="#pleiotropy" data-target="#metrics" data-toggle="tab" className="js-fix-scroll">
              Disease Pleiotropy Index{' '}
            </HashLink>
            (DPI)
          </li>
          <li className="bullet-item">
            The pLI, defined as the probability of being loss-of-function intolerant, is a gene constraint
            metric provided by the{' '}
            <a className="tt" target="_blank" rel="noreferrer" href="https://gnomad.broadinstitute.org/">
              GNOMAD
            </a>{' '}
            consortium. A gene constraint metric aims at measuring how the naturally occurring LoF (loss of
            function) variation has been depleted from a gene by natural selection (in other words, how
            intolerant is a gene to LoF variation). LoF intolerant genes have a high pLI value (&gt;=0.9),
            while LoF tolerant genes have low pLI values (&lt;=0.1). The LoF variants considered are nonsense
            and essential splice site variants.
          </li>
          <li className="bullet-item">
            The protein class is obtained from the top level category from the{' '}
            <a className="tt" target="_blank" rel="noreferrer" href="http://drugtargetontology.org/">
              Chemical Target Ontology
            </a>
            .
          </li>
          <li className="bullet-item">
            The pathways are obtained from top-level pathways from{' '}
            <a className="tt" target="_blank" rel="noreferrer" href="https://reactome.org/">
              Reactome
            </a>
            .
          </li>
        </ul>
      </div>
      <div id="section42">
        <h3>Variants</h3>
        <strong>
          <p>Variants in DISGENET are annotated with:</p>
        </strong>
        <ul>
          <li className="bullet-item">
            The position in the chromosome according to <b>dbSNP</b>, the{' '}
            <a className="tt" target="blank" href="http://www.ncbi.nlm.nih.gov/projects/SNP/">
              NCBI Short Genetic Variations database{' '}
            </a>
          </li>
          <li className="bullet-item">The reference and alternative alleles</li>
          <li className="bullet-item">
            The class of the variant: SNP, deletion, insertion, indel, somatic SNV, substitution, sequence
            alteration, and tandem repeat{' '}
          </li>
          <li className="bullet-item">
            The allelic frequency in genomes and exomes according to <b>GNOMAD</b>, the &nbsp;
            <a className="tt" target="blank" href="http://gnomad.broadinstitute.org/">
              Genome Aggregation Database
            </a>
            . The data spans 125,748 exomes and 71,702 genomes from unrelated individuals sequenced as part of
            various disease-specific and population genetic studies (
            <a className="tt" target="blank" href="http://gnomad.broadinstitute.org/downloads">
              release 2.1.1
            </a>{' '}
            for exomes and 3.0 for genomes)
          </li>
          <li className="bullet-item">
            The most severe consequence type according to the{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="http://www.ensembl.org/info/genome/variation/predicted_data.html"
            >
              Variant Effect Predictor
            </a>
          </li>
          <li className="bullet-item">
            The gene corresponding to the consequence type assigned by VEP, or the one assigned by dbSNP
            database
          </li>
          <li className="bullet-item">
            The{' '}
            <HashLink to="#specificity" data-target="#metrics" data-toggle="tab" className="js-fix-scroll">
              Disease Specificity Index
            </HashLink>{' '}
            (DSI)
          </li>
          <li className="bullet-item">
            The{' '}
            <HashLink to="#pleiotropy" data-target="#metrics" data-toggle="tab" className="js-fix-scroll">
              Disease Pleiotropy Index{' '}
            </HashLink>{' '}
            (DPI)
          </li>
          <li className="bullet-item">
            The risk allele is the allele associated with the phenotype as provided by the GWAS catalogue or
            ClinVar.
          </li>
          <li className="bullet-item">
            <strong> Pathogenicity scores SIFT and PolyPhen-2:</strong> For human variants that are predicted
            to result in an amino acid substitution, we provide the pathogenicity predictions from SIFT and
            PolyPhen-2 obtained from{' '}
            <a
              rel="noreferrer"
              target="_blank"
              href="https://www.ensembl.org/info/genome/variation/prediction/protein_function.html"
            >
              VEP
            </a>
            . For each variant, we provide the max value of Polyphen and the minimum value of SIFT.
            <li className="bullet-item">
              <a rel="noreferrer" target="_blank" href="http://sift.bii.a-star.edu.sg/">
                SIFT
              </a>{' '}
              predicts whether an amino acid substitution is likely to affect protein function based on
              sequence homology and the physicochemical similarity between the alternate amino acids. The
              score is the normalized probability that the amino acid change is tolerated; scores near zero
              are more likely to be deleterious. A qualitative prediction can be derived from this score such
              that substitutions with a score {'<'} 0.05 are called {'deleterious'} and all others are called
              {'tolerated'}.
            </li>
            <div className="table-responsive">
              <table
                className="table panther-table"
                style={{
                  margin: '10px 50px 10px 50px',
                }}
              >
                <thead className="text-center">
                  <td>
                    <strong>SIFT value</strong>
                  </td>
                  <td>
                    <strong>Qualitative prediction</strong>
                  </td>
                </thead>
                <tbody className="text-center">
                  <td>smaller than 0.05</td>
                  <td>{'Deleterious'}</td>
                </tbody>
              </table>
            </div>
            <li className="bullet-item">
              <a rel="noreferrer" target="_blank" href="http://genetics.bwh.harvard.edu/pph2/">
                PolyPhen-2
              </a>{' '}
              predicts the effect of an amino acid substitution on the structure and function of a protein
              using sequence homology, Pfam annotations, 3D structures from PDB where available, and several
              other databases and tools (including DSSP, ncoils etc.). The PolyPhen score represents the
              probability that a substitution is damaging, so values nearer one are more confidently predicted
              to be deleterious (note that this is the opposite of SIFT). A qualitative classification can be
              derived from the PolyPhen score as follows:
            </li>
          </li>
          <div className="table-responsive">
            <table
              className="table panther-table"
              style={{
                margin: '10px 50px 10px 50px',
              }}
            >
              <thead className="text-center">
                <td>
                  <strong>Polyphen value</strong>
                </td>
                <td>
                  <strong>Qualitative prediction</strong>
                </td>
              </thead>
              <tbody className="text-center">
                <td>greater than 0.908</td>
                <td>"Probably Damaging"</td>
              </tbody>
              <tbody className="text-center">
                <td style={{ width: '500px' }}>greater than 0.446 and less than or equal to 0.908</td>
                <td>"Possibly Damaging"</td>
              </tbody>
              <tbody className="text-center">
                <td>less than or equal to 0.446</td>
                <td>"Benign"</td>
              </tbody>
              <tbody className="text-center">
                <td>Unknown</td>
                <td>Unknown</td>
              </tbody>
            </table>
          </div>
        </ul>
      </div>
      <div id="section43">
        <h3>Diseases</h3>
        <p>
          The vocabulary used for diseases in DISGENET is the
          <a href="https://www.nlm.nih.gov/research/umls/index.html" target="_blank" rel="noreferrer">
            {' '}
            UMLS<sup>®</sup> Metathesaurus<sup>®</sup>.
          </a>{' '}
          Each disease has a Concept Unique Identifier (CUI) from the Unified Medical Language System
          <sup>®</sup> (UMLS) Metathesaurus<sup>®</sup> (version UMLS 2024AB).
        </p>
        {/* <div>
          <p>
            <strong>Diseases in DISGENET are annotated with: </strong>
          </p>
          <ul>
            <li className="bullet-item">
              The disease name, provided by the{' '}
              <a
                className="tt"
                target="_blank"
                rel="noreferrer"
                href="https://www.nlm.nih.gov/research/umls/"
              >
                UMLS<sup>®</sup> Metathesaurus<sup>®</sup>
              </a>
            </li>
            <li className="bullet-item">
              The{' '}
              <a
                className="tt"
                target="_blank"
                rel="noreferrer"
                href="http://www.nlm.nih.gov/research/umls/META3_current_semantic_types.html"
              >
                UMLS<sup>®</sup> semantic types{' '}
              </a>
              the{' '}
              <a className="tt" target="_blank" rel="noreferrer" href="https://meshb.nlm.nih.gov/treeView">
                MeSH
              </a>{' '}
              class: we classify the diseases according the MeSH hierarchy using the upper level concepts of
              the MeSH tree branch C (Diseases) plus three concepts of the F branch (Psychiatry and
              Psychology: "Behavior and Behavior Mechanisms", "Psychological Phenomena and Processes", and
              "Mental Disorders")
            </li>
            <li className="bullet-item">
              The top level concepts from the{' '}
              <a className="tt" target="_blank" rel="noreferrer" href="http://disease-ontology.org/">
                Human Disease Ontology
              </a>
            </li>
            <li className="bullet-item">
              The top level concepts from the{' '}
              <a className="tt" target="_blank" rel="noreferrer" href="https://hpo.jax.org/app/">
                Human Phenotype Ontology
              </a>
            </li>
            <li className="bullet-item">
              The DISGENET disease type: <b>disease</b>, <b>phenotype</b> and <b>group</b>.
            </li>
          </ul>
        </div> */}
        <div>
          <p>
            <strong>
              We consider a <b>disease</b> entries mapping to the following UMLS<sup>®</sup> semantic types:
            </strong>
          </p>
          <ul>
            <li className="bullet-item">Disease or Syndrome</li>
            <li className="bullet-item">Neoplastic Process</li>
            <li className="bullet-item">Acquired Abnormality</li>
            <li className="bullet-item">Anatomical Abnormality</li>
            <li className="bullet-item">Congenital Abnormality</li>
            <li className="bullet-item">Mental or Behavioral Dysfunction</li>
          </ul>
        </div>
        <div>
          <p>
            <strong>
              We consider a <b>phenotype</b> entries mapping to the following UMLS<sup>®</sup> semantic
              types:
            </strong>
          </p>
          <ul>
            <li className="bullet-item">Pathologic Function</li>
            <li className="bullet-item">Sign or Symptom</li>
            <li className="bullet-item">Finding</li>
            <li className="bullet-item">Laboratory or Test Result</li>
            <li className="bullet-item">Individual Behavior</li>
            <li className="bullet-item">Injury or Poisoning</li>
            <li className="bullet-item">Mental Process</li>
            <li className="bullet-item">Clinical Attribute</li>
            <li className="bullet-item">Organism Attribute</li>
            <li className="bullet-item">Organism Function</li>
            <li className="bullet-item">Physiologic Function</li>
            <li className="bullet-item">Organ or Tissue Function</li>
            <li className="bullet-item">Cell or Molecular Dysfunction</li>
            <li className="bullet-item">Cell Function</li>
            <li className="bullet-item">Behavior</li>
          </ul>
        </div>
        {/* <p>
          These classifications were manually checked. In addition, disease entries referring to disease
          groups such as "Cardiovascular Diseases", "Autoimmune Diseases", "Neurodegenerative Diseases, and
          "Lung Neoplasms" were classified as <b>disease group</b>.
        </p>
        <div>
          Additionally, we have removed terms considered as diseases by other sources, but are not strictly
          diseases, such as terms belonging to the following UMLS
          <sup>®</sup> semantic types:
          <ul>
            <li className="bullet-item">Gene or Genome</li>
            <li className="bullet-item">Genetic Function</li>
            <li className="bullet-item">Immunologic Factor</li>
            <li className="bullet-item">Injury or Poisoning</li>
          </ul>
        </div> */}
      </div>
      <div id="section44">
        <h3>Chemicals</h3>
        <p>
          Chemicals in DISGENET are represented by an internal identifier, and mapped to several identifiers
          such as ChEMBL and PubChem.{' '}
        </p>
        {/* <p>
          The vocabulary used for chemicals in DISGENET is the
          <a href="https://www.nlm.nih.gov/research/umls/index.html" target="_blank" rel="noreferrer">
            {' '}
            UMLS<sup>®</sup> Metathesaurus<sup>®</sup>.
          </a>{' '}
          Each chemical has a Concept Unique Identifier (CUI) from the Unified Medical Language System
          <sup>®</sup> (UMLS) Metathesaurus<sup>®</sup> (version UMLS 2019AB).
        </p> */}

        <p>
          <strong>Chemicals in DISGENET are annotated with: </strong>
        </p>

        <ul>
          {/* 
          The DISGENET chemical identifier.
          The chemical name, obtained from ChEMBL whenever available, 
          The number of publications with chemical mentions (N. PMIDS)
          The number of publications that support GDAs with chemical mentions (N. PMIDs GDA)
          The number of publications that support VDAs with chemical mentions (N. PMIDs VDA)
          “Chemical Effect”: classification of the effect of a chemical on the disease, provided processing expert-curated resources that provide adverse effec
          t information and indications. By using the “Chemical Effect” attribute, you can select chemicals that are used for the treatment of a disease (therapeutic) 
          or lead to a disease (toxicity). This attribute is available in the GDA and
          */}
          <li className="bullet-item">The DISGENET chemical identifier</li>
          <li className="bullet-item">The chemical name, obtained from ChEMBL whenever available</li>
          <li className="bullet-item">The number of publications with chemical mentions (N. PMIDS)</li>
          <li className="bullet-item">
            The number of publications that support GDAs with chemical mentions (N. PMIDs GDA)
          </li>
          <li className="bullet-item">
            The number of publications that support VDAs with chemical mentions (N. PMIDs VDA)
          </li>
          <li className="bullet-item">
            “Chemical Effect”: classification of the effect of a chemical on the disease, provided processing
            expert-curated resources that provide adverse effect information and indications. By using the
            “Chemical Effect” attribute, you can select chemicals that are used for the treatment of a disease
            (therapeutic) or lead to a disease (toxicity). This attribute is available in the GDA and VDA
            Evidence tables with chemical annotations.
          </li>
        </ul>
      </div>
      <div id="section45">
        <h3>Gene-Disease Associations</h3>
        <ul>
          <li className="bullet-item">
            The{' '}
            <HashLink to="#gdaScore" data-target="#metrics" data-toggle="tab" className="js-fix-scroll">
              DISGENET score
            </HashLink>
          </li>
          <li className="bullet-item">
            The{' '}
            <HashLink
              to="#assoctypeont"
              data-target="#assoctypeont"
              data-toggle="tab"
              className="js-fix-scroll"
            >
              DISGENET Gene-Disease Association Type
            </HashLink>
          </li>
          <li className="bullet-item">
            The Evidence Level The Evidence Level (EL) is a metric developed by ClinGen that measures the
            strength of evidence of a gene-disease relationship that correlates to a qualitative
            classification: <i>"Definitive", "Strong", "Moderate", "Limited", "Conflicting Evidence"</i>, or{' '}
            <i>"No Reported Evidence" </i>(
            <a target="_blank" rel="noreferrer" href="https://www.ncbi.nlm.nih.gov/pubmed/28552198">
              Strande <i>et al.</i>, 2017{' '}
            </a>
            ). GDAs that have been reported by ClinGen will have their corresponding Evidence Level.
            Furthermore, we have adapted a similar metric reported by Genomics England PanelApp to correspond
            to the same categories from ClinGen: GDAs marked by Genomics England PanelApp as
            <i>High Evidence</i> are labeled as <i>strong</i> in DISGENET. Those labeled as{' '}
            <i>Moderate Evidence</i> are labeled as <i>moderate</i> and <i>Low Evidence</i> associations are
            labeled as <i>limited</i>. We have labeled GDAs with no evidence level as "No reported evidence"
          </li>
          <li className="bullet-item">
            The{' '}
            <HashLink to="#evindex" data-target="#metrics" data-toggle="tab" className="js-fix-scroll">
              Evidence Index
            </HashLink>
          </li>
          <li className="bullet-item">The year initial: first time that the association was reported</li>
          <li className="bullet-item">The year final: last time that the association was reported</li>
          <li className="bullet-item">
            The publication(s) that reports the gene-disease association, with the{' '}
            <a className="tt" target="_blank" rel="noreferrer" href="http://www.ncbi.nlm.nih.gov/pubmed/">
              {' '}
              Pubmed Identifier
            </a>
          </li>
          <li className="bullet-item">
            A representative sentence from the publication describing the association between the gene and the
            disease (if a representative sentence is not found, we provide the title of the paper)
          </li>
          <li className="bullet-item">The original source reporting the Gene-Disease Association.</li>
        </ul>
      </div>
      <div id="section46">
        <h3>Variant-Disease Associations</h3>
        <ul>
          <li className="bullet-item">
            The{' '}
            <HashLink to="#vdaScore" data-target="#metrics" data-toggle="tab">
              DISGENET score
            </HashLink>
          </li>
          <li className="bullet-item">
            The{' '}
            <HashLink to="#evindex" data-target="#metrics" data-toggle="tab">
              Evidence Index
            </HashLink>
          </li>
          <li className="bullet-item">
            The publication(s) that reports the variant-disease association, with the &nbsp;
            <a className="tt" target="_blank" rel="noreferrer" href="http://www.ncbi.nlm.nih.gov/pubmed/">
              Pubmed Identifier
            </a>{' '}
          </li>
          <li className="bullet-item">The year initial: first time that the association was reported</li>
          <li className="bullet-item">The year final: last time that the association was reported</li>
          <li className="bullet-item">
            A representative sentence from the publication describing the association between the variant and
            the disease (if a representative sentence is not found, we provide the title of the paper){' '}
          </li>
          <li className="bullet-item">The original source reporting the Variant-Disease Association.</li>
          <li className="bullet-item">
            Ancestry: ancestry information from the original GWAS study or identified from the publication by
            text-mining. Ancestry is standardized using the{' '}
            <a href="https://github.com/EBISPOT/hancestro" target="_blank" rel="noreferrer">
              Hancestro ontology
            </a>
            .
          </li>
          <li className="bullet-item">Gender: As reported in the original GWAS study</li>
          <li className="bullet-item">
            OR: Odds ratio associated with strongest SNP risk allele. Note that if an OR {'<'} 1 is reported
            this is inverted, along with the reported allele, so that all ORs are {'>'} 1
          </li>
          <li className="bullet-item">BETA: Beta-coefficient associated with strongest SNP risk allele.</li>
          <li className="bullet-item">p-value: reported p-value for strongest SNP risk allele</li>
          <li className="bullet-item">
            GOF/LOF: indicates that the variant is gain of function (GoF) or loss of function (LoF) mutation
            associated with the phenotype
          </li>
        </ul>
      </div>
      <div id="section47">
        <h3>Disease-Disease Associations</h3>
        <ul>
          <li className="bullet-item">Jaccard Index based on shared genes</li>
          <li className="bullet-item">
            -log(p-value JIg): we provide the minus decimal logarithm of the p-value of JIg
          </li>
          <li className="bullet-item">Jaccard Index based on shared variants</li>
          <li className="bullet-item">
            -log(p-value JIv): we provide the minus decimal logarithm of the p-value of JIv
          </li>
          <li className="bullet-item">
            p-value: the p-value of the JI (for genes or variants) was obtained by a Fisher test. Only those
            DDAs with p-value equal to or smaller than 10<sup>-6</sup> are included in the dataset (for the
            Jaccard index of the genes or the variants)
          </li>
          <li className="bullet-item">
            The Sokal-Sneath semantic similarity distance{' '}
            <a
              className="tt"
              target="_blank"
              rel="noreferrer"
              href="https://pubmed.ncbi.nlm.nih.gov/21463704/"
            >
              (Sánchez et al., 2011)
            </a>
            &nbsp; computed on the taxonomic relations (only is-a type) provided by the Unified Medical
            Language System Metathesaurus®. These types of relations can also be retrieved using the DDA
            Relation “is_similar_to” in the disgenet2r R package{' '}
          </li>
          <li className="bullet-item">
            DDA Relation: semantic relations between diseases obtained from the Unified Medical Language
            System Metathesaurus®. The types of relations available are: has_manifestation,
            has_associated_morphology, manifestation_of, associated_morphology_of, is_finding_of_disease,
            due_to, has_definitional_manifestation, has_associated_finding, definitional_manifestation_of,
            disease_has_finding, cause_of, associated_finding_of, is_similar_to.
          </li>
        </ul>
      </div>
    </div>
  );
}

export default AboutAttributes;
