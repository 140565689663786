import React, { useEffect, useRef, useState } from 'react';
import { userAppContext } from '../../Context/Context';
import getApi from '../../Services/GetApi';
import ResetApiKey from '../../Services/ResetApiKey';

const ProfileApiKey = ({ apiKeyClass }) => {
  const { user, userApiKey, setUserApiKey } = userAppContext();
  const [userResetApiKey, setuserResetApiKey] = useState([]);
  const [apiKeyRequest, setApiKeyRequest] = useState({});
  const [isPassVisible, setIsPassVisible] = useState(false);
  const [copied, setCopied] = useState(false);
  const apiKeyRef = useRef(null);

  useEffect(() => {
    if (user.length === 0) {
      return;
    }

    const fetchedApiKey = getApi(user['cognito:username']).then(response => {
      if (response?.status === 'OK') {
        return response?.payload['api_key'];
      }
    });

    Promise.resolve(fetchedApiKey).then(apiKey => {
      if (apiKey !== userApiKey) {
        setUserApiKey(apiKey);
      }
    });
  }, [userApiKey]);

  const togglePassVisibility = () => {
    setIsPassVisible(prevState => !prevState);
  };

  const handleResetApiKeyData = event => {
    event.preventDefault();
    // const userName = event.target.username.value; // disabled user input since I have id already
    const userName = user?.sub;
    const password = event.target.password.value;

    const userData = [userName, password];
    setuserResetApiKey(userData);
  };

  const handleCopy = () => {
    const apiKeyText = apiKeyRef.current.innerText;
    navigator.clipboard.writeText(apiKeyText);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 10000);
  };

  const apiKeyContent = userApiKey ? userApiKey : <span>Please reset your API key</span>;

  const modalAlert = () => {
    let apiKeyStatus;
    if (apiKeyRequest.status === 'OK') {
      apiKeyStatus = (
        <div className="alert alert-success mt-3" role="alert">
          Successfully reset
        </div>
      );
    } else if (apiKeyRequest.status === 'BAD_REQUEST') {
      apiKeyStatus = (
        <div className="alert alert-danger mt-3" role="alert">
          Incorrect username or password
        </div>
      );
    } else {
      apiKeyStatus = '';
    }
    return apiKeyStatus;
  };

  const alert = modalAlert();

  const handleDeleteUserApiKeyData = () => {
    setApiKeyRequest([]);
    setuserResetApiKey([]);
    document.getElementById('resetForm').reset();
  };

  return (
    <React.Fragment>
      <div className={`${apiKeyClass} `} id="apiKey">
        {user['cognito:groups']?.includes('ROLE_STANDARD') ? (
          <>
            <h6 className="mt-4">
              As a Standard user you do not have access to the REST API. Upgrade your plan to gain access to
              the REST API and the R package. Learn more about our plans here:{' '}
              <strong>
                <a style={{ color: 'rgb(190,0,128)' }} href="/plans">
                  PLANS
                </a>
              </strong>
            </h6>
          </>
        ) : (
          <>
            <p className="input100">Your Rest API Key is: </p>
            <span ref={apiKeyRef} className="label-input100">
              {apiKeyContent}
            </span>

            <span className="copy-clip-board"></span>
            {userApiKey && <button onClick={handleCopy} className="fa fa-copy" />}

            {copied && (
              <span style={{ color: 'green' }} className="copy label-input100 ">
                {' '}
                Copied!
              </span>
            )}
            <div className="wrap-input100" />
            <button
              className="input100 resetApiKeyandPass"
              data-toggle="modal"
              data-target="#resetApiKeyModal"
            >
              Reset Your API Key
            </button>
          </>
        )}
        <div
          className="modal apiKeyModal"
          id="resetApiKeyModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="resetApiKeyModal"
          aria-hidden="false"
        >
          <div className="modal-dialog  modal-dialog-centered" role="document">
            <div className="modal-content modal-sm">
              <div className="modal-header">
                <h5 className="modal-title" id="resetApiKeyModal">
                  Reset your API Key
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={handleDeleteUserApiKeyData}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body m-1">
                <form id="resetForm" onSubmit={handleResetApiKeyData}>
                  {/* <label htmlFor="InputUserName">Email</label> */}
                  {/* <input type="text" className="form-control" id="username" placeholder="Email" required /> */}
                  <div className="password-input">
                    <label className="mt-3" htmlFor="InputPassword">
                      Password
                    </label>
                    <span className="eye-icon-apiReset" onClick={togglePassVisibility}>
                      <i className={`fa ${isPassVisible ? 'fa-eye' : 'fa-eye-slash'}`} aria-hidden="true" />
                    </span>
                  </div>
                  <input
                    type={`${isPassVisible ? 'text' : 'password'}`}
                    className="form-control"
                    id="password"
                    placeholder="password"
                    required
                  />
                  <div className="text-center">
                    {apiKeyRequest.status === 'OK' ? (
                      <button
                        type="close"
                        className="button BtnAccion p-2 px-3 justify-content-center"
                        data-dismiss="modal"
                        onClick={handleDeleteUserApiKeyData}
                      >
                        Close
                      </button>
                    ) : (
                      <button type="submit" className="button BtnApyKey p-2 px-3 justify-content-center">
                        Submit
                      </button>
                    )}
                  </div>
                </form>
                {alert}
              </div>
            </div>
          </div>
        </div>
        <ResetApiKey setApiKeyRequest={setApiKeyRequest} userResetApiKey={userResetApiKey} />
      </div>
    </React.Fragment>
  );
};

export default ProfileApiKey;
