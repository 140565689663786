import React from 'react';
import AboutSourcesGda from './AboutSourcesGda';
import AboutSourcesVda from './AboutSourcesVda';
import AboutSourcesDda from './AboutSourcesDda';

function AboutSources() {
  return (
    <div>
      <h2>Original Data Sources</h2>
      <p>
        DISGENET integrates information on human gene-disease associations (GDAs) and variant-disease
        associations (VDAs) from various repositories specialized in different disease categories that include
        Mendelian, complex, and environmental diseases.
      </p>
      <AboutSourcesGda />
      <AboutSourcesVda />
      <AboutSourcesDda />
    </div>
  );
}

export default AboutSources;
