import React from 'react';

const SuggestionsTable = ({
  suggestionsData,
  selectedItems,
  handleCheckboxSelection,
  searchType,
  selectedFilter,
}) => {
  if (!Array.isArray(suggestionsData) || suggestionsData.length === 0) return null;

  const uniqueResults = new Set();
  const uniqueNotFound = new Set();
  const uniqueValidationErrors = new Set();
  let rowNumber = 1;

  const renderColumnHeader = () => {
    switch (searchType) {
      case 'DISEASES':
        return (
          <>
            <th></th>
            <th>{getDiseaseItemColumnHeader()}</th>
            <th>Disease Name</th>
            <th>{getDiseaseSecondColumnHeader()}</th>
            <th>Select</th>
          </>
        );
      case 'VARIANTS':
        return (
          <>
            <th></th>
            <th>Variant rs number</th>
            <th>Variants Gene</th>
            <th>Select</th>
          </>
        );
      case 'GENES':
        return (
          <>
            <th></th>
            <th>{getGeneItemColumnHeader()}</th>
            <th>{selectedFilter === 'geneID' ? 'ENSEMBL ID' : 'Gene ID'}</th>
            <th>{getGeneSecondColumnHeader()}</th>
            <th>Select</th>
          </>
        );
      case 'CHEMICALS':
        return (
          <>
            <th></th>
            <th>Chemical IDs</th>
            <th>Chemicals Name</th>
            <th>Select</th>
          </>
        );
      default:
        return null;
    }
  };

  const getDiseaseItemColumnHeader = () => {
    switch (selectedFilter) {
      case 'diseaseUMLSCUI':
        return 'DISEASE CUI';
      default:
        return selectedFilter;
    }
  };

  const getDiseaseSecondColumnHeader = () => {
    if (selectedFilter === 'diseaseUMLSCUI') {
      return 'MHS';
    }
    return 'DISEASE CUI';
  };

  const getGeneItemColumnHeader = () => {
    switch (selectedFilter) {
      case 'symbol':
        return 'Gene Symbol';
      case 'ensemblID':
        return 'ENSEMBL ID';
      case 'uniprotID':
        return 'UNIPROPT ID';
      default:
        return selectedFilter;
    }
  };

  const getGeneSecondColumnHeader = () => {
    if (selectedFilter === 'symbol') {
      return 'Ensembl IDs';
    }
    return 'Gene Symbol';
  };

  const renderValidationErrorRow = (item, index) => (
    <tr key={index} className="validation-error">
      <td style={{ color: 'red', fontStyle: 'italic' }}>{rowNumber++}</td>
      <td style={{ color: 'red', fontStyle: 'italic' }}>{item.query}</td>
      <td style={{ color: 'red', fontStyle: 'italic' }}>{item.validationError}</td>
      <td></td>
    </tr>
  );

  const renderNotFoundRow = (item, index) => (
    <tr key={index} className="not-found">
      <td style={{ color: 'red', fontStyle: 'italic' }}>{rowNumber++}</td>
      <td style={{ color: 'red', fontStyle: 'italic' }}>{item.query}</td>
      <td style={{ color: 'red', fontStyle: 'italic' }}>Not available</td>
      <td className="ml-3"></td>
    </tr>
  );

  const renderResultRow = (result, index, i) => (
    <tr key={`${index}-${i}`}>
      <td>{rowNumber++}</td>
      <td>{getResultColumnValue(result)}</td>
      <td>{getResultName(result)}</td>
      {renderSecondColumn(result)}
      <td>
        <input
          type="checkbox"
          name="selectedItem"
          value={result[selectedFilter]}
          checked={selectedItems.includes(result)}
          onChange={() => handleCheckboxSelection(result)}
          className="ml-3"
        />
      </td>
    </tr>
  );

  const getResultColumnValue = result => {
    switch (searchType) {
      case 'DISEASES':
        return getDiseaseColumnValue(result);
      case 'VARIANTS':
        return result.strID || 'Not available';
      case 'GENES':
        return getGeneColumnValue(result);
      case 'CHEMICALS':
        return result.chemID || 'Not available';
      default:
        return 'Not available';
    }
  };

  const getDiseaseColumnValue = result => {
    switch (selectedFilter) {
      case 'diseaseUMLSCUI':
        return Array.isArray(result.diseaseUMLSCUI)
          ? result.diseaseUMLSCUI.join(', ')
          : result.diseaseUMLSCUI || 'Not available';
      case 'MSH':
        return Array.isArray(result.msh) ? result.msh.join(', ') : result.msh || 'Not available';
      case 'OMIM':
        return Array.isArray(result.omim) ? result.omim.join(', ') : result.omim || 'Not available';
      case 'NCI':
        return Array.isArray(result.nci) ? result.nci.join(', ') : result.nci || 'Not available';
      case 'ORDO':
        return Array.isArray(result.ordo) ? result.ordo.join(', ') : result.ordo || 'Not available';
      case 'HPO':
        return Array.isArray(result.hpo) ? result.hpo.join(', ') : result.hpo || 'Not available';
      case 'MONDO':
        return Array.isArray(result.mondo) ? result.mondo.join(', ') : result.mondo || 'Not available';
      default:
        return 'Not available';
    }
  };

  const getGeneColumnValue = result => {
    switch (selectedFilter) {
      case 'symbol':
        return Array.isArray(result.symbol) ? result.symbol.join(', ') : result.symbol || 'Not available';
      case 'geneID':
        return Array.isArray(result.geneID) ? result.geneID.join(', ') : result.geneID || 'Not available';
      case 'ensemblID':
        return Array.isArray(result.ensembl_ids)
          ? result.ensembl_ids.join(', ')
          : result.ensembl_ids || 'Not available';
      case 'uniprotID':
        return Array.isArray(result.proteinStrIDs)
          ? result.proteinStrIDs.join(', ')
          : result.proteinStrIDs || 'Not available';
      default:
        return 'Not available';
    }
  };

  const getResultName = result => {
    switch (searchType) {
      case 'DISEASES':
        return result.name;
      case 'VARIANTS':
        return result.geneSymbol;
      case 'GENES':
        if (selectedFilter === 'geneID') {
          return Array.isArray(result.ensembl_ids)
            ? result.ensembl_ids.join(', ')
            : result.ensembl_ids || 'Not available';
        }
        return result.geneID;
      case 'CHEMICALS':
        return result.chemPrefName;
      default:
        return 'Not available';
    }
  };

  const renderSecondColumn = result => {
    if (searchType === 'DISEASES' || searchType === 'GENES') {
      if (searchType === 'DISEASES' && selectedFilter === 'diseaseUMLSCUI') {
        return <td>{result.msh || 'Not available'}</td>;
      }
      if (searchType === 'GENES' && selectedFilter === 'symbol') {
        return <td>{result.ensembl_ids || 'Not available'}</td>;
      }
      return (
        <td>
          {searchType === 'DISEASES'
            ? result.diseaseUMLSCUI || 'Not available'
            : result.symbol || 'Not available'}
        </td>
      );
    }
    return null;
  };

  return (
    <div className="suggestions-table">
      <table className="table panther-table" style={{ float: 'middle', margin: '10px 20px 60px 10px' }}>
        <thead>
          <tr>{renderColumnHeader()}</tr>
        </thead>
        <tbody>
          {suggestionsData.map((item, index) => {
            if (item.validationError) {
              if (!uniqueValidationErrors.has(item.query)) {
                uniqueValidationErrors.add(item.query);
                return renderValidationErrorRow(item, index);
              }
              return null;
            }

            if (item.found && Array.isArray(item.results)) {
              return item.results
                .filter(result => {
                  const resultString = JSON.stringify(result);
                  if (uniqueResults.has(resultString)) {
                    return false;
                  }
                  uniqueResults.add(resultString);
                  return true;
                })
                .map((result, i) => renderResultRow(result, index, i));
            }

            if (!uniqueNotFound.has(item.query) && item.found === false) {
              uniqueNotFound.add(item.query);
              return renderNotFoundRow(item, index);
            }

            return null;
          })}
        </tbody>
      </table>
    </div>
  );
};

export default SuggestionsTable;
