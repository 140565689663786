// these dropdown lists are shared between 1+ columns
const diseaseTypeDropdownList = ['phenotype', 'group', 'disease'];
const semanticTypeDropdownList = [
  'Disease or Syndrome',
  'Finding',
  'Neoplastic Process',
  'Congenital Abnormality',
  'Anatomical Abnormality',
  'Sign or Symptom',
  'Mental or Behavioral Dysfunction',
  'Pathologic Function',
  'Laboratory Procedure',
  'Cell Function',
  'Acquired Abnormality',
  'Injury or Poisoning',
  'Laboratory or Test Result',
  'Cell or Molecular Dysfunction',
  'Mental Process',
  'Clinical Attribute',
  'Organism Attribute',
  'Organ or Tissue Function',
  'Individual Behavior',
  'Experimental Model of Disease',
  'Temporal Concept',
  'Organism Function',
  'Physiologic Function',
];
const diseaseClassDropdownList = [
  'Behavior and Behavior Mechanisms',
  'Cardiovascular Diseases',
  'Chemically-Induced Disorders',
  'Congenital, Hereditary, and Neonatal Diseases and Abnormalities',
  'Digestive System Diseases',
  'Disorders of Environmental Origin',
  'Endocrine System Diseases',
  'Eye Diseases',
  //"Female Urogenital Diseases and Pregnancy Complications",
  'Hemic and Lymphatic Diseases',
  'Immune System Diseases',
  'Infections',
  //"Male Urogenital Diseases",
  'Mental Disorders',
  'Musculoskeletal Diseases',
  'Neoplasms',
  'Nervous System Diseases',
  'Nutritional and Metabolic Diseases',
  'Occupational Diseases',
  'Otorhinolaryngologic Diseases',
  'Pathological Conditions, Signs and Symptoms',
  'Psychological Phenomena',
  'Respiratory Tract Diseases',
  'Skin and Connective Tissue Diseases',
  'Stomatognathic Diseases',
  'Urogenital Diseases',
  'Wounds and Injuries',
];
const geneTypeDropdownList = [
  'protein-coding',
  'ncRNA',
  'pseudo',
  'other',
  'biological-region',
  'snoRNA',
  'tRNA',
  'snRNA',
  'rRNA',
  'scRNA',
];
const proteinDropdownList = [
  'Calcium-binding protein',
  'Cell adhesion',
  'Cell-cell junction',
  'Cellular structure',
  'Chaperone',
  'Enzyme',
  'Enzyme modulator',
  'Epigenetic regulator',
  'Extracellular structure',
  'G-protein coupled receptor',
  'Immune response',
  'Ion channel',
  'Kinase',
  'Nuclear receptor',
  'Nucleic acid binding',
  'Receptor',
  'Signaling',
  'Storage',
  'Surfactant',
  'Transcription factor',
  'Transporter',
];
const hpoDropdownList = [
  'Abnormal cellular phenotype',
  'Abnormality of blood and blood-forming tissues',
  'Abnormality of connective tissue',
  'Abnormality of head or neck',
  'Abnormality of limbs',
  'Abnormality of metabolism/homeostasis',
  'Abnormality of prenatal development or birth',
  'Abnormality of the breast',
  'Abnormality of the cardiovascular system',
  'Abnormality of the digestive system',
  'Abnormality of the ear',
  'Abnormality of the endocrine system',
  'Abnormality of the eye',
  'Abnormality of the genitourinary system',
  'Abnormality of the immune system',
  'Abnormality of the integument',
  'Abnormality of the musculature',
  'Abnormality of the nervous system',
  'Abnormality of the respiratory system',
  'Abnormality of the skeletal system',
  'Abnormality of the thoracic cavity',
  'Abnormality of the voice',
  'Constitutional symptom',
  'Growth abnormality',
  'Neoplasm',
];
const doDropdownList = [
  'disease by infectious agent',
  'disease of anatomical entity',
  'disease of cellular proliferation',
  'disease of mental health',
  'disease of metabolism',
  'genetic disease',
  'physical disorder',
  'syndrome',
];
const ddaRelationDropdownList = [
  'has manifestation',
  'has associated morphology',
  'manifestation of',
  'associated morphology of',
  'is finding of disease',
  'due to',
  'has definitional manifestation',
  'has associated finding',
  'definitional manifestation of',
  'disease has finding',
  'cause of',
  'associated finding of',
];

// each key corresponds to column that uses a select filter
const dropdownLists = {
  source: {
    gda: [
      'ALL',
      'BIOBANK',
      'CLINICALTRIALS',
      'CLINGEN',
      'CLINVAR',
      'CURATED',
      'FINNGEN',
      'GWASCAT',
      'HPO',
      'INFERRED',
      'MGD_HUMAN',
      'MGD_MOUSE',
      'MODELS',
      'ORPHANET',
      'PHEWASCAT',
      'PSYGENET',
      'RGD_HUMAN',
      'RGD_RAT',
      'TEXTMINING_HUMAN',
      'TEXTMINING_MODELS',
      'UKBIOBANK',
      'UNIPROT',
    ],
    vda: [
      'ALL',
      'BIOBANK',
      'CLINVAR',
      'CURATED',
      'FINNGEN',
      'GWASCAT',
      'PHEWASCAT',
      'TEXTMINING_HUMAN',
      'UKBIOBANK',
      'UNIPROT',
    ],
  },

  EL: ['None', 'Definitive', 'Strong', 'Moderate', 'Disputed', 'Limited'],
  riskAllele: ['A', 'T', 'G', 'C'],
  evidenceLevelDropdownList: ['None', 'Definitive', 'Strong', 'Moderate', 'Disputed', 'Limited'],
  geneNcbiType: geneTypeDropdownList,
  ncbi_type: geneTypeDropdownList,
  geneProteinClassNames: proteinDropdownList,
  proteinClassIDs: proteinDropdownList,
  associationTypeGda: [
    'AlteredExpression',
    'Biomarker',
    'CausalMutation',
    'CausalOrContributing',
    'CellMarker', //
    'ChromosomalRearrangement',
    'DrugResponse',
    'EpigenomicAlterations', //
    'FusionGene',
    'GeneticVariation',
    'GermlineCausalMutation',
    'GermlineModifyingMutation',
    'Phosphorylation', //
    'PostTranslationalModification',
    'Protective',
    'SomaticCausalMutation',
    'SusceptibilityMutation',
  ],
  associationTypeVda: [
    'CausalMutation',
    'DrugResponse',
    'GeneticVariation',
    'Protective',
    'SusceptibilityMutation',
    'VUS',
  ],
  mostSevereConsequences: [
    'intron_variant',
    'intergenic_variant',
    'regulatory_region_variant',
    'stop_gained',
    'missense_variant',
    'non_coding_transcript_exon_variant',
    'synonymous_variant',
    '5_prime_UTR_variant',
    'splice_donor_variant',
    '3_prime_UTR_variant',
    'mature_miRNA_variant',
    'frameshift_variant',
    'splice_acceptor_variant',
    'TF_binding_site_variant',
    'splice_region_variant',
    'start_lost',
    'stop_lost',
    'inframe_deletion',
    'protein_altering_variant',
    'inframe_insertion',
    'coding_sequence_variant',
    'stop_retained_variant',
    'upstream_gene_variant',
    'downstream_gene_variant',
    'start_retained_variant',
    'transcript_ablation',
  ],
  ancestryList: [
    'Afghanistan',
    'African',
    'African American',
    'African American or Afro-Caribbean',
    'Afro-Caribbean',
    'Amish',
    'Arab',
    'Ashkenazi Jew',
    'Asian',
    'Bangladeshi',
    'Brazilian',
    'British',
    'Central Asian',
    'Chilean',
    'Chinese',
    'Costa Rican founder',
    'Curacao',
    'Dominican Republic',
    'East Asian',
    'Erasmus Rucphen',
    'European',
    'Filipino',
    'French Canadian founder',
    'Friuli Venezia Giulia',
    'Greater Middle Eastern (Middle Eastern or North African or Persian)',
    'Han Chinese',
    'Hispanic',
    'Indian',
    'Indigenous in North America',
    'Indonesian',
    'Iranian',
    'Israeli',
    'Japanese',
    'Korean',
    'Korčulan',
    'Latin or Admixed American',
    'Lebanese',
    'Malaysian',
    'Martu Australian Aboriginal',
    'Mexican',
    'Moroccan',
    'Native Hawaiian',
    'Nigerian',
    'Old Order Amish',
    'Orcadian',
    'Peruvian',
    'Sardinian',
    'South African',
    'South Asian',
    'South East Asian',
    'Spanish',
    'Sri Lankan Sinhalese',
    'Surinamese',
    'Taiwanese',
    'Thai',
    'Turkish',
    'Vietnamese',
  ],
  disease1ClassName_UMLS: semanticTypeDropdownList,
  disease2ClassName_UMLS: semanticTypeDropdownList,
  diseaseClassName_UMLS: semanticTypeDropdownList,
  disease1ClassName_MSH: diseaseClassDropdownList,
  disease2ClassName_MSH: diseaseClassDropdownList,
  diseaseClassName_MSH: diseaseClassDropdownList,
  disease1_Type: diseaseTypeDropdownList,
  disease2_Type: diseaseTypeDropdownList,
  diseaseType: diseaseTypeDropdownList,
  type: diseaseTypeDropdownList,
  disease1ClassName_HPO: hpoDropdownList,
  disease2ClassName_HPO: hpoDropdownList,
  diseaseClassName_HPO: hpoDropdownList,
  disease1ClassName_DO: doDropdownList,
  disease2ClassName_DO: doDropdownList,
  diseaseClassName_DO: doDropdownList,
  DBSNPclass: ['snv', 'delins', 'del', 'mnv', 'ins'],
  gof_lof: ['GOF', 'LOF'],
  ddaRelation: ddaRelationDropdownList,
  reference_type: ['PMID', 'NCTID'],
  //chemical_effect: ['other', 'therapeutic', 'toxicity'],
};

export default dropdownLists;

// source: [
//   'ALL',
//   'CLINICALTRIALS',
//   'CLINGEN',
//   'CLINVAR',
//   'CURATED',
//   'GWASCAT',
//   'HPO',
//   'INFERRED',
//   'MGD_HUMAN',
//   'MGD_MOUSE',
//   'MODELS',
//   'ORPHANET',
//   'PHEWASCAT',
//   'PSYGENET',
//   'RGD_HUMAN',
//   'RGD_RAT',
//   'TEXTMINING_HUMAN',
//   'UNIPROT',
// ],
