import React from 'react';

function AboutSourcesGda() {
  return (
    <div id="section11">
      <h3>Gene-Disease Associations</h3>
      <p>Gene-disease data is organized according to the types of source databases:</p>
      <ul>
        <li className="bullet-item">
          CURATED: GDAs provided by the expert curated resources such as{' '}
          <a className="tt" target="blank" href="https://www.uniprot.org/">
            UniProt/SwissProt (UniProt)
          </a>
          ,
          <a className="tt" target="blank" href="https://www.ncbi.nlm.nih.gov/clinvar/">
            {' '}
            ClinVar
          </a>
          ,
          <a className="tt" target="blank" href="http://psygenet.org/web/PsyGeNET/menu">
            {' '}
            PsyGeNET
          </a>
          ,
          <a className="tt" target="blank" href="https://www.orpha.net/consor/cgi-bin/index.php">
            {' '}
            Orphanet
          </a>
          , and{' '}
          <a className="tt" target="blank" href="https://www.clinicalgenome.org/">
            {' '}
            The Clinical Genome Resource
          </a>{' '}
          (ClinGen), and{' '}
          <a className="tt" target="blank" href="https://www.informatics.jax.org/">
            {' '}
            MGD
          </a>{' '}
          (MGD human), and{' '}
          <a className="tt" target="blank" href="https://rgd.mcw.edu/">
            {' '}
            RGD
          </a>{' '}
          (RGD human).
        </li>
        <li className="bullet-item">
          MODELS: These data refer to GDAs identified in several models (mouse, rat, zebrafish) using text
          mining approaches and obtained from the database{' '}
          <a className="tt" target="blank" href="https://www.informatics.jax.org/">
            {' '}
            MGD
          </a>{' '}
          (MGD mouse) and{' '}
          <a className="tt" target="blank" href="https://rgd.mcw.edu/">
            {' '}
            RGD
          </a>{' '}
          (RGD rat).
        </li>
        <li className="bullet-item">
          INFERRED: These data refer to GDAs inferred from{' '}
          <a className="tt" target="blank" href="https://hpo.jax.org/app/">
            Human Phenotype Ontology
          </a>{' '}
          (HPO) and the VDAs reported by the GWAS and Phewas catalog. In the case of HPO, GDAs are inferred
          from phenotype-disease and gene-phenotype associations via inference through the common phenotypes.
          In the case of VDAs, a GDA is inferred for each VDA, for those variants that map to gene coding
          sequences.
        </li>
        <li className="bullet-item">
          CLINICAL TRIALS: GDAs extracted using text mining from clinical trials records available at{' '}
          <a className="tt" target="blank" href="https://clinicaltrials.gov/">
            clinicaltrials.gov
          </a>
          . Disease annotations are obtained from{' '}
          <a className="tt" target="blank" href="https://aact.ctti-clinicaltrials.org/">
            aact.ctti-clinicaltrials.org
          </a>
          .
        </li>
        <li className="bullet-item">
          TEXT MINING MODELS: GDAs identified by text mining from the scientific literature in studies using
          model systems (animal models).
        </li>
        <li className="bullet-item">
          TEXT MINING HUMAN: GDAs identified by text mining from the scientific literature in studies
          performed on human subjects.
        </li>
        <li className="bullet-item">ALL: GDAs from all previous sources.</li>
      </ul>
    </div>
  );
}

export default AboutSourcesGda;
