import React from 'react';
import { Helmet } from 'react-helmet';
import { generateContent } from './CaseStudiesContent';

const CaseStudy = ({ study }) => {
  if (!study) {
    return <div className="container">Post not found.</div>;
  }

  return (
    <div className="container pt-4 pb-4">
      <Helmet>
        <link rel="stylesheet" href={`/styles/main.css?v=${Date.now()}`} /> <title>{study.title}</title>
        <meta name="description" content={study.description} />
        <meta name="keywords" content={study.tags?.join(', ')} />
        <meta name="title" property="og:title" content={study.title} />
        <meta name="description" property="og:description" content={study.description} />
        <meta name="image" property="og:image" content={study.image} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <div className="post-preview">{generateContent(study)}</div>
    </div>
  );
};

export { CaseStudy };
