import AboutMetricsGdaScore from './AboutMetricsGdaScore.jsx';
import AboutMetricsVdaScore from './AboutMetricsVdaScore.jsx';
import AboutMetricsSpecificity from './AboutMetricsSpecificity.jsx';
import AboutMetricsPleiotropy from './AboutMetricsPleiotropy.jsx';
import AboutMetricsEvidenceIndex from './AboutMetricsEvidenceIndex.jsx';

function AboutMetrics() {
  return (
    <div>
      {' '}
      <h2>DISGENET Metrics</h2>
      <p>
        We have developed two scores to rank the gene-disease, and the variant-disease associations according
        to their level of evidence. These scores range from 0 to 1, and take into account the number and type
        of sources (level of curation, model organisms), and the number of publications supporting the
        association.
      </p>
      <div>
        <AboutMetricsGdaScore />
        <div className="divider" />
        <AboutMetricsVdaScore />
        <div className="divider" />
        <AboutMetricsSpecificity />
        <div className="divider" />
        <AboutMetricsPleiotropy />
        <div className="divider" />
        <AboutMetricsEvidenceIndex />
      </div>
    </div>
  );
}

export default AboutMetrics;
