import React from 'react';
import { DATASETS } from '../Constants';
const Tag = ({ item, setSearchItems }) => {
  // tooltip on hover
  const tooltipMappings = {
    [DATASETS.DISEASES]: item.name + ', ' + item.diseaseUMLSCUI,
    [DATASETS.GENES]: item.symbol + ', ' + item.description,
    [DATASETS.VARIANTS]: item.strID + ', ' + item.geneSymbol,
    [DATASETS.CHEMICALS]: item.chemPrefName + ', ' + item.chemID,
  };
  const toolTip = tooltipMappings[item.dataset];

  const textContentMappings = {
    [DATASETS.DISEASES]: 'Name: ' + item.name + ', ' + 'CUI: ' + item.diseaseUMLSCUI,
    [DATASETS.GENES]:
      'Symbol: ' +
      item.symbol +
      ', ' +
      'Description: ' +
      item.description +
      ', ' +
      'EntrezId: ' +
      item.ncbiID,
    [DATASETS.VARIANTS]: 'SNPdb: ' + item.strID + ', ' + 'Gene Symbol: ' + item.geneSymbol,
    [DATASETS.CHEMICALS]: 'Name: ' + item.chemPrefName + ', ID: ' + item.chemID,
  };
  const textContent = textContentMappings[item.dataset];

  const clear = evt => {
    evt.preventDefault();
    setSearchItems(prevItems => prevItems.filter(prevItem => prevItem !== item));
  };
  return (
    <>
      <span title={toolTip} onClick={clear} data-testid="searchTag">
        {textContent}
      </span>
    </>
  );
};

export default Tag;
