const prod = {
  googleAnalyticsTrackingId: 'G-QCQLM7BJFX',
  mode: 'PRODUCTION',
};

const dev = {
  googleAnalyticsTrackingId: 'G-488FQ6JHZS',
  mode: 'DEVELOPMENT',
};

// eslint-disable-next-line no-undef
export const gAnalytics = process.env.NODE_ENV === `development` ? dev : prod;
