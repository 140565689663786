import $ from 'jquery';
import { Link, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { updatePassword } from 'aws-amplify/auth';

function validate(input) {
  if ($(input).attr('type') == 'email' || $(input).attr('name') == 'email') {
    if (
      $(input)
        .val()
        .trim()
        .match(
          /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{1,5}|[0-9]{1,3})(\]?)$/,
        ) == null
    ) {
      return false;
    }
  } else {
    if ($(input).val().trim() == '') {
      return false;
    }
  }
}

export default function ChangePassword({ onClose }) {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isOldPassVisible, setIsOldPassVisible] = useState(false);
  const [isNewPassVisible, setIsNewPassVisible] = useState(false);
  const [isConfirmPassVisible, setIsConfirmPassVisible] = useState(false);

  const toggleVisibility = setter => setter(prev => !prev);

  const validateInputs = () => {
    if (!oldPassword.trim()) {
      setErrorMessage('Old Password is required');
      return false;
    }
    if (!newPassword.trim()) {
      setErrorMessage('New Password is required');
      return false;
    }
    if (newPassword !== confirmPassword) {
      setErrorMessage('Passwords do not match');
      return false;
    }
    return true;
  };

  const handleSubmit = async evt => {
    evt.preventDefault();
    setErrorMessage('');

    if (!validateInputs()) return;

    try {
      await updatePassword({ oldPassword, newPassword });
      alert('Password updated successfully!');
      navigate('/Profile-area');
      setTimeout(() => {
        if (onClose) onClose();
      }, 5000);
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  return (
    <div className="signin-container">
      <div className="col-12">
        <form className="validate-form" onSubmit={handleSubmit} autoComplete="off">
          <h5 style={{ color: 'white', textAlign: 'center', paddingBottom: '15px' }}>Change Your Password</h5>

          <div className="input-container">
            <label className="label-input100 pr-2" style={{ color: 'white' }}>
              Old Password:
            </label>
            <span className="eye-icon" onClick={() => toggleVisibility(setIsOldPassVisible)}>
              <i
                className={`fa ${isOldPassVisible ? 'fa-eye' : 'fa-eye-slash'}`}
                style={{ color: 'white' }}
              />
            </span>
            <div className="line-input">
              <input
                onChange={e => setOldPassword(e.target.value)}
                type={isOldPassVisible ? 'text' : 'password'}
                className="input100"
                placeholder="Enter Old Password"
              />
            </div>
          </div>

          <div className="input-container">
            <label className="label-input100 pr-2" style={{ color: 'white' }}>
              New Password:
            </label>
            <span className="eye-icon" onClick={() => toggleVisibility(setIsNewPassVisible)}>
              <i
                className={`fa ${isNewPassVisible ? 'fa-eye' : 'fa-eye-slash'}`}
                style={{ color: 'white' }}
              />
            </span>
            <div className="line-input">
              <input
                onChange={e => setNewPassword(e.target.value)}
                type={isNewPassVisible ? 'text' : 'password'}
                className="input100"
                placeholder="Enter New Password"
              />
            </div>
          </div>

          <div className="input-container">
            <label className="label-input100 pr-2" style={{ color: 'white' }}>
              Confirm Password:
            </label>
            <span className="eye-icon" onClick={() => toggleVisibility(setIsConfirmPassVisible)}>
              <i
                className={`fa ${isConfirmPassVisible ? 'fa-eye' : 'fa-eye-slash'}`}
                style={{ color: 'white' }}
              />
            </span>
            <div className="line-input">
              <input
                onChange={e => setConfirmPassword(e.target.value)}
                type={isConfirmPassVisible ? 'text' : 'password'}
                className="input100"
                placeholder="Confirm New Password"
              />
            </div>
          </div>

          <Link style={{ float: 'right', marginBottom: '20px', color: 'white' }} to="/Profile-area">
            Cancel
          </Link>

          <div className="container-contact100-form-btn">
            <div className="wrap-contact100-form-btn">
              <div className="contact100-form-bgbtn" />
              <button className="contact100-form-btn">Change Password</button>
            </div>
          </div>

          {errorMessage && <p className="text-center">{errorMessage}</p>}
        </form>
      </div>
    </div>
  );
}
