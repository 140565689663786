import React, { Fragment, useState } from 'react';
const ChangeViewsButton = ({ selectedView, setSelectedView }) => {

  const handleViewChange = () => {
    setSelectedView(prevcurrentView => (prevcurrentView === 'tables' ? 'graphs' : 'tables'));
  };

  return (
    <Fragment>
      <button className="BtnGraph ChangeViews" onClick={handleViewChange}>
        {selectedView === 'graphs' ? 'View Tables' : 'View Graphs'}
      </button>
    </Fragment>
  );
};

export default ChangeViewsButton;
