import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

export const LegalNav = () => {
  return (
    <nav className="w-full" id="cd-vertical-nav">
      <ul className="list-unstyled mt-4 flex overflow-auto">
        <li className="nav-item">
          <Link smooth to="/Legal#section1">
            <span className="cd-title">1. DTO</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link smooth to="/Legal#section2">
            <span className="cd-title">2. HPO</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link smooth to="/Legal#section3">
            <span className="cd-title">3. Uniprot</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link smooth to="/Legal#section4">
            <span className="cd-title">4. Orphanet</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link smooth to="/Legal#section5">
            <span className="cd-title">5. GWASCAT</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link smooth to="/Legal#section6">
            <span className="cd-title">6. NLM MEDLINE</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link smooth to="/Legal#section7">
            <span className="cd-title">7. CLINICATRIAL.GOV</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link smooth to="/Legal#section8">
            <span className="cd-title">8. MeSH</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link smooth to="/Legal#section9">
            <span className="cd-title">9. UMLS</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link smooth to="/Legal#section10">
            <span className="cd-title">10. NCIT</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link smooth to="/Legal#section11">
            <span className="cd-title">11. SIO</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link smooth to="/Legal#section12">
            <span className="cd-title">12. DCMI Metadata</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link smooth to="/Legal#section13">
            <span className="cd-title">13. MONDO</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link smooth to="/Legal#section14">
            <span className="cd-title">14. REACTOME PATHWAY DIAGRAM</span>
          </Link>
        </li>
        <li className="nav-i</li>tem">
          <Link smooth to="/Legal#sectio</Link>n15">
            <span className="cd-title">15. REACTOME PATHWAY ANALYSE SERVICE</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link smooth to="/Legal#section16">
            <span className="cd-title">16. dbSNP</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link smooth to="/Legal#section17">
            <span className="cd-title">17. Phewas Catalog</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link smooth to="/Legal#section18">
            <span className="cd-title">18. Mouse Genome Database (MGD)</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link smooth to="/Legal#section19">
            <span className="cd-title">19. Rat Genome Database (RGD)</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link smooth to="/Legal#section20">
            <span className="cd-title">20. FinnGen GWAS</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link smooth to="/Legal#section21">
            <span className="cd-title">21. UK Biobank GWAS/PheWAS</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link smooth to="/Legal#section22">
            <span className="cd-title">22. Hancestro Ontology</span>
          </Link>
        </li>
      </ul>
    </nav>
  );
};
