import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Filas from './Filas';

const getRowTableHTML = element => (
  <tr key={element}>
    <td className="col-md-6">{element}</td>
  </tr>
);

const chemVocabularyAttributes = ['vocabulary', 'code', 'name'];

export default function DetailDrug({ values }) {
  const { chemSynonyms, chemVocabularies } = values;
  const uniqueVocabs = new Set(chemVocabularies.map(item => item.vocabulary));

  return (
    <Tabs id="table-scroll">
      <TabList className="menuTabs">
        <Tab className="react-tabs__tab">Vocabularies</Tab>
        <Tab className="react-tabs__tab">Synonyms</Tab>
      </TabList>
      <TabPanel className="tab-pane p-2">
        <table className="table">
          <tbody>
            <tr>
              {chemVocabularyAttributes.map((term, index) => (
                <td key={`${term}${index}`} className="table-headers">
                  {term[0]?.toUpperCase() + term.slice(1)}
                </td>
              ))}
            </tr>
            {[...uniqueVocabs].map((vocab, index) => {
              return (
                <Filas key={`${vocab}${index}`} resultado={vocab} lista={chemVocabularies} type="Drug" />
              );
            })}
          </tbody>
        </table>
      </TabPanel>
      <TabPanel className="tab-pane p-2">
        <table className="table">
          <tbody>
            {chemSynonyms.map(synonym => {
              return getRowTableHTML(synonym);
            })}
          </tbody>
        </table>
      </TabPanel>
    </Tabs>
  );
}
