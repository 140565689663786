import React, { Fragment, useEffect, useState } from 'react';
import Buttons from './Buttons';

import {
  listSourceGDAs_DDAs,
  listSourceVariantAll_VDAs,
  listSourceAcademic,
  listSourceDiseaseAll,
  listSourceGeneALL,
} from './SourceLists';
import { searchContext } from '../../Context/Context';

const variantSourceButtons = ['VARIANTS', 'VDA'];
const diseasesSourceButtons = ['DISEASES', 'CHEMICALS'];
const genesSourceButtons = ['GENES'];
const gdaDDASourceButtons = ['GDA', 'DDA'];

export const getSourceList = ({ tab, restrictSources }) => {
  if (restrictSources === 'CURATED') {
    return listSourceAcademic;
  }
  if (variantSourceButtons.includes(tab)) {
    return listSourceVariantAll_VDAs;
  }
  if (diseasesSourceButtons.includes(tab)) {
    return listSourceDiseaseAll;
  }
  if (genesSourceButtons.includes(tab)) {
    return listSourceGeneALL;
  }

  if (gdaDDASourceButtons.includes(tab)) {
    return listSourceGDAs_DDAs;
  }

  return [];
};

export default function RenderSourcesLogic() {
  const { tab, restrictSources } = searchContext();

  const [sourceList, setSourceList] = useState(getSourceList({ tab, restrictSources }));
  useEffect(() => {
    setSourceList(getSourceList({ tab, restrictSources }));
  }, [tab]);

  const renderButtonsByCategory = sourceList => {
    return Object.keys(sourceList).map((category, index) => (
      <Buttons key={category} sourceList={sourceList[category]} />
    ));
  };

  return (
    <Fragment>
      {Array.isArray(sourceList) ? (
        <Buttons sourceList={sourceList} />
      ) : (
        <div className="">{renderButtonsByCategory(sourceList)}</div>
      )}
    </Fragment>
  );
}
