import React from 'react';

function Statistics251Changelog() {
  return (
    <div>
      <h6>DATA:</h6>
      <ul>
        <li className="bullet-item">
          All data sources updated: we increased the number of genes, variants, chemicals and associations.
          The most significant increases are for variants (96%), chemicals (262%), and VDAs (253%).
        </li>
        <li className="bullet-item">New data source: GWAS from biobanks.</li>
        <li className="bullet-item">NER for genes improved by removing ambiguous terms.</li>
        <li className="bullet-item">
          NER for diseases and phenotypes: concepts representing athletic performance were incorporated.
        </li>
        <li className="bullet-item">
          NLP pipeline for ClinicalTrials.gov was improved to better identify disease biomarkers. A text
          snippet representing the evidence about the biomarker is reported in the Sentence column of the GDA
          evidence table whenever available.
        </li>
        <li className="bullet-item">Increased coverage for chemicals and drugs.</li>
        <li className="bullet-item">New chemical identifier available.</li>
        <li className="bullet-item">
          Detailed information about GWAS summary statistics: Ancestry and Gender is now available, and Odds
          Ratio and Beta coefficients are provided as separate columns in the VDA evidence dataset.
        </li>
        <li className="bullet-item">
          Ancestry information is provided for VDAs identified from scientific publications, whenever this
          information is available.
        </li>
      </ul>
      <h6>WEB:</h6>
      <p> New Features:</p>
      <ul>
        <li className="bullet-item">
          New filter for data sources BIOBANK, UKBIOBANK and FINNGEN available for the GDA and VDA tables
          (summary and evidence).
        </li>
        <li className="bullet-item">
          New fields for filtering VDAs available in the VDA evidence table: Beta and Odds Ratio for GWAS,
          gender and ancestry (using a string or identifier from the Hancestro ontology).
        </li>
      </ul>
      <h6>REST API:</h6>
      <p>New release of the REST API (1.8.0)</p>
      <ul>
        <li className="bullet-item">Bug fix to the disease enrichment endpoint.</li>
        <li className="bullet-item">
          New filter for data sources BIOBANK, UKBIOBANK and FINNGEN available for the GDA and VDA endpoints
          (summary and evidence).
        </li>
        <li className="bullet-item">
          Different chemical identifiers (ChEMBL, PubChem, DrugBank and ChEBI) can be used for searches in the
          GDA and VDA endpoints (summary and evidence), and in the Chemical endpoint. The response of these
          endpoints includes cross references to several chemical identifiers (ChEMBL, PubChem, DrugBank and
          ChEBI) for the chemicals.
        </li>
        <li className="bullet-item">
          New filter for Initial and Final Year for GDAs in the GDA summary endpoint.
        </li>
        <li className="bullet-item">
          New fields for filtering VDAs available in the VDA evidence endpoint: Beta and Odds Ratio for GWAS,
          gender and ancestry (using a string or identifier from the Hancestro ontology).
        </li>
      </ul>
      <h6>R PACKAGE:</h6>
      <p>
        New release of the disgenet2r R package (1.2.3). For detailed information, see the{' '}
        <a href="https://gitlab.com/medbio/disgenet2r/-/blob/master/NEWS.md" target="_blank" rel="noreferrer">
          News Section
        </a>
        . Here are the main updates:
      </p>
      <ul>
        <li className="bullet-item">New sources added.</li>
        <li className="bullet-item">Chemical functions updated.</li>
      </ul>
      <h6>Cytoscape App:</h6>
      <ul>
        <li className="bullet-item">New release of the DISGENET Cytoscape App (v8.2).</li>
        <li className="bullet-item">New sources added.</li>
        <li className="bullet-item">Bug enrichment.</li>
        <li className="bullet-item">
          For more information, see the{' '}
          <a href="https://apps.cytoscape.org/apps/disgenetapp" target="_blank" rel="noreferrer">
            release notes.
          </a>
        </li>
      </ul>
    </div>
  );
}

export default Statistics251Changelog;
