import React from 'react';

export const LegalContent = () => {
  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
  return (
    <div>
      <p>DISGENET database incorporates data from: </p>
      <div id="section1" className="cd-section mb-3">
        <div className="p-3">
          <h5>1. DTO</h5>
          <p>
            Drug Target Ontology. Lin, Yu, et al. "Drug Target Ontology to Classify and Integrate Drug
            Discovery Data". Journal of Biomedical Semantics 2017 8:50.
          </p>
          <p>
            <a target="_blank" href="https://doi.org/10.1186/s13326-017-0161-x" rel="noreferrer">
              {' '}
              https://doi.org/10.1186/s13326-017-0161-x
            </a>
            .{' '}
          </p>
          <p>
            DTO can be downloaded from GitHub at{' '}
            <span>
              <a target="_blank" href="https://github.com/DrugTargetOntology/DTO" rel="noreferrer">
                {' '}
                https://github.com/DrugTargetOntology/DTO
              </a>
              .
            </span>
          </p>
          <p>
            Creative Commons Attribution 4.0 International (CC BY 4.0) License
            <span>
              {' '}
              (
              <a target="_blank" href="https://creativecommons.org/licenses/by/4.0/" rel="noreferrer">
                https://creativecommons.org/licenses/by/4.0/
              </a>
              ).
            </span>
          </p>
        </div>
      </div>

      <div id="section2" className="cd-section mb-3">
        <div className="p-3">
          <h5>2. HPO</h5>
          <p>Human Phenotype Ontology (data-version: hp/releases/2022-06-11).</p>
          <p>
            Find out more at{' '}
            <span>
              <a target="_blank" href="http://www.human-phenotype-ontology.org" rel="noreferrer">
                http://www.human-phenotype-ontology.org
              </a>
              .
            </span>
          </p>
          <p>
            Köhler S, Gargano M, Matentzoglu N, Carmody LC, Lewis-Smith D, Vasilevsky NA, Danis D, Balagura G,
            Baynam G, Brower AM, Callahan TJ, Chute CG, Est JL, Galer PD, Ganesan S, Griese M, Haimel M,
            Pazmandi J, Hanauer M, Harris NL, Hartnett MJ, Hastreiter M, Hauck F, He Y, Jeske T, Kearney H,
            Kindle G, Klein C, Knoflach K, Krause R, Lagorce D, McMurry JA, Miller JA, Munoz-Torres MC, Peters
            RL, Rapp CK, Rath AM, Rind SA, Rosenberg AZ, Segal MM, Seidel MG, Smedley D, Talmy T, Thomas Y,
            Wiafe SA, Xian J, Yüksel Z, Helbig I, Mungall CJ, Haendel MA, Robinson PN. The Human Phenotype
            Ontology in 2021. Nucleic Acids Res. 2021 Jan 8;49(D1):D1207-D1217. doi: 10.1093/nar/gkaa1043.
            PMID: 33264411; PMCID: PMC7778952.
          </p>
        </div>
      </div>
      <div id="section3" className="cd-section mb-3">
        <div className="p-3">
          <h5>3. Uniprot</h5>
          <p>© 2002-2021 UniProt Consortium.</p>
          <p>
            The UniProt Consortium, UniProt: the Universal Protein Knowledgebase in 2023, Nucleic Acids
            Research, Volume 51, Issue D1, 6 January 2023, Pages D523–D531,{' '}
            <span>
              <a target="_blank" href="https://doi.org/10.1093/nar/gkac1052" rel="noreferrer">
                https://doi.org/10.1093/nar/gkac1052
              </a>
              .
            </span>
          </p>
          <p>
            Creative Commons Attribution 4.0 International (CC BY 4.0) License
            <span>
              {' '}
              (
              <a target="_blank" href="https://creativecommons.org/licenses/by/4.0/" rel="noreferrer">
                https://creativecommons.org/licenses/by/4.0/
              </a>
              ).
            </span>
          </p>
          <p>
            All databases and documents in the UniProt FTP directory may be copied and redistributed freely,
            without advance permission, provided this copyright statement is reproduced with each copy.{' '}
            <span>
              <a target="_blank" href="https://ftp.uniprot.org/pub/databases/uniprot/" rel="noreferrer">
                https://ftp.uniprot.org/pub/databases/uniprot/
              </a>
              .
            </span>
          </p>
        </div>
      </div>
      <div id="section4" className="cd-section mb-3">
        <div className="p-3">
          <h5>4. Orphanet</h5>
          <p>
            Free access data from Orphanet. © INSERM 1999. Available on{' '}
            <span>
              <a target="_blank" href="https://www.orphadata.com" rel="noreferrer">
                https://www.orphadata.com
              </a>
            </span>
            .
          </p>
          <p>
            Data version (date="2022-06-14 16:27:47" version="1.3.16 / 4.1.7 [2022-01-26] (orientdb
            version)").
          </p>
        </div>
      </div>
      <div id="section5" className="cd-section mb-3">
        <div className="p-3">
          <h5>5. GWASCAT</h5>
          <p>
            Carey V (2022). gwascat: representing and modeling data in the EMBL-EBI GWAS catalog. R package
            version 2.30.0.
          </p>
          <p>
            <a target="_blank" href="https://www.ebi.ac.uk/about/terms-of-use" rel="noreferrer">
              {' '}
              https://www.ebi.ac.uk/about/terms-of-use
            </a>
            .
          </p>
        </div>
      </div>
      <div id="section6" className="cd-section mb-3">
        <div className="p-3">
          <h5>6. NLM MEDLINE</h5>
          <p>Courtesy of the U.S. National Library of Medicine.</p>
        </div>
      </div>
      <div id="section7" className="cd-section mb-3">
        <div className="p-3">
          <h5>7. CLINICATRIAL.GOV</h5>
          <p>Data accessed on 19/01/2022, ClinicalTrials.gov from United States Government.</p>
        </div>
      </div>
      <div id="section8" className="cd-section mb-3">
        <div className="p-3">
          <h5>8. MeSH</h5>
          <p>
            MSH2023_2022_07_27 - Medical Subject Headings (MeSH) 2023 U.S. National Library of Medicine; July
            27, 2022; Bethesda, MD.
          </p>
          <p>
            Bodenreider O. The Unified Medical Language System (UMLS): integrating biomedical terminology.
            Nucleic Acids Res. 2004 Jan 1; 32 (Database issue): D267-70. doi: 10.1093/nar/gkh061. PubMed PMID:
            14681409; PubMed Central PMCID: PMC308795.
          </p>
          <p>
            Some material in the UMLS Metathesaurus is from copyrighted sources of the respective copyright
            holders. Users of the UMLS Metathesaurus are solely responsible for compliance with any copyright,
            patent or trademark restrictions and are referred to the copyright, patent or trademark notices
            appearing in the original sources, all of which are hereby incorporated by reference.
          </p>
        </div>
      </div>
      <div id="section9" className="cd-section mb-3">
        <div className="p-3">
          <h5>9. UMLS</h5>
          <p>
            UMLS® Metathesaurus® National Library of Medicine, Department of Health and Human Services
            (IDENTIFY SPECIFICS) 2022 AB November 7, 2022.
          </p>
          <p>
            Some material in the UMLS Metathesaurus is from copyrighted sources of the respective copyright
            holders. Users of the UMLS Metathesaurus are solely responsible for compliance with any copyright,
            patent or trademark restrictions andare referred to the copyright, patent or trademark notices
            appearing in the original sources, all of which are hereby incorporated by reference.
          </p>
          <p>
            <a
              target="_blank"
              href="https://uts.nlm.nih.gov/uts/assets/LicenseAgreement.pdf"
              rel="noreferrer"
            >
              {' '}
              https://uts.nlm.nih.gov/uts/assets/LicenseAgreement.pdf
            </a>
            .
          </p>
        </div>
      </div>
      <div id="section10" className="cd-section mb-3">
        <div className="p-3">
          <h5>10. NCIT</h5>
          <p>
            The NCI Thesaurus™ is produced by the Enterprise Vocabulary Services group of the Center for
            Biomedical Informatics and Information Technology, National Cancer Institute, Maryland, USA.
            <span>
              <a target="_blank" href="https://ncithesaurus.nci.nih.gov/ncitbrowser/" rel="noreferrer">
                {' '}
                https://ncithesaurus.nci.nih.gov/ncitbrowser/
              </a>
            </span>
            .
          </p>
          <p>
            Creative Commons Attribution 4.0 International (CC BY 4.0) License{' '}
            <span>
              (
              <a target="_blank" href="https://creativecommons.org/licenses/by/4.0/" rel="noreferrer">
                https://creativecommons.org/licenses/by/4.0/
              </a>
              ).
            </span>
          </p>
        </div>
      </div>
      <div id="section11" className="cd-section mb-3">
        <div className="p-3">
          <h5>11. SIO</h5>
          <p>The Semanticscience Integrated Ontology (SIO).</p>
          <p>
            Michel Dumontier, Christopher JO Baker, Joachim Baran, Alison Callahan, Leonid Chepelev, José
            Cruz-Toledo, Nicholas R Del Rio, Geraint Duck, Laura I Furlong, Nichealla Keath, Dana Klassen,
            Jamie P McCusker, Núria Queralt-Rosinach, Matthias Samwald, Natalia Villanueva-Rosales, Mark D
            Wilkinson & Robert Hoehndorf.
          </p>
          <p>
            <a target="_blank" href=" https://github.com/MaastrichtU-IDS/semanticscience">
              https://github.com/MaastrichtU-IDS/semanticscience
            </a>
            .
          </p>
          <p>
            Creative Commons Attribution 4.0 International (CC BY 4.0) License{' '}
            <span>
              (
              <a target="_blank" href="https://creativecommons.org/licenses/by/4.0/" rel="noreferrer">
                https://creativecommons.org/licenses/by/4.0/
              </a>
              ).
            </span>
          </p>
        </div>
      </div>
      <div id="section12" className="cd-section mb-3">
        <div className="p-3">
          <h5>12. DCMI Metadata</h5>
          <p>
            Copyright © [2012] Dublin Core™ Metadata Initiative.{' '}
            <span>
              <a target="_blank" href=" http://dublincore.org/about/copyright/">
                http://dublincore.org/about/copyright/
              </a>
              .
            </span>
          </p>
          <p>
            Creative Commons Attribution 4.0 International (CC BY 4.0) License{' '}
            <span>
              (
              <a target="_blank" href="https://creativecommons.org/licenses/by/4.0/" rel="noreferrer">
                https://creativecommons.org/licenses/by/4.0/
              </a>
              ).
            </span>
          </p>
        </div>
      </div>
      <div id="section13" className="cd-section mb-3">
        <div className="p-3">
          <h5>13. MONDO</h5>
          <p>
            Unifying diseases for the world, by the world, Nicole A Vasilevsky, Nicolas A Matentzoglu, Sabrina
            Toro, Joseph E Flack IV, Harshad Hegde, Deepak R Unni, Gioconda F Alyea, Joanna S Amberger, Larry
            Babb, James P Balhoff, Taylor I Bingaman, Gully A Burns, Orion J Buske, Tiffany J Callahan, Leigh
            C Carmody, Paula Carrio Cordo, Lauren E Chan, George S Chang, Sean L Christiaens, Louise C
            Daugherty, Michel Dumontier, Laura E Failla, May J Flowers, H. Alpha Garrett Jr., Jennifer L
            Goldstein, Dylan Gration, Tudor Groza, Marc Hanauer, Nomi L Harris, Jason A Hilton, Daniel S
            Himmelstein, Charles Tapley Hoyt, Megan S Kane, Sebastian Köhler, David Lagorce, Abbe Lai, Martin
            Larralde, Antonia Lock, Irene López Santiago, Donna R Maglott, Adriana J Malheiro, Birgit H M
            Meldal, Monica C Munoz-Torres, Tristan H Nelson, Frank W Nicholas, David Ochoa, Daniel P Olson,
            Tudor I Oprea, David Osumi-Sutherland, Helen Parkinson, Zoë May Pendlington, Ana Rath, Heidi L
            Rehm, Lyubov Remennik, Erin R Riggs, Paola Roncaglia, Justyne E Ross, Marion F Shadbolt, Kent A
            Shefchek, Morgan N Similuk, Nicholas Sioutos, Damian Smedley, Rachel Sparks, Ray Stefancsik, Ralf
            Stephan, Andrea L Storm, Doron Stupp, Gregory S Stupp, Jagadish Chandrabose Sundaramurthi, Imke
            Tammen, Darin Tay, Courtney L Thaxton, Eloise Valasek, Jordi Valls-Margarit, Alex H Wagner,
            Danielle Welter, Patricia L Whetzel, Lori L Whiteman, Valerie Wood, Colleen H Xu, Andreas Zankl,
            Xingmin Aaron Zhang, Christopher G Chute, Peter N Robinson, Christopher J Mungall, Ada Hamosh,
            Melissa A Haendel, medRxiv 2022.04.13.22273750; doi:{' '}
            <span>
              <a target="_blank" href=" https://doi.org/10.1101/2022.04.13.22273750" rel="noreferrer">
                https://doi.org/10.1101/2022.04.13.22273750
              </a>
              .
            </span>
          </p>
        </div>
      </div>
      <div id="section14" className="cd-section mb-3">
        <div className="p-3">
          <h5>14. REACTOME PATHWAY DIAGRAM</h5>
          <p>
            Copyright © 2023 Reactome{' '}
            <span>
              <a target="_blank" href="https://reactome.org/license" rel="noreferrer">
                https://reactome.org/license
              </a>{' '}
              -{' '}
            </span>
            <span>
              <a target="_blank" href="https://reactome.org/download-data" rel="noreferrer">
                https://reactome.org/download-data
              </a>
            </span>
          </p>
          <p>
            Creative Commons Attribution 4.0 International (CC BY 4.0) License{' '}
            <span>
              (
              <a target="_blank" href="https://creativecommons.org/licenses/by/4.0/" rel="noreferrer">
                https://creativecommons.org/licenses/by/4.0/
              </a>
              ).
            </span>
          </p>
        </div>
      </div>
      <div id="section15" className="cd-section mb-3">
        <div className="p-3">
          <h5>15. REACTOME PATHWAY ANALYSE SERVICE</h5>
          <p>
            Copyright © 2023 Reactome{' '}
            <span>
              <a target="_blank" href="https://reactome.org/license" rel="noreferrer">
                https://reactome.org/license
              </a>{' '}
              -{' '}
            </span>
            <span>
              <a target="_blank" href="https://reactome.org/download-data" rel="noreferrer">
                https://reactome.org/download-data
              </a>
            </span>
          </p>
          <p>
            Creative Commons Attribution 4.0 International (CC BY 4.0) License{' '}
            <span>
              (
              <a target="_blank" href="https://creativecommons.org/licenses/by/4.0/" rel="noreferrer">
                https://creativecommons.org/licenses/by/4.0/
              </a>
              ).
            </span>
          </p>
        </div>
      </div>
      <div id="section16" className="cd-section mb-3">
        <div className="p-3">
          <h5>16. dbSNP</h5>
          <p>
            Databases are provided separately under the terms of the corresponding license, and Customer, as
            defined on Subscription Agreement, shall be considered a direct licensee of those datasets and
            agrees to Company to comply with the mentioned license.
          </p>
          <p>
            Database of Single Nucleotide Polymorphisms (dbSNP). Bethesda (MD): National Center for
            Biotechnology Information, National Library of Medicine. {`(dbSNP Build ID: {151})`}.
          </p>
          <p>
            Available from:{' '}
            <span>
              <a target="_blank" href="http://www.ncbi.nlm.nih.gov/SNP/" rel="noreferrer">
                http://www.ncbi.nlm.nih.gov/SNP/
              </a>
              .
            </span>
          </p>
          <p>
            <span>
              <a target="_blank" href="https://opendatacommons.org/licenses/odbl/1-0/" rel="noreferrer">
                https://opendatacommons.org/licenses/odbl/1-0/
              </a>
              .
            </span>
          </p>
        </div>
      </div>

      <div id="section17" className="cd-section mb-3">
        <div className="p-3">
          <h5>17. Phewas Catalog</h5>

          <p>Creative Commons Attribution 4.0 International (CC BY 4.0) License: </p>
          <p>
            <span>
              <a target="_blank" href="https://creativecommons.org/licenses/by/4.0/" rel="noreferrer">
                https://creativecommons.org/licenses/by/4.0/
              </a>
            </span>
          </p>
        </div>
      </div>
      <div id="section18" className="cd-section mb-3">
        <div className="p-3">
          <h5>18. Mouse Genome Database (MGD)</h5>
          <p>
            Mouse Genome Database (MGD) at the{' '}
            <a target="_blank" href="https://www.informatics.jax.org/index.shtml" rel="noreferrer">
              Mouse Genome Informatics{' '}
            </a>
            website, The Jackson Laboratory, Bar Harbor, Maine. World Wide Web (URL:{' '}
            <a target="_blank" href="http://www.informatics.jax.org" rel="noreferrer">
              http://www.informatics.jax.org).{' '}
            </a>
            [August, 2023].
          </p>
          <p>
            MGD data and annotations are licensed under a{' '}
            <a target="_blank" href="https://creativecommons.org/licenses/by/4.0/" rel="noreferrer">
              Creative Commons Attribution 4.0 International License (CC-BY).
            </a>
          </p>
        </div>
      </div>

      <div id="section19" className="cd-section mb-3">
        <div className="p-3">
          <h5>19. Rat Genome Database (RGD)</h5>

          <p>
            RGD [Rattus norvergicus,
            https://download.rgd.mcw.edu/pub/data_release/annotated_rgd_objects_by_ontology/with_terms/rattus_terms_rdo
            and https://download.rgd.mcw.edu/data_release/RGD_ORTHOLOGS.txt ] Download file, Rat Genome
            Database Web Site, Medical College of Wisconsin, Milwaukee, Wisconsin. World Wide Web (URL:
            http://rgd.mcw.edu/). [Downloaded at 06:34PM on November 16, 2023]. RGD data and annotations are
            licensed under a
            <a target="_blank" href="https://creativecommons.org/licenses/by/4.0/" rel="noreferrer">
              {' '}
              Creative Commons Attribution 4.0 International License (CC-BY).
            </a>
          </p>
        </div>
      </div>
      <div id="section20" className="cd-section mb-3">
        <div className="p-3">
          <h5>20. FinnGen GWAS</h5>

          <p>
            The FinnGen study is a large-scale genomics initiative that has analyzed over 500,000 Finnish
            biobank samples and correlated genetic variation with health data to understand disease mechanisms
            and predispositions. The project is a collaboration between research organisations and biobanks
            within Finland and international industry partners.
          </p>
          <p>We incorporate data from FinnGen GWAS Release 12 (November, 2024).</p>
          <p>
            Kurki M.I., et al.{' '}
            <a href="https://www.nature.com/articles/s41586-022-05473-8" target="_blank" rel="noreferrer">
              FinnGen provides genetic insights from a well-phenotyped isolated population
            </a>
            FinnGen provides genetic insights from a well-phenotyped isolated population. Nature 2023
            Jan;613(7944):508-518. doi: 10.1038/s41586-022-05473-8. Epub 2023 Jan 18.
          </p>
        </div>
      </div>
      <div id="section21" className="cd-section mb-3">
        <div className="p-3">
          <h5>21. UK Biobank GWAS/PheWAS</h5>

          <p>
            The UK Biobank GWAS/PheWAS data set was obtained from{' '}
            <a href="https://pheweb.org/UKB-TOPMed/phenotypes" target="_blank" rel="noreferrer">
              https://pheweb.org/UKB-TOPMed/phenotypes
            </a>
            .
          </p>
          <p>
            The PheWeb tool is described in: Gagliano Taliun, Sarah A., et al.{' '}
            <a href="https://www.nature.com/articles/s41588-020-0622-5" target="_blank" rel="noreferrer">
              Exploring and visualizing large-scale genetic associations by using PheWeb
            </a>
            . Nature genetics 52.6 (2020): 550-552.
          </p>
          <p>
            The TOPMed-imputed UK Biobank is introduced in: ​​Taliun, Daniel, et al.{' '}
            <a href="https://www.nature.com/articles/s41586-021-03205-y" target="_blank" rel="noreferrer">
              Sequencing of 53,831 diverse genomes from the NHLBI TOPMed Program
            </a>
            . Nature 590.7845 (2021): 290-299.
          </p>
        </div>
      </div>
      <div id="section22" className="cd-section mb-3">
        <div className="p-3">
          <h5>22. Hancestro Ontology</h5>
          <p>
            Joannella Morales, Danielle Welter, Emily H. Bowler, Maria Cerezo, Laura W. Harris, Aoife C.
            McMahon, Peggy Hall, Heather A. Junkins, Annalisa Milano, Emma Hastings, Cinzia Malangone,
            Annalisa Buniello, Tony Burdett, Paul Flicek, Helen Parkinson, Fiona Cunningham, Lucia A. Hindorff
            and Jacqueline A. L. MacArthur. A standardized framework for representation of ancestry data in
            genomics studies, with application to the NHGRI-EBI GWAS Catalog. Genome Biology, 2018, 19:21
          </p>
          <p>
            <a href="https://github.com/ebispot/hancestro/" target="_blank" rel="noreferrer">
              https://github.com/ebispot/hancestro/
            </a>
          </p>
          <p>
            <a href="https://ebispot.github.io/hancestro/" target="_blank" rel="noreferrer">
              https://ebispot.github.io/hancestro/
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
