import React, { useState } from 'react';
import { userAppContext } from '../../Context/Context';
import { ToAcademicForm, ToUpgradePlanForm } from './UpgradeTrialForm';

const STEP_ONE = 1;
const STEP_TWO = 2;
const STEP_THREE = 3;

const TrialExpired = () => {
  const { user } = userAppContext();
  const isAcademic = user['custom:application_role'] === 'ROLE_ACADEMIC';

  const [step, setStep] = useState(STEP_ONE);
  const [selectedStep, setSelectedStep] = useState(null);

  const goToStep = targetStep => setStep(targetStep);

  const handleSelection = plan => {
    setSelectedStep(plan);
    goToStep(STEP_TWO);
  };

  const renderStepContent = () => {
    switch (step) {
      case STEP_ONE:
        return (
          <div className="profile-selection">
            <h1 className="header-topic gradient-color">Your 7-Day Free Trial Has Ended</h1>

            {isAcademic && (
              <div>
                <br />
                <h6>We are checking your application for academic user.</h6>
              </div>
            )}

            {!isAcademic && (
              <div>
                {/* <h6>Choose your access plan:</h6> */}
                <div className="cards-container">
                  {/* <div className="card">
                    <h3>Apply for Academic Plan</h3>
                    <p>Get in touch with us to upgrade to an academic plan.</p>
                    <button
                      className="btn-gradient-pink"
                      onClick={() => handleSelection('Request Academic Pricing')}
                    >
                      Go to Request
                    </button>
                  </div> */}
                  <div className="card">
                    <h3>Next Steps for Continued Access</h3>
                    <p>
                      Want to keep using DISGENET? Click the button below to get more information about
                      available about available plans and pricing.
                    </p>
                    <button
                      className="btn-gradient-pink"
                      onClick={() => handleSelection('Request Trial Pricing')}
                    >
                      Request Pricing
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        );
      case STEP_TWO:
        return selectedStep === 'Request Academic Pricing' ? (
          <ToAcademicForm onSubmit={() => goToStep(STEP_THREE)} />
        ) : (
          <ToUpgradePlanForm onSubmit={() => goToStep(STEP_THREE)} />
        );
      case STEP_THREE:
        return (
          <div className="final-step">
            <p>Your request has been successfully submitted.</p>
            <button className="btn-gradient-pink" onClick={() => goToStep(STEP_ONE)}>
              Back to Start
            </button>
          </div>
        );
      default:
        return null;
    }
  };

  const renderBreadcrumbs = () => (
    <nav className="breadcrumbs">
      <span onClick={() => goToStep(STEP_ONE)} style={{ cursor: 'pointer' }}>
        Trial Expired
      </span>

      {step >= STEP_TWO && (
        <>
          {' '}
          &gt; <span>{selectedStep}</span>
        </>
      )}
      {step === STEP_THREE && <span style={{ cursor: 'pointer' }}> &gt; Confirmation</span>}
    </nav>
  );

  return (
    <div className="trial-expired-container">
      {renderBreadcrumbs()}
      <div className="steps-container">{renderStepContent()}</div>
    </div>
  );
};

export default TrialExpired;
