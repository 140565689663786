import React from 'react';
import CommonHeader from '../../Components/Headers/CommonHeader.jsx';
import { CaseList } from '../../Components/CaseStudy/CaseList.jsx';
import SummaryStudy from '../../Components/CaseStudy/SummaryStudy.jsx';
import Footer from '../../Components/Home/Footer.jsx';

export default function CaseStudiesList({ study }) {
  return (
    <React.Fragment>
      <CommonHeader />
      {/* this page shows the list disgenet.com/case-studies*/}
      <div className="row p-4">
        <div className="col-12 col-md-2 order-0 order-md-0 mb-4 mb-md-0">
          <SummaryStudy />
        </div>
        <div className="col-12 col-md-8 order-1 order-md-1">
          <CaseList study={study} />
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}
