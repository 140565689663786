import React from 'react';
import CommonHeader from '../Components/Headers/CommonHeader';
import Login from '../Components/Search/Login';
import LoginSso from '../Components/Search/LoginSso';
import Footer from '../Components/Home/Footer';

export default function LoginPage({ isSso = false }) {
  return (
    <>
      <CommonHeader />
      <div>{isSso ? <LoginSso /> : <Login />}</div>
      <Footer />
    </>
  );
}
