import React from 'react';
import { Helmet } from 'react-helmet';
import { generateContent } from './PostGenerator';

const BlogPostDetails = ({ post }) => {
  if (!post) {
    return <div className="container">Post not found.</div>;
  }

  return (
    <div className="container pt-4 pb-4">
      <Helmet>
        <link rel="stylesheet" href={`/styles/main.css?v=${Date.now()}`} />
        <title>{post.title}</title>
        <meta name="description" content={post.description} />
        <meta name="keywords" content={post.tags?.join(', ')} />
        <meta name="title" property="og:title" content={post.title} />
        <meta name="description" property="og:description" content={post.description} />
        <meta name="image" property="og:image" content={post.image} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <div className="post-preview">{generateContent(post)}</div>
    </div>
  );
};

export { BlogPostDetails };
