import React, { useState } from 'react';
import { userAppContext } from '../../Context/Context';

function PricingForm({ formType, messageText, onSubmit }) {
  const { user } = userAppContext();

  const [formData, setFormData] = useState({
    message: messageText,
  });
  const [formErrors, setFormErrors] = useState({});
  const [emailSentMessage, setEmailSentMessage] = useState('');

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async e => {
    e.preventDefault();

    // Validation checks
    const errors = {};

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    setFormErrors({});

    const messageInfo = {
      name: user['custom:name'],
      email: user.email,
      organization: formData.organization,
      message: messageText,
      subject: formType,
    };

    try {
      const response = await fetch('https://p3xqewey7e.execute-api.eu-central-1.amazonaws.com/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(messageInfo),
      });

      setEmailSentMessage('Your request was successfully sent!');
      setFormData({ organization: '' });
      onSubmit();
    } catch (e) {
      setEmailSentMessage('There was an error with email submission. Please try again or contact us.');
    }
  };

  return (
    <form className={`${formType.toLowerCase()}-form`} onSubmit={handleSubmit}>
      <h2 className="form-header">{`${formType}`}</h2>
      <div className="form-group">
        <input type="text" value={user['custom:name']} disabled placeholder="Full Name" />
      </div>
      <div className="form-group">
        <input type="email" value={user.email} disabled placeholder="Email Address" />
      </div>
      <div className="form-group">
        <textarea name="message" value={formData.message} onChange={handleChange} placeholder="Message" />
      </div>

      {emailSentMessage && <p>{emailSentMessage}</p>}

      <button type="submit" className="btn-gradient-pink">
        Request Pricing
      </button>
    </form>
  );
}

export function ToAcademicForm({ onSubmit }) {
  return (
    <PricingForm
      formType="Academic Request for Trial Users"
      messageText="Please convert my Trial user to an Academic user."
      // messageText="I am an academic user and would like more information about the Standard and Advanced plan pricing to access the full DISGENET database."
      onSubmit={onSubmit}
    />
  );
}

export function ToUpgradePlanForm({ onSubmit }) {
  return (
    <PricingForm
      formType="Upgrade Request for Trial Users"
      messageText="I am a commercial/business user, and my trial period has expired. I would like more information about the pricing and upgrade options to access the full DISGENET database."
      onSubmit={onSubmit}
    />
  );
}

export default { ToAcademicForm, ToUpgradePlanForm };
