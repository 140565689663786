import React from 'react';

function AboutOntology() {
  return (
    <div>
      {' '}
      <h2>The DISGENET Association Type Ontology</h2>
      <p>
        For a seamless integration of gene-disease association data, we developed the DISGENET association
        type ontology. All association types as found in the original source databases are formally structured
        from a parent <font className="myfont"> GeneDiseaseAssociation </font> class if there is a
        relationship between the gene/protein and the disease, and represented as ontological classes.
      </p>
      <h3>DISGENET ontology for the GDAs</h3>
      <img
        src="../../static/images/disgenet-ontology_gdas.svg"
        style={{ float: 'middle', margin: '20px 20px 20px 60px' }}
        width="100%"
        height="100%"
        alt="DISGENET association types"
      />
      <p>
        <strong>The description of each association type in our ontology is:</strong>
      </p>
      <div className="hangingindent">
        <ul>
          <li>
            <b>Causal or Contributing</b>: This relationship indicates that a genotype (gene or genetic
            variation) has some causal or contributing role influencing the condition (a phenotype or disease)
          </li>
          <li>
            <b>Biomarker</b>: This relationship indicates that the gene is a biomarker for the disease
          </li>
          <li>
            <b>Cell Marker</b>: This relationship indicates that a biomarker measured as cell surface antigen
            is associated with the disease phenotype.
          </li>
          <li>
            <b>Altered Expression</b>: This relationship indicates that an altered expression of the gene is
            associated with the disease phenotype.
          </li>
          <li>
            <b>Post-translational Modification: </b>: This relationship indicates that alterations in the
            function of the protein by means of post-translational modifications (methylation or
            phosphorylation of the protein) are associated with the disease phenotype.
          </li>
          <li>
            <b>Phosphorylation</b>: This relationship indicates that changes in levels of protein
            phosphorylation are associated with the disease phenotype.
          </li>
          <li>
            <b>Epigenomic Alterations</b>: This relationship indicates that changes in DNA methylation
            patterns are associated with the disease phenotype.
          </li>
          <li>
            <b>Genomic Alterations</b>: This relationship indicates that a genomic alteration is linked to the
            gene associated with the disease phenotype.
          </li>
          <li>
            <b>Genetic Variation</b>: This relationship indicates that a sequence variation (a mutation, an
            SNP) is associated with the disease phenotype, but there is still no evidence to say that the
            variation causes the disease.
          </li>
          <li>
            <b>Causal Mutation</b>: This relationship indicates that there are allelic variants or mutations
            known to cause the disease.
          </li>
          <li>
            <b>Germline Causal Mutation</b>: This relationship indicates that there are germline allelic
            variants or mutations known to cause the disease, and they may be passed on to offspring.
          </li>
          <li>
            <b>Somatic Causal Mutation</b>: This relationship indicates that there are somatic allelic
            variants or mutations known to cause the disease, but they may not be passed on to offspring.
          </li>
          <li>
            <b>Chromosomal Rearrangement</b>: This relationship indicates that a gene is included in a
            chromosomal rearrangement associated with a particular manifestation of the disease.
          </li>
          <li>
            <b>Fusion Gene</b>: This relationship indicates that the fusion between two different genes
            (between promoter and/or other coding DNA regions) is associated with the disease.
          </li>
          <li>
            <b>Susceptibility Mutation</b>: This relationship indicates that a gene mutation in a germ cell
            predisposes to the development of a disorder, and that is necessary but not sufficient for the
            manifestation of the disease.
          </li>
          <li>
            <b>Modifying Mutation</b>: This relationship indicates that a gene mutation is known to modify the
            clinical presentation of the disease.
          </li>
          <li>
            <b>Germline Modifying Mutation</b>: This relationship indicates that a germline gene mutation
            modifies the clinical presentation of the disease, and it may be passed on to offspring.
          </li>
          <li>
            <b>Somatic Modifying Mutation</b>: This relationship indicates that a somatic gene mutation
            modifies the clinical presentation of the disease, but it may not be passed on to offspring.
          </li>
          <li>
            <b>Protective</b>: This relationship indicates that a variant decreases the risk of a disorder,
            including infections.
          </li>
          <li>
            <b>Drug Response</b>: This relationship indicates that a variant affects the response to a
            chemical.
          </li>
          <li>
            <b>Variant of Uncertain significance (VUS)</b>: This relationship indicates that the variant is of
            unknown clinical significance.
          </li>
        </ul>
      </div>
      <h3>DISGENET ontology for the VDAs</h3>
      <img
        src="../../static/images/disgenetplus_ontology_vdas_v31082023.svg"
        style={{ float: 'middle', margin: '20px 20px 20px 60px' }}
        width="100%"
        height="100%"
        alt="DISGENET association types"
      />
      <p>
        {' '}
        Additionally, for the VDAs, we have created in DISGENET two categories (Gain of Function, and Loss of
        Function) that modify the terms in the ontology that are children of Genetic Variation, and the term
        itself, Genetic Variation.
      </p>
    </div>
  );
}

export default AboutOntology;

// </div>
