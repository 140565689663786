import React from 'react';
import { jaccardGda, jaccardVda } from '../AboutHelpers';

function AboutSourcesDda() {
  return (
    <div id="section13">
      <h3>Disease-Disease Associations</h3>
      <p>
        The disease-disease associations (DDA) in DISGENET have been obtained by computing the number of
        shared genes, and shared variants between pairs of diseases, by source. The DDAs can be explored from
        the Search panel by searching by disease (one or multiple diseases).
      </p>
      <p>
        Disease-Disease associations are obtained as described below from all the sources available for GDAs
        and VDAs.
      </p>
      <p className="top2">
        For each disease pair, we computed a Jaccard Index (JI), to assess the fraction of shared genes (or
        variants) among the diseases according to:
      </p>

      <div className="row">
        <div className="col-sm-5">{jaccardGda()}</div>
        <div className="col-sm-offset-1 col-sm-4">
          <dt>where: </dt>Through the
          <p>
            G<sub>1</sub> is the set of genes associated to Disease 1
          </p>
          <p>
            G<sub>2</sub> is the set of genes associated to Disease 2
          </p>
        </div>
      </div>
      <div className="row top5">
        <div className="col-sm-5">{jaccardVda()}</div>
        <div className="col-sm-offset-1 col-sm-4">
          <dt>where: </dt>
          <p>
            V<sub>1</sub> is the set of variants associated to Disease 1
          </p>
          <p>
            V<sub>2</sub> is the set of variants associated to Disease 2
          </p>
        </div>
      </div>
      <p className="mt-3">
        For each disease-disease association, we perform two Fisher's exact tests, one based on the shared
        genes, and one on the shared variants, and we filter the associations to keep only those with p values
        of the Fisher test for the genes or for the variants equal or smaller than 10
        <sup>-6</sup>
      </p>
    </div>
  );
}

export default AboutSourcesDda;
