import React, { useState } from 'react';
import { resetPassword } from 'aws-amplify/auth';
import { userAppContext } from '../../Context/Context';
import NewPassword from './NewPassword';

export default function Reset({ setReset }) {
  const [errorMessage, setErrorMessage] = useState('');
  const { setIsAuthenticated, setAuthorization, setGroups } = userAppContext();
  const [name, setName] = useState('');
  const [newPassword, setNewPassword] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async evt => {
    evt.preventDefault();
    if (!name) return;

    try {
      setErrorMessage('');
      await resetPassword({ username: name });
      setNewPassword(true);
      setIsSubmitted(true);
    } catch (error) {
      setErrorMessage(error.message);
      setIsSubmitted(false);
    }
  };

  const validateInput = inputValue => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputValue.trim());
  };

  const handleBlur = event => {
    if (!validateInput(event.target.value)) {
      setErrorMessage('Invalid email format');
    } else {
      setErrorMessage('');
    }
  };

  return newPassword ? (
    <NewPassword setReset={setReset} name={name} />
  ) : (
    <div className="txt-contenido">
      <div className="login-container mb-5">
        <div className="signin-container">
          <div className="col-12">
            <form className="validate-form" onSubmit={handleSubmit}>
              <h3 style={{ color: 'white' }} className="reset-title">
                E-MAIL ADDRESS
              </h3>
              <div className="validate-input" data-validate="User Name is required">
                <span className="label-input100" style={{ color: 'white' }}>
                  Email:
                </span>
                <div className="line-input">
                  <input
                    style={{ color: 'white' }}
                    onChange={event => setName(event.target.value)}
                    onBlur={handleBlur}
                    className="input100"
                    type="email"
                    name="email"
                    placeholder="Email..."
                  />
                </div>
              </div>
              <a
                style={{ float: 'right', marginBottom: '20px', color: 'white' }}
                href="true"
                onClick={evt => {
                  evt.preventDefault();
                  setReset(false);
                }}
              >
                Cancel
              </a>
              <div className="container-contact100-form-btn">
                <div className="wrap-contact100-form-btn">
                  <div className="contact100-form-bgbtn" />
                  <button className="contact100-form-btn">Send</button>
                </div>
              </div>
              <div>
                <div className="mt-3">{errorMessage && <p className="text-center">{errorMessage}</p>}</div>
                {isSubmitted && (
                  <p className="mt-3" style={{ color: 'white' }}>
                    We will send the confirmation code to your email
                  </p>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
