import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../Components/Common/Logo';
import TrialExpired from '../Components/SignUp/TrialExpired';
import { userAppContext } from '../Context/Context';

function ExpiredPage() {
  const { isAuthenticated } = userAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated === true) {
      navigate({
        pathname: '/',
      });
    }
  });

  return (
    <div>
      <nav className="header-logo">
        <Logo />
        <a
          href="https://disgenet.freshdesk.com/support/home"
          target="_blank"
          rel="noopener noreferrer"
          className="custom-support"
        >
          Support
        </a>
      </nav>
      <div>
        <TrialExpired />
      </div>
      <footer className="footer-expired">
        <p className="text-center">&copy; 2025 MedBioinformatics Solutions SL.</p>
      </footer>
    </div>
  );
}

export default ExpiredPage;
