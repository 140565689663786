export const advancedExamples = {
  DISEASES: {
    diseaseUMLSCUI: [
      'C0002736',
      'C1862939',
      'C2677565',
      'C2675491',
      'C3150692',
      'C3151403',
      'C3275459',
      'C3280587',
      'C3553719',
      'C3715155',
      'C1859807',
      'C3715156',
      'C3807521',
      'C4016414',
      'C4015512',
      'C4693381',
      'C5436882',
      'C5830359',
      'C5830642',
      'C1847735',
      'C1865409',
      'C1865864',
      'C2750729',
      'C1837728',
      'C2678468',
      'C4302169',
      'C0393554',
      'C1862940',
      'C4551993',
      'C0543859',
      'C1862941',
      'C4016314',
      'C3149907',
      'C4693523',
      'C4693609',
      'C2931786',
      'C4024604',
      'C1834304',
      'C1865384',
      'C5568978',
      'C5686324',
      'C0393552',
      'C4522181',
      'C0221759',
      'C1839259',
      'C0007286',
      'C5704675',
      'C1839566',
      'C0271685',
      'C1848736',
    ],
    name: ['Amyotrophic Lateral Sclerosis', 'Breast Neoplasms', 'Colorectal Neoplasm', 'Neoplasm, Prostatic'],
    MSH: [
      'D014211',
      'D003922',
      'D003923',
      'D009369',
      'D002283',
      'D000544',
      'D003920',
      'D006973',
      'D010300',
      'D001249',
      'D009765',
      'D007676',
    ],
    OMIM: [
      '147450',
      '612069',
      '602432',
      '606352',
      '137070',
      '105400',
      '104300',
      '222100',
      '145900',
      '168600',
      '101600',
      '601806',
      '609441',
      '609529',
      '602544',
    ],
    NCI: [
      'C34373',
      'C34558',
      'C131626',
      'C153359',
      'C131687',
      'C132826',
      'C153387',
      'C129806',
      'C34379',
      'C34381',
      'C34372',
      'C34375',
      'C34382',
      'C34383',
    ],
    EFO: [
      '0001357',
      '0001260',
      '0001261',
      '0001262',
      '0001263',
      '0001264',
      '0001265',
      '0001266',
      '0001267',
      '0001268',
      '0001269',
      'exemplo',
    ],
    DO: [
      '0060193',
      '0060196',
      '0060197',
      '0060198',
      '0060199',
      '0060200',
      '0060201',
      '0060202',
      '0060203',
      '0060204',
      '0060205',
    ],
    ORDO: [
      '88673',
      '918',
      '234567',
      '345678',
      '456789',
      '567890',
      '678901',
      '789012',
      '890123',
      '901234',
      '112345',
      '223456',
      '334567',
      '445678',
      '556789',
      '667890',
      '778901',
      '889012',
      '990123',
      '101234',
      '112345',
      '123456',
      '134567',
      '145678',
      '156789',
      '167890',
      '178901',
      '189012',
      '290123',
      '301234',
      '312345',
      '323456',
      '434567',
      '545678',
      '656789',
      '767890',
      '878901',
      '989012',
      '990234',
      '100123',
      '110234',
      '120345',
      '130456',
      '140567',
      '150678',
      '160789',
      '170890',
      '180901',
      '190012',
      '200123',
      '210234',
      '220345',
      '230456',
      '240567',
      '250678',
      '260789',
      '270890',
      '280901',
      '290012',
      '300123',
      '310234',
      '320345',
      '330456',
      '340567',
      '350678',
      '360789',
      '370890',
      '380901',
      '390012',
      '400123',
      '410234',
      '420345',
      '430456',
      '440567',
      '450678',
      '460789',
      '470890',
      '480901',
      '490012',
      '500123',
      '510234',
      '520345',
      '530456',
      '540567',
      '550678',
      '560789',
      '570890',
      '580901',
      '590012',
      '600123',
      '610234',
      '620345',
      '630456',
      '640567',
      '650678',
      '660789',
      '670890',
      '680901',
      '690012',
      '700123',
      '710234',
      '720345',
      '730456',
      '740567',
      '750678',
      '760789',
      '770890',
      '780901',
      '790012',
      '800123',
      '810234',
      '820345',
    ],
    HPO: [
      'HP:0009031',
      'HP:0001250',
      'HP:0004322',
      'HP:0001943',
      'HP:0001658',
      'HP:0002376',
      'HP:0000238',
      'HP:0001290',
      'HP:0001871',
      'HP:0004328',
    ],
    MONDO: [
      '0005335',
      '0008315',
      '0004950',
      '0005159',
      '0008315',
      '0005138',
      '0004989',
      '0007254',
      '0007256',
      '0005335',
      '0007103',
      '0007108',
      '0007109',
      '0007110',
      '0007113',
      '0007114',
    ],
  },
  VARIANTS: {
    strID: [
      'rs75290158',
      'rs142200609',
      'rs893051',
      'rs2228145',
      'rs12720356',
      'rs11385942',
      'rs1799853',
      'rs2236225',
      'rs6754875',
      'rs7138803',
      'rs4994',
      'rs17782313',
      'rs1421085',
      'rs8050136',
      'rs1044498',
      'rs2229616',
      'rs17817449',
      'rs13447331',
      'rs6548238',
      'rs1558902',
      'rs10938397',
      'rs1121980',
      'rs7498665',
      'rs52820871',
      'rs13447329',
      'rs1800571',
      'rs34911341',
      'rs397832689',
      'rs987237',
      'rs1805081',
      'rs7754561',
      'rs79783591',
      'rs1800437',
      'rs6567160',
      'rs121909065',
      'rs10968576',
      'rs7132908',
      'rs12463617',
      'rs2605100',
      'rs7754840',
      'rs4684677',
      'rs942758928',
      'rs9941349',
      'rs1057517991',
      'rs988712',
      'rs121913561',
      'rs121913563',
      'rs747681609',
      'rs52804924',
      'rs187152753',
    ],
    geneSymbol: ['EGFR', 'TLR10', 'KRAS', 'MLH1', 'PTEN', 'MTHFR'],
  },
  GENES: {
    symbol: [
      'TROAP-AS1',
      'BRCA1',
      'TP53',
      'EGFR',
      'TLR10',
      'TLR1',
      'VHL',
      'RB1',
      'CFTR',
      'BRCA2',
      'PPARGC1A',
      'FUS',
      'C9orf72',
      'UNC13A',
      'NEFH',
      'SOD1',
      'TARDBP',
      'OPTN',
      'SQSTM1',
      'DCTN1',
      'VCP',
      'PON1',
      'CHMP2B',
      'VAPB',
      'UBQLN2',
      'ANG',
      'PRPH',
      'DAO',
      'NEK1',
      'ATXN2',
      'ALS2',
      'CFAP410',
      'ERBB4',
      'SCFD1',
      'MATR3',
      'CCNF',
      'FIG4',
      'PFN1',
      'TBK1',
      'GLE1',
      'CHCHD10',
      'NEFL',
      'GFAP',
      'GRN',
      'MIR206',
      'HNRNPA1',
      'KIF5A',
      'EWSR1',
      'TAF15',
      'HNRNPA2B1',
      'TREM2',
    ],
    geneID: ['672', '7157', '1956', '8174', '7098', '7428', '5925', '1080', '675'],
    uniprotID: [
      'P04637',
      'Q9BYF1',
      'P38398',
      'Q16539',
      'P38398',
      'P35222',
      'P00533',
      'Q6P6B7',
      'P01106',
      'Q16850',
    ],
    ensemblID: [
      'ENSG00000141510',
      'ENSG00000134982',
      'ENSG00000157764',
      'ENSG00000197386',
      'ENSG00000148795',
      'ENSG00000169083',
      'ENSG00000164327',
      'ENSG00000196367',
    ],
  },
  CHEMICALS: {
    chemID: [
      'C-329331',
      'C-534865',
      'C-1219003',
      'C-2500077',
      'C-1219003',
      // 'C0061516',
      // 'C0012772',
      // 'C3658706',
      // 'C0028833',
      // 'C2930764',
      // 'C0171023',
      // 'C0009079',
      // 'C0170531',
      // 'C0167117',
      // 'C0132776',
      // 'C0085149',
      // 'C0074554',
      // 'C0074493',
      // 'C0036751',
      // 'C0073393',
      // 'C0071097',
      // 'C0070166',
      // 'C0056391',
      // 'C0020268',
      // 'C0043572',
      // 'C0043031',
      // 'C0019134',
      // 'C0042874',
      // 'C0042866',
      // 'C0042845',
      // 'C0040165',
      // 'C0039902',
      // 'C0036581',
      // 'C0035527',
      // 'C0028928',
      // 'C0076275',
      // 'C0041073',
      // 'C0028128',
      // 'C0026549',
      // 'C0025598',
      // 'C0023749',
      // 'C0017245',
      // 'C0015689',
      // 'C0014912',
      // 'C0013030',
      // 'C0010590',
      // 'C0009170',
      // 'C0008286',
      // 'C0006657',
      // 'C0004749',
    ],
    chemPrefName: [
      'Aspirin',
      'Ibuprofen',
      'Acetaminophen',
      'Morphine',
      'Caffeine',
      'Lidocaine',
      'Epinephrine',
      'Metformin',
      'Hydrochlorothiazide',
    ],
  },
};
