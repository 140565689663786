import React from 'react';
import { useLocation } from 'react-router-dom';
import { MathJaxContext } from 'better-react-mathjax';
import { TableFromFile } from './Tsv_parser.jsx';
import { HashLink } from 'react-router-hash-link';

import AboutSources from './AboutSections/AboutSources.jsx';
import AboutAttributes from './AboutSections/AboutAttributes.jsx';
import AboutGeneTypes from './AboutSections/AboutGeneTypes.jsx';
import AboutVariantTypes from './AboutSections/AboutVariantTypes.jsx';
import AboutSchema from './AboutSections/AboutSchema.jsx';
import AboutMetrics from './AboutSections/AboutMetrics.jsx';
import AboutOntology from './AboutSections/AboutOntology.jsx';
import AboutCiteUs from './AboutSections/AboutCiteUs.jsx';

import StatisticsCurrent from './AboutSections/StatisticsCurrent.jsx';
import StatisticsSummaryTable from './AboutSections/StatisticsSummaryTable.jsx';
import Statistics251 from './AboutSections/Statistics251.jsx';
import Statistics251Changelog from './AboutSections/Statistics251Changelog.jsx';

function AboutContent() {
  const location = useLocation();
  const hash = location.hash;
  const possibleMetricsHash = [
    '#metrics',
    '#pleiotropy',
    '#specificity',
    '#evindex',
    '#gdaScore',
    '#vdaScore',
  ];
  const possibleSourcesHash = ['#sources', '#section11', '#section12', '#section13'];
  const possibleAttributesHash = [
    '#attributes',
    '#section41',
    '#section42',
    '#section43',
    '#section44',
    '#section45',
    '#section46',
    '#section47',
  ];

  const hashlinkBootstrapClasses = 'mb-1 dropdown-item text-wrap';

  return (
    <MathJaxContext>
      <section className="container">
        <h1 className="firstHiddenTitle">Information about database statistics, attributes and metrics.</h1>
        <div className="row txt-contenido border-bottom pt-4 pb-4">
          <div className="col-12 col-md-3 order-0 order-md-0 mb-4 mb-md-0">
            <div className="nav p-0 dropdown-menu-inline dropdown-menu-rounded dropdown-menu-hover-primary">
              <h5>Statistics</h5>
              <HashLink
                to="#gdaStatistics"
                data-toggle="tab"
                data-target="#gdaStatistics"
                className={`${hashlinkBootstrapClasses} ${
                  hash === '' || hash === '#gdaStatistics' ? 'active' : ''
                }`}
              >
                {' '}
                DISGENET statistics{' '}
              </HashLink>
              <HashLink
                to="#gene_type"
                data-toggle="tab"
                data-target="#gene_type"
                className={`${hashlinkBootstrapClasses} ${hash === '#gene_type' ? 'active' : ''}`}
              >
                {' '}
                Gene types{' '}
              </HashLink>
              <HashLink
                to="#variant_type"
                data-toggle="tab"
                data-target="#variant_type"
                className={`${hashlinkBootstrapClasses} ${hash === '#variant_type' ? 'active' : ''}`}
              >
                Variant types
              </HashLink>

              <h5 className="mt-3">Data</h5>
              <HashLink
                to="#schema"
                data-toggle="tab"
                data-target="#schema"
                className={`${hashlinkBootstrapClasses} ${hash === '#schema' ? 'active' : ''}`}
              >
                {' '}
                Schema{' '}
              </HashLink>
              <HashLink
                to="#sources"
                data-toggle="tab"
                data-target="#sources"
                className={`${hashlinkBootstrapClasses} ${
                  possibleSourcesHash.includes(hash) ? 'active' : ''
                }`}
              >
                {' '}
                Sources{' '}
              </HashLink>
              <HashLink
                to="#metrics"
                data-toggle="tab"
                data-target="#metrics"
                className={`${hashlinkBootstrapClasses} ${
                  possibleMetricsHash.includes(hash) ? 'active' : ''
                }`}
              >
                {' '}
                Metrics{' '}
              </HashLink>
              <HashLink
                to="#attributes"
                data-toggle="tab"
                data-target="#attributes"
                className={`${hashlinkBootstrapClasses} ${
                  possibleAttributesHash.includes(hash) ? 'active' : ''
                }`}
              >
                {' '}
                Attributes{' '}
              </HashLink>
              <HashLink
                to="#assoctypeont"
                data-toggle="tab"
                data-target="#assoctypeont"
                className={`${hashlinkBootstrapClasses} ${hash === '#assoctypeont' ? 'active' : ''}`}
              >
                DISGENET Association Type Ontology
              </HashLink>

              <h5 className="mt-3">How To Cite Us</h5>
              <HashLink
                to="#cite_us"
                data-toggle="tab"
                data-target="#cite_us"
                className={`${hashlinkBootstrapClasses} ${hash === '#cite_us' ? 'active' : ''}`}
              >
                How to cite us
              </HashLink>
              <h5 className="mt-3">Version History</h5>
              <HashLink
                to="#version_25.1"
                data-toggle="tab"
                data-target="#version_25.1"
                className={`${hashlinkBootstrapClasses} ${hash === '#version_25.1' ? 'active' : ''}`}
              >
                Version 25.1
              </HashLink>
              <HashLink
                to="#version_24.4"
                data-toggle="tab"
                data-target="#version_24.4"
                className={`${hashlinkBootstrapClasses} ${hash === '#version_24.4' ? 'active' : ''}`}
              >
                Version 24.4
              </HashLink>
              <HashLink
                to="#version_24.3"
                data-toggle="tab"
                data-target="#version_24.3"
                className={`${hashlinkBootstrapClasses} ${hash === '#version_24.3' ? 'active' : ''}`}
              >
                Version 24.3
              </HashLink>
              <HashLink
                to="#version_24.2"
                data-toggle="tab"
                data-target="#version_24.2"
                className={`${hashlinkBootstrapClasses} ${hash === '#version_24.2' ? 'active' : ''}`}
              >
                Version 24.2
              </HashLink>
              <HashLink
                to="#version_24.1"
                data-toggle="tab"
                data-target="#version_24.1"
                className={`${hashlinkBootstrapClasses} ${hash === '#version_24.1' ? 'active' : ''}`}
              >
                Version 24.1
              </HashLink>
              <HashLink
                to="#version_24"
                data-toggle="tab"
                data-target="#version_24"
                className={`${hashlinkBootstrapClasses} ${hash === '#version_24' ? 'active' : ''}`}
              >
                Version 24
              </HashLink>
              <HashLink
                to="#version_23.1"
                data-toggle="tab"
                data-target="#version_23.1"
                className={`${hashlinkBootstrapClasses} ${hash === '#version_23.1' ? 'active' : ''}`}
              >
                Version 23.1
              </HashLink>
              <HashLink
                to="#version_23"
                data-toggle="tab"
                data-target="#version_23"
                className={`${hashlinkBootstrapClasses} ${hash === '#version_23' ? 'active' : ''}`}
              >
                Version 23
              </HashLink>
              <HashLink
                to="#version_22"
                data-toggle="tab"
                data-target="#version_22"
                className={`${hashlinkBootstrapClasses} ${hash === '#version_22' ? 'active' : ''}`}
              >
                Version 22
              </HashLink>
              <HashLink
                to="#version_21"
                data-toggle="tab"
                data-target="#version_21"
                className={`${hashlinkBootstrapClasses} ${hash === '#version_21' ? 'active' : ''}`}
              >
                Version 21
              </HashLink>
              <HashLink
                to="#version_20.1"
                data-toggle="tab"
                data-target="#version_20.1"
                className={`${hashlinkBootstrapClasses} ${hash === '#version_20.1' ? 'active' : ''}`}
              >
                Version 20.1
              </HashLink>
              <HashLink
                to="#version_20"
                data-toggle="tab"
                data-target="#version_20"
                className={`${hashlinkBootstrapClasses} ${hash === '#version_20' ? 'active' : ''}`}
              >
                Version 20
              </HashLink>
              <HashLink
                to="#version_19"
                data-toggle="tab"
                data-target="#version_19"
                className={`${hashlinkBootstrapClasses} ${hash === '#version_19' ? 'active' : ''}`}
              >
                Version 19
              </HashLink>
              <HashLink
                to="#version_18"
                data-toggle="tab"
                data-target="#version_18"
                className={`${hashlinkBootstrapClasses} ${hash === '#version_18' ? 'active' : ''}`}
              >
                Version 18
              </HashLink>
              <HashLink
                to="#version_17"
                data-toggle="tab"
                data-target="#version_17"
                className={`${hashlinkBootstrapClasses} ${hash === '#version_17' ? 'active' : ''}`}
              >
                Version 17
              </HashLink>
              <HashLink
                to="#version_16"
                data-toggle="tab"
                data-target="#version_16"
                className={`${hashlinkBootstrapClasses} ${hash === '#version_16' ? 'active' : ''}`}
              >
                Version 16
              </HashLink>
              <HashLink
                to="#version_15"
                data-toggle="tab"
                data-target="#version_15"
                className={`${hashlinkBootstrapClasses} ${hash === '#version_15' ? 'active' : ''}`}
              >
                Version 15
              </HashLink>
            </div>
          </div>

          <div className="col-12 col-md-8 order-1 order-md-1">
            <div>
              <div className="tab-content" id="dbInfoTabbedPane">
                <div
                  className={`tab-pane ${hash === '' || hash === '#gdaStatistics' ? 'active show' : ''}`}
                  id="gdaStatistics"
                >
                  <h2>Statistics</h2>
                  <StatisticsCurrent />
                  <StatisticsSummaryTable />
                  <h3 className="mt-5">Distribution of genes, diseases, and GDAs by source</h3>
                  <div className="table-responsive">
                    <table
                      className="table panther-table"
                      style={{ float: 'middle', margin: '10px 20px 40px 10px' }}
                    >
                      <thead className="text-center">
                        <tr>
                          <th>Source</th>
                          <th>Genes</th>
                          <th>Diseases</th>
                          <th>GDAs</th>
                          <th>Evidences</th>
                        </tr>
                      </thead>
                      <tbody className="text-center">
                        <TableFromFile filePath="../../static/files/gdas_stats.tsv" />
                      </tbody>
                    </table>
                  </div>

                  <h3 className="mt-5">Distribution of variants, diseases, and VDAs by source</h3>
                  <div className="table-responsive">
                    <table
                      className="table panther-table"
                      style={{ float: 'middle', margin: '10px 20px 40px 10px' }}
                    >
                      <thead className="text-center">
                        <tr>
                          <th>Source</th>
                          <th>Variants</th>
                          <th>Diseases</th>
                          <th>VDAs</th>
                          <th>Evidences</th>
                        </tr>
                      </thead>
                      <tbody className="text-center">
                        <TableFromFile filePath="../../static/files/vdas_stats.tsv" />
                      </tbody>
                    </table>
                  </div>
                  <h3 className="mt-5">DISGENET provides ample coverage of all disease areas...</h3>

                  <img
                    src="../../static/images/plot_msh.svg"
                    style={{ float: 'middle', margin: '20px 20px 20px 10px' }}
                    width="100%"
                    height="100%"
                    alt="DISGENET therapeutic areas"
                  />
                  <h3 className="mt-5">...and protein classes relevant for chemical R&D</h3>
                  <img
                    src="../../static/images/plot_dto.svg"
                    style={{ float: 'middle', margin: '20px 20px 20px 10px' }}
                    width="100%"
                    height="100%"
                    alt="DISGENET protein classes"
                  />
                </div>

                <div className={`tab-pane ${hash === '#gene_type' ? 'active show' : ''}`} id="gene_type">
                  <AboutGeneTypes />
                </div>

                <div
                  className={`tab-pane ${hash === '#variant_type' ? 'active show' : ''}`}
                  id="variant_type"
                >
                  <AboutVariantTypes />
                </div>

                <div className={`tab-pane ${hash === '#schema' ? 'active show' : ''}`} id="schema">
                  <AboutSchema />
                </div>

                <div
                  className={`tab-pane ${possibleSourcesHash.includes(hash) ? 'active show' : ''}`}
                  id="sources"
                >
                  <AboutSources />
                </div>

                <div
                  className={`tab-pane ${possibleMetricsHash.includes(hash) ? 'active show' : ''}`}
                  id="metrics"
                >
                  <AboutMetrics />
                </div>

                <div
                  className={`tab-pane ${possibleAttributesHash.includes(hash) ? 'active show' : ''}`}
                  id="attributes"
                >
                  <AboutAttributes />
                </div>

                <div
                  className={`tab-pane ${hash === '#assoctypeont' ? 'active show' : ''}`}
                  id="assoctypeont"
                >
                  <AboutOntology />
                </div>

                <div className={`tab-pane ${hash === '#cite_us' ? 'active show' : ''}`} id="cite_us">
                  <AboutCiteUs />
                </div>

                {/* VERSION HISTORY */}

                <div
                  className={`tab-pane ${hash === '#version_25.1' ? 'active show' : ''}`}
                  id="version_25.1"
                >
                  <h2 className="version-number">March 30th, 2025: Release of version 25.1 of DISGENET</h2>
                  <Statistics251 />
                  <Statistics251Changelog />
                </div>

                <div
                  className={`tab-pane ${hash === '#version_24.4' ? 'active show' : ''}`}
                  id="version_24.4"
                >
                  <h2 className="version-number">December 4th, 2024: Release of version 24.4 of DISGENET</h2>
                  <p>
                    The current version of DISGENET (v24.4) contains 1,957,356 gene-disease associations
                    (GDAs), between 26,798 genes and 39,972 diseases and traits; 1,245,570 variant-disease
                    associations (VDAs), between 728,547 variants and 16,974 diseases and traits, and over 28M
                    disease-disease associations. DISGENET also contains more than 369,000 associations
                    involving 13,500 diseases detected in animal models. This release provides annotations for
                    4,044 chemicals associated with GDAs and VDAs. In total, DISGENET contains a corpus of
                    1,572,260 publications that support the genotype-phenotype associations.
                  </p>
                  <h6>DATA:</h6>
                  <ul>
                    <li className="bullet-item">
                      All data sources updated: we increased the number of GDAs and VDAs by approx. 10%
                    </li>
                  </ul>
                  <h6>WEB:</h6>
                  <p>
                    <strong>New feature: Advanced search.</strong>
                  </p>
                  <p>
                    With this new feature, the web interface supports searches for a list of entities (genes,
                    diseases, variants and chemicals). The advanced search can support users that want to
                    perform searches for multiple entities without using the REST API. The advanced search
                    supports searches for up to 100 entities. Give it a try{' '}
                    <a href="/advancedSearch" target="_blank" rel="noreferrer">
                      here
                    </a>
                    .
                  </p>
                  <h6>REST API:</h6>
                  <p>New release of the REST API (1.7.0)</p>
                  <ul>
                    <li className="bullet-item">
                      A new endpoint is available for performing enrichment analysis on lists of genes and
                      variants to identify statistically significant associated diseases.
                    </li>
                    <li className="bullet-item">
                      The GDA and VDA summary endpoints now allow ordering the results by score, DSI, DPI,
                      yearInitial, yearFinal, and numCTsupportingAssociation
                    </li>
                    <li className="bullet-item">
                      The GDA and VDA evidence endpoints now allow ordering the results by score, DSI, DPI,
                      and pmYear
                    </li>
                    <li className="bullet-item">
                      The VDA summary and evidence endpoints now allow retrieve of information using protein
                      coordinates (for example, BRAF V157A or BRAF Val157Ala).
                    </li>
                    <li className="bullet-item">
                      The GDA and VDA summary and evidence endpoints now include a new parameter,
                      <strong> chemical_association</strong>, which enables the retrieval of all GDAs or VDAs
                      linked to genes, diseases, or variants that involve a chemical association.
                    </li>
                  </ul>
                  <h6>R PACKAGE :</h6>
                  <p>
                    New release of the disgenet2r R package (1.2.1). For detailed information, see the{' '}
                    <a
                      href="https://gitlab.com/medbio/disgenet2r/-/blob/master/NEWS.md"
                      target="_blank"
                      rel="noreferrer"
                    >
                      News Section.
                    </a>{' '}
                    Here are the main updates:
                  </p>
                  <ul>
                    <li className="bullet-item">
                      <strong>New functions</strong>
                      <li className="bullet-item">
                        disgenet_enrichment, that performs an disease enrichment analysis on a list of genes
                        or variants of up to 4,000 entities.
                      </li>
                      <li className="bullet-item">
                        gene2vda that receives genes to retrieve VDAs using HGNC symbols, ENSEMBL genes,
                        UNIPROT accessions, and ENTREZ identifiers.
                      </li>
                    </li>
                  </ul>
                  <ul>
                    <li className="bullet-item">
                      <strong>New features</strong>
                      <li className="bullet-item">
                        functions{' '}
                        <strong>
                          gene2disease, disease2gene, variant2disease, disease2variant, chemical2gda,
                          chemical2vda,
                        </strong>{' '}
                        now receive a new parameter, order_by to sort results, that can take the following
                        values: score, dsi, dpi, pli, ei, yearInitial, yearFinal, numCTsupportingAssociation
                      </li>
                      <li className="bullet-item">
                        functions{' '}
                        <strong>
                          gene2evidence, disease2evidence, variant2evidence, gene2chemical, disease2chemical,
                          variant2chemical,
                        </strong>{' '}
                        now receive a new parameter, order_by to sort results, that can take the following
                        values: score, dsi, dpi, pli, pmYear
                      </li>
                      <li className="bullet-item">
                        functions{' '}
                        <strong>disease2gene, disease2variant, disease2evidence, and disease2disease</strong>{' '}
                        accept diseases coded using MONDO identifiers.
                      </li>
                      <li className="bullet-item">
                        functions <strong>gene2disease , gene2chemical and gene2attribute</strong> accept
                        UNIPROT accessions.
                      </li>
                      <li className="bullet-item">
                        functions <strong>gene2chemical and disease2chemical</strong> are faster
                      </li>
                      <li className="bullet-item">
                        New visualization available for the results of the <strong>disease_enrichment</strong>{' '}
                        function
                      </li>
                    </li>
                  </ul>
                  <h6>Cytoscape App:</h6>
                  <ul>
                    <li className="bullet-item">New release of the DISGENET Cytoscape App (v8.1.2).</li>
                    <li className="bullet-item">
                      The DISGENET enrichment function is now available in the app, allowing enrichment
                      analyses on both external gene and variant networks as well as DISGENET data.
                    </li>
                    <li className="bullet-item">Bug in search box fixed</li>
                    <li className="bullet-item">
                      For more information, see the{' '}
                      <a href="https://apps.cytoscape.org/apps/disgenetapp" target="_blank" rel="noreferrer">
                        release notes.
                      </a>
                    </li>
                  </ul>
                  <h6>DISGENET AI assistant:</h6>
                  <ul>
                    <p>
                      It combines the power of generative AI with the capabilities of the DISGENET REST API.
                      You can ask questions in natural language and the assistant will translate them to
                      structured queries to the database exploiting all the features available in DISGENET,
                      and summarize the main results for you. The DISGENET AI assistant lowers the barrier to
                      access DISGENET for users with no programming skills and no knowledge about DISGENET
                      data schema. Try it{' '}
                      <a href="/Assistant" target="_blank" rel="noreferrer">
                        here.
                      </a>
                    </p>
                  </ul>
                </div>

                <div
                  className={`tab-pane ${hash === '#version_24.3' ? 'active show' : ''}`}
                  id="version_24.3"
                >
                  <h2 className="version-number">
                    September 26th, 2024: Release of version 24.3 of DISGENET
                  </h2>
                  <p>
                    The current version of DISGENET (v24.3) contains 1,943,710 gene-disease associations
                    (GDAs), between 26,484 genes and 39,922 diseases and traits; 1,237,183 variant-disease
                    associations (VDAs), between 723,970 variants and 16,938 diseases and traits, and over
                    27,494,442 M disease-disease associations. DISGENET also contains more than 340,000
                    associations involving 12,000 diseases detected in animal models. This release provides
                    annotations for 4,042 chemicals associated with GDAs and VDAs. 1,550,658 publications
                    support the GDAs, and 183,559 publications support the VDAs. In total, DISGENET contains a
                    corpus of 1,560,237 publications that support the genotype-phenotype associations.
                  </p>
                  <h6>DATA:</h6>
                  <ul>
                    <li className="bullet-item">
                      All data sources updated: we increased the number of GDAs and VDAs by approx. 10%
                    </li>
                  </ul>
                  <h6>WEB</h6>
                  <ul>
                    <li className="bullet-item">
                      New feature: data on tables can be filtered by the source Clinical Trials
                    </li>
                  </ul>
                  <h6>REST API</h6>
                  <ul>
                    <li className="bullet-item">
                      New feature: the API calls can be filtered by the new data source Clinical Trials
                    </li>
                    <li className="bullet-item">
                      New options for filtering and searching data on different endpoints
                    </li>
                    <li className="bullet-item">Updated information in the documentation about API usage</li>
                  </ul>
                  <h6>R PACKAGE :</h6>
                  <ul>
                    <li className="bullet-item">New release of the disgenet2r R package (1.1.4).</li>
                    <li className="bullet-item">
                      New feature: functions that accept database source can filter by the new data source
                      Clinical Trials
                    </li>
                  </ul>
                  <h6>Cytoscape App:</h6>
                  <ul>
                    <li className="bullet-item">
                      New release of the DISGENET Cytoscape App (v8.1.2).
                      <li className="bullet-item">Enhanced Query Capabilities</li>
                      <li className="bullet-item">Improved Gene Search</li>
                      <li className="bullet-item">Expanded Entity Suggestions</li>
                      <li className="bullet-item">Better Error Handling</li>
                    </li>
                    <li className="bullet-item">
                      For more information, see the{' '}
                      <a href="https://apps.cytoscape.org/apps/disgenetapp" target="_blank" rel="noreferrer">
                        release notes.
                      </a>{' '}
                    </li>
                  </ul>
                  <h6>New tool: the DISGENET AI assistant:</h6>
                  <p>
                    It combines the power of generative AI with the capabilities of the DISGENET REST API. You
                    can ask questions in natural language and the assistant will translate them to structured
                    queries to the database exploiting all the features available in DISGENET, and summarize
                    the main results for you. The DISGENET AI assistant lowers the barrier to access DISGENET
                    for users with no programming skills and no knowledge about DISGENET data schema. Try it{' '}
                    <a href="/Assistant" target="_blank">
                      here.
                    </a>
                  </p>
                </div>

                <div
                  className={`tab-pane ${hash === '#version_24.2' ? 'active show' : ''}`}
                  id="version_24.2"
                >
                  <h2 className="version-number"> July 10th, 2024: Release of version 24.2 of DISGENET</h2>
                  <p>
                    {' '}
                    The current version of DISGENET (v24.2) contains 1,934,730 gene-disease associations
                    (GDAs), between 26,392 genes and 39,903 diseases and traits; 1,254,874 variant-disease
                    associations (VDAs), between 719,161 variants and 17,029 diseases and traits, and over
                    27,494,442 M disease-disease associations. DISGENET also contains more than 340,000
                    associations involving 12,000 diseases detected in animal models. This release provides
                    annotations for 4,032 chemicals associated with GDAs and VDAs. 1,529,620 publications
                    support the GDAs, and 180,533 publications support the VDAs. In total, DISGENET contains a
                    corpus of 1,539,056 publications that support the genotype-phenotype associations.
                  </p>
                  <h6>DATA:</h6>
                  <ul>
                    <li className="bullet-item">All data sources for associations were updated.</li>
                    <li className="bullet-item">Improvement in the NER for genes and diseases.</li>
                    <li className="bullet-item">
                      New data source: we obtain biomarkers associated with diseases from clinical trials
                      available in{' '}
                      <a href="https://www.clinicaltrials.gov" target="_blank" rel="noreferrer">
                        ClinicalTrials.gov
                      </a>
                    </li>
                    <li className="bullet-item">
                      “Chemical Effect”: classification of the effect of a chemical on the disease, provided
                      processing expert-curated resources that provide adverse effect information and
                      indications. By using the “Chemical Effect” attribute, you can select chemicals that are
                      used for the treatment of a disease (therapeutic) or lead to a disease (toxicity). This
                      attribute is available in the GDA and VDA Evidence tables with chemical annotations.
                    </li>
                    <li className="bullet-item">
                      Disease-Disease associations: we applied a more stringent cut-off p-value to identify
                      DDAs (before was 10<sup>-3</sup> and now is 10<sup>-6</sup>)
                    </li>
                  </ul>
                  <h6>R PACKAGE :</h6>
                  <ul>
                    <li className="bullet-item">New release of the disgenet2r R package (1.1.2).</li>
                    <li className="bullet-item">
                      New data attribute “Chemical Effect” is available in the following functions:
                      gene2chemical, disease2chemical, variant2chemical , and chemical2evidence. These
                      functions return the field chemical_effect, with possible values toxicity, therapeutic
                      and other.
                    </li>
                  </ul>
                  <h6>Cytoscape App:</h6>
                  <ul>
                    <li className="bullet-item">New release of the DISGENET Cytoscape App (v8.0.0).</li>
                  </ul>
                </div>

                <div
                  className={`tab-pane ${hash === '#version_24.1' ? 'active show' : ''}`}
                  id="version_24.1"
                >
                  <h2 className="version-number"> April 3rd, 2024: Release of version 24.1 of DISGENET</h2>
                  <p>
                    {' '}
                    The current version of DISGENET (v24.1) contains 1,793,304 gene-disease associations
                    (GDAs), between 26,090 genes and 39,797 diseases and traits; 1,201,698 variant-disease
                    associations (VDAs), between 704,086 variants and 16,774 diseases and traits, and over 45
                    M disease-disease associations. DISGENET also contains more than 290,000 associations
                    involving 12,000 diseases that have been detected in animal models. This release provides
                    annotations for 3,981 chemicals associated with GDAs and VDAs. 1,470,167 publications
                    support the GDAs, and 173,659 publications support the VDAs. In total, DISGENET contains a
                    corpus of 1,479,286 publications that support the genotype-phenotype associations.
                  </p>
                  <h6>DATA:</h6>
                  <ul>
                    <li className="bullet-item">
                      We change the notation of the release versions. From now on, the first 2 digits will
                      represent the year, and the following digits indicate if it is the first, second, third,
                      or fourth release of the year. Therefore, the current release is v24.1 (the first
                      release of the calendar year 2024).
                    </li>
                    <li className="bullet-item">All data sources for associations were updated.</li>
                    <li className="bullet-item">gnomAD data updated to version 4.0.0.</li>
                    <li className="bullet-item">Disease, Gene, and Chemical dictionaries were updated.</li>
                    <li className="bullet-item">
                      We performed curation of Disease and Gene dictionaries to remove the ambiguity of
                      concepts. In the case of the Disease dictionary, we also performed a curation that
                      resulted in the removal of approx. 1K concepts that were not proper diseases or
                      phenotypes (e.g. “Folate-dependent fragile site at Xq28”), were very general (e.g.
                      “Communicable diseases”), or were diseases caused by external factors such as a
                      consequence of surgical procedures or burns (e.g. “Burn injury”).
                    </li>
                    <li className="bullet-item">
                      “Chemical Effect”: classification of the effect of a chemical on the disease, provided
                      processing expert-curated resources that provide adverse effect information and
                      indications. By using the “Chemical Effect” attribute, you can select chemicals that are
                      used for the treatment of a disease (therapeutic) or lead to a disease (toxicity). This
                      attribute is available in the GDA and VDA Evidence tables with chemical annotations.
                    </li>
                    <li className="bullet-item">
                      Incorporated md5 file for checking the integrity of the database zipped files for users
                      that download the database dump.
                    </li>
                  </ul>
                  <h6>REST API:</h6>
                  <ul>
                    <li className="bullet-item">
                      New data attribute “Chemical Effect” is available in the following endpoints: GDA
                      evidence, VDA evidence.
                    </li>
                  </ul>
                  <h6>R PACKAGE :</h6>
                  <ul>
                    <li className="bullet-item">New release of the disgenet2r R package (1.0.6).</li>
                    <li className="bullet-item">
                      New data attribute “Chemical Effect” is available in the following functions:
                      gene2chemical, disease2chemical, variant2chemical , and chemical2evidence.
                    </li>
                  </ul>
                </div>

                <div className={`tab-pane ${hash === '#version_24' ? 'active show' : ''}`} id="version_24">
                  <h2 className="version-number"> December 27th, 2023: Release of version 24 of DISGENET</h2>
                  <p>
                    The current version of DISGENET (v24) contains 1,770,610 gene-disease associations (GDAs),
                    between 25,992 genes and 39,644 diseases and traits; 1,119,130 variant-disease
                    associations (VDAs), between 616,660 variants and 16,605 diseases and traits, and over 45
                    M disease-disease associations.DISGENET also contains more than 310,000 associations
                    involving 13,000 diseases that have been detected in animal models. This release provides
                    annotations for 3,015 chemicals associated with GDAs and VDAs. 1,425,789 publications
                    support the GDAs, and 158,269 publications support the VDAs. In total,DISGENET contains a
                    corpus of 1,434,210 publications that support the genotype-phenotype associations.
                  </p>
                  <h5>Release info:</h5>
                  <ul>
                    <li className="bullet-item">New data source: Rat Genome Database</li>
                    <li className="bullet-item">
                      17% increase in the number of GDAs compared to v21: more than 260K new GDAs available
                    </li>
                    <li className="bullet-item">
                      Increased coverage of genes and diseases/phenotypes: more than 1.3K new genes and 3.2K
                      diseases and phenotypes incorporated in the database
                    </li>
                    <li className="bullet-item">
                      New attributes for variants: we provide the SIFT and PolyPhen scores for pathogenicity
                      for each variant
                    </li>
                    <li className="bullet-item">
                      New features of disgenet2r R package (1.0.3):
                      <li className="bullet-item">
                        New features:
                        <li className="bullet-item">
                          Queries with functions disease2variant, chemical2variant, and chemical2vda now can
                          receive SIFT and POLYPHEN ranges
                        </li>
                      </li>
                      <li className="bullet-item">
                        Documentation:
                        <li className="bullet-item">NEWS.md file was added to document package changes</li>
                        <li className="bullet-item">
                          All functions that use the parameter database now have all data sources, including
                          RGD and MGD, that are new sources
                        </li>
                      </li>
                      <li className="bullet-item">
                        Bug fixes:
                        <li className="bullet-item">
                          Functions that use the parameter database that query variant endpoints now throw an
                          error if a database is not GWASCAT, CLINVAR, UNIPROT, TEXTMINING_HUMAN, CURATED, or
                          ALL
                        </li>
                      </li>
                    </li>
                  </ul>
                </div>

                <div
                  className={`tab-pane ${hash === '#version_23.1' ? 'active show' : ''}`}
                  id="version_23.1"
                >
                  <h2 className="version-number">October 26th, 2023: Release of version 23.1 of DISGENET</h2>
                  <p>
                    This release corresponds to the same data as in <a href="#version_23">release v23</a>, but
                    we have incorporated the following fields in the database dump files:
                  </p>
                  <h6>GDA SUMMARY TABLE:</h6>
                  <ul>
                    <li className="bullet-item">
                      N_DISEASES_GENE_ALL: Number of diseases associated to the gene in ALL data.
                    </li>
                    <li className="bullet-item">
                      N_VARIANTS_GENE_ALL: Number of variants associated to the gene in ALL data.
                    </li>
                    <li className="bullet-item">
                      N_GENES_DISEASE_ALL: Number of genes associated to the disease in ALL data.
                    </li>
                    <li className="bullet-item">
                      N_VARIANTS_DISEASE_ALL: Number of variants associated to the disease in ALL data.
                    </li>
                    <li className="bullet-item">
                      N_PMIDS_GDA_ALL: Number of publications supporting the gene-disease association in ALL
                      data.
                    </li>
                    <li className="bullet-item">
                      N_VARIANTS_GDA_ALL: Number of variants for the gene-disease association in ALL data.
                    </li>
                  </ul>

                  <h6>VDA SUMMARY TABLE:</h6>
                  <ul>
                    <li className="bullet-item">
                      N_DISEASES_VARIANT_ALL: Number of diseases associated to the variant in ALL data
                      (Integer).
                    </li>
                    <li className="bullet-item">
                      N_GENES_DISEASE_ALL: Number of genes associated to the disease in ALL data (Integer).
                    </li>
                    <li className="bullet-item">
                      N_VARIANTS_DISEASE_ALL: Number of variants associated to the disease in ALL data
                      (Integer).
                    </li>
                    <li className="bullet-item">
                      N_PMIDS_VDA_ALL: Number of publications supporting the variant-disease association in
                      ALL data (Integer).
                    </li>
                  </ul>
                  <h6>DDA TABLE:</h6>
                  <ul>
                    <li className="bullet-item">
                      ASSOC_RELATION: The Unified Medical Language System®(UMLS) Metathesaurus Semantic
                      Relationship between the concepts (String).
                    </li>
                  </ul>
                  <h6>GDA EVIDENCE TABLE:</h6>
                  <ul>
                    <li className="bullet-item">
                      ASSOC_PM_JOURNAL: Journal for the publication supporting the gene-disease association
                      (String).
                    </li>
                    <li className="bullet-item">
                      ASSOC_PM_JOURNAL_ABB: Journal Abbreviation for the publication supporting the
                      gene-disease association (String).
                    </li>
                  </ul>
                  <h6>VDA EVIDENCE TABLE:</h6>
                  <ul>
                    <li className="bullet-item">
                      ASSOC_PM_JOURNAL: Journal for the publication supporting the variant-disease association
                      (String).
                    </li>
                    <li className="bullet-item">
                      ASSOC_PM_JOURNAL_ABB: Journal Abbreviation for the publication supporting the
                      variant-disease association (String).
                    </li>
                  </ul>
                  <h6>GENE TABLE:</h6>
                  <ul>
                    <li className="bullet-item">GENE_FULL_NAME: Gene Full Name (String).</li>
                    <li className="bullet-item">
                      GENE_NCBI_TYPE: Gene NCBI type (i.e. protein-coding, ncRNA, tRNA, ... String).
                    </li>
                    <li className="bullet-item">
                      PATHWAY_IDS: The identifier of the top level category pathway from Reactome
                      (https://reactome.org/)(List of identifiers separated by the separator string (3 chars)
                      _*_).
                    </li>
                    <li className="bullet-item">
                      PATHWAY_NAMES: The name of the top level category from Reactome (https://reactome.org/).
                      When more than one top level category pathway is associated to the gene, a list of top
                      level category names separated by the separator string (3 chars) _*_ is provided. A list
                      of the same size will be present in the PROTEIN_CLASS_IDS column: the identifier of each
                      top level category name of this list can be retrieved at the same index in the list of
                      top level categoy indentifiers included in the PATHWAY_IDS column (List of identifiers
                      separated by the separator string (3 chars) _*_).
                    </li>
                  </ul>
                  <h6>dbSNP DATA TABLE:</h6>
                  <ul>
                    <li className="bullet-item">
                      AF_EXOME: Allelic Frequency of the variant from the GNOMAD exomes information (list of
                      Doubles, -1 codes for no information).
                    </li>
                    <li className="bullet-item">
                      AF_GENOME: Allelic Frequency of the variant from the GNOMAD genomes information (list of
                      Doubles, -1 codes for no information).
                    </li>
                  </ul>
                </div>
                <div className={`tab-pane ${hash === '#version_23' ? 'active show' : ''}`} id="version_23">
                  <h2 className="version-number"> September 27th, 2023: Release of version 23 of DISGENET</h2>
                  <p>
                    The current version of DISGENET (v23) contains 1,729,483 gene-disease associations (GDAs),
                    between 25,869 genes and 39,141 diseases and traits; 1,117,115 variant-disease
                    associations (VDAs), between 617,724 variants and 16,533 diseases and traits, and over 43M
                    disease-disease associations.DISGENET also contains more than 1,7 M associations involving
                    14,000 diseases that have been detected in animal models. This release provides
                    annotations for 3,507 chemicals associated with GDAs and VDAs. 1,399,652 publications
                    support the GDAs, and 156,684 publications support the VDAs. In total,DISGENET contains a
                    corpus of 1,408,123 publications that support the genotype-phenotype associations.
                  </p>
                  <h5>Release info:</h5>
                  <ul>
                    <li className="bullet-item">New data source: Mouse Genome Database</li>
                    <li className="bullet-item">
                      10% increase in the number of GDAs: more than 175K new GDAs available
                    </li>
                    <li className="bullet-item">
                      Increased coverage of genes and diseases/phenotypes: almost 2.5K new diseases and
                      phenotypes incorporated into the database
                    </li>
                    <li className="bullet-item">Changes in the Association type ontology</li>
                    <li className="bullet-item">
                      {' '}
                      New features in the disgenet2r package:
                      <li className="bullet-item">
                        Changes in the plot function to enable visualization by DiseaseClass and ProteinClass
                        both in networks and heatmaps.
                      </li>
                      <li className="bullet-item">Heatmaps available for download as png and json files</li>
                      <li className="bullet-item">
                        Customization of network plots using the visNetwork package.
                      </li>
                      <li className="bullet-item">Retrieval of DDAs by relationship types.</li>
                    </li>
                  </ul>
                </div>

                <div className={`tab-pane ${hash === '#version_22' ? 'active show' : ''}`} id="version_22">
                  <h2 className="version-number"> July 5th, 2023: Release of version 22 of DISGENET</h2>
                  <p>
                    The current version of DISGENET (v22) contains 1,552,626 gene-disease associations (GDAs),
                    between 25,474 genes and 36,675 diseases and traits; 1,115,471 variant-disease
                    associations (VDAs), between 610,272 variants and 16,168 diseases and traits, and over
                    32,7 M disease-disease associations.DISGENET also contains more than 250,000 associations
                    involving 12,000 diseases that have been detected in animal models. This release provides
                    annotations for 3,173 chemicals associated with GDAs and VDAs. 1,107,571 publications
                    support the GDAs, and 155,495 publications support the VDAs. In total,DISGENET contains a
                    corpus of 1,160,823 publications that support the genotype-phenotype associations.
                  </p>
                  <ul>
                    <li className="bullet-item">
                      More than 40K GDAs and VDAs are available, increasing the coverage of genes, variants
                      and diseases.
                    </li>
                    <li className="bullet-item">
                      New feature for DDAs: semantic relations from the UMLS between diseases
                    </li>
                    <li className="bullet-item">
                      New features on the REST API:
                      <li className="bullet-item">
                        New endpoint to search for chemicals, to retrieve information on number of GDAs, VDAs
                        and supporting publications.
                      </li>
                      <li className="bullet-item">
                        Search by chemical available on the endpoints GDA summary & evidence, VDA summary &
                        evidence.
                      </li>
                    </li>
                    <li className="bullet-item">
                      New features on the disgenet2r package:
                      <li className="bullet-item">
                        New functions to search for chemicals and their associated GDAs and VDAs New functions
                        to search for chemicals and their associated genes, variants and diseases.
                      </li>
                      <li className="bullet-item">
                        Different visualizations available for the new functions.
                      </li>
                    </li>
                  </ul>
                </div>

                <div className={`tab-pane ${hash === '#version_21' ? 'active show' : ''}`} id="version_21">
                  <h2 className="version-number">March 30th, 2023: Release of version 21 of DISGENET</h2>
                  <p>
                    The current version of DISGENET (v21) contains 1,509,314 gene-disease associations (GDAs),
                    between 24,613 genes and 0 diseases and traits; 1,070,721 variant-disease associations
                    (VDAs), between 584,693 variants and 16,010 diseases and traits, and over 32 M
                    disease-disease associations.DISGENET also contains more than 240,000 associations
                    involving 12,000 diseases detected in animal models. This release provides annotations for
                    3,174 chemicals associated with GDAs and VDAs. The GDAs are supported by 1,105,030
                    publications, and the VDAs by 141,088 publications.
                  </p>
                  <ul>
                    <li className="bullet-item">
                      All data sources updated, including the disease and gene ontologies.
                    </li>
                    <li className="bullet-item">
                      New data source: PheWas Catalog
                      <span>
                        <a target="_blank" rel="noreferrer" href="https://phewascatalog.org/">
                          {' '}
                          (https://phewascatalog.org/)
                        </a>
                        .
                      </span>
                    </li>
                    <li className="bullet-item">
                      Information on chemicals associated with GDAs and VDAs: we identify mentions of
                      chemicals by text mining within the publications that support the GDAs and VDAs.
                    </li>
                    <li className="bullet-item">
                      New features on the REST API:
                      <li className="bullet-item">
                        Search by GoF/LoF variations and consequence type in the “VDA evidence” endpoint.
                      </li>
                      <li className="bullet-item">
                        Search by consequence type in the “VDA summary” endpoint.
                      </li>
                    </li>
                  </ul>
                </div>
                <div
                  className={`tab-pane ${hash === '#version_20.1' ? 'active show' : ''}`}
                  id="version_20.1"
                >
                  <h2 className="version-number">January 23th, 2023: Release of version 20.1 of DISGENET</h2>
                  <p>
                    The current version of DISGENET (v20.1) contains 1,516,952 gene-disease associations
                    (GDAs), between 24,642 genes and 34,124 diseases and traits; 1,009,638 variant-disease
                    associations (VDAs), between 578,771 variants and 15,185 diseases and traits, and over
                    30,421,348 M disease-disease associations.DISGENET also contains more than 250,000
                    associations involving 11,000 diseases detected in animal models. This release provides
                    annotations for 3,937 chemicals associated with GDAs and VDAs.
                  </p>
                  <ul>
                    <li className="bullet-item">
                      New information on chemicals associated with GDAs and VDAs: we identify mentions of
                      chemicals by text mining within the publications that support the GDAs and VDAs.
                    </li>
                    <li className="bullet-item">
                      New attribute for genes: annotations to pathways from Reactome.
                    </li>
                    <li className="bullet-item">
                      New attribute for VDAs: annotation of the effect of the variant on the phenotype, such
                      as gain of function (GoF) or loss of function (LoF) mutations. This information is
                      obtained by text mining.
                    </li>
                  </ul>
                </div>

                <div className={`tab-pane ${hash === '#version_20' ? 'active show' : ''}`} id="version_20">
                  <h2 className="version-number">November 3rd, 2022: Release of version 20.0 of DISGENET</h2>
                  <p>
                    The current version of DISGENET (v20) contains 1,517,357 gene-disease associations (GDAs),
                    between 24,643 genes and 34,158 diseases, disorders, traits, and clinical or abnormal
                    human phenotypes, 1,010,102 variant-disease associations (VDAs), between 578,958 variants
                    and 15,209 diseases, traits, and phenotypes and over 47 M disease-disease
                    associations.DISGENET also contains more than 250,000 associations involving 11,000
                    diseases that have been detected in animal models.
                  </p>
                  <ul>
                    <li className="bullet-item">All data sources updated</li>
                    <li className="bullet-item">
                      Increased coverage for variants: 13,900 new variants, 40,000 new VDAs compared to the
                      previous release
                    </li>
                    <li className="bullet-item">30,000 new GDAs</li>
                    <li className="bullet-item">
                      New: reported odds ratio or beta-coefficient associated with the GWAS study are now
                      available
                    </li>
                    <li className="bullet-item">
                      New: disease-disease associations can be filtered by p values of the Jaccard index of
                      genes and variants
                    </li>
                    <li className="bullet-item">
                      New feature: visualization of the data is available (bar plots, heatmaps, and networks)
                    </li>
                  </ul>
                </div>

                <div className={`tab-pane ${hash === '#version_19' ? 'active show' : ''}`} id="version_19">
                  <h2 className="version-number">June 27th, 2022: Release of version 19.0 of DISGENET</h2>
                  <p>
                    The current version of DISGENET (v19) contains 1,483,670 gene-disease associations (GDAs),
                    between 24,249 genes and 34,041 diseases, disorders, traits, and clinical or abnormal
                    human phenotypes and 968,761 variant-disease associations (VDAs), between 564,982 variants
                    and 15,059 diseases, traits, and phenotypes and over 46 M disease-disease
                    associations.DISGENET also contains more than 260,000 associations involving 11,000
                    diseases that have been detected in animal models.
                  </p>
                  <ul>
                    <li className="bullet-item">All data sources updated</li>
                    <li className="bullet-item">
                      Increased coverage for variants: 12,000 new variants, 35,000 new VDAs compared to the
                      previous release
                    </li>
                    <li className="bullet-item">100,000 new GDAs</li>
                    <li className="bullet-item">
                      New: searches by aminoacid-position are now available for variants
                    </li>
                    <li className="bullet-item">New: now users can filter genes using the gene type!</li>
                    <li className="bullet-item">
                      New: links to clinical trials are now available for genes and diseases
                    </li>
                  </ul>
                </div>
                <div className={`tab-pane ${hash === '#version_18' ? 'active show' : ''}`} id="version_18">
                  <h2 className="version-number">March 15th, 2022: Release of version 18.0 of DISGENET</h2>
                  <p>
                    The version v18 of DISGENET contains 1,371,161 gene-disease associations (GDAs), between
                    23,111 genes and 32,630 diseases, disorders, traits, and clinical or abnormal human
                    phenotypes and 933,409 variant-disease associations (VDAs), between 552,680 variants and
                    14,626 diseases, traits, and phenotypes, and over 41 M disease-disease associations. This
                    new release also contains more than 200,000 associations involving 10,000 diseases that
                    have been detected in animal models.
                  </p>

                  <p>
                    Our database now summarizes the disease genomics knowledge from over 1M scientific
                    publications by mining 30M publications.
                  </p>

                  <ul>
                    <li className="bullet-item">All data sources updated</li>
                    <li className="bullet-item">
                      Increased coverage for variants: 28% more variants, 23% more VDAs compared to the
                      previous release
                    </li>
                    <li className="bullet-item">13% more pieces of evidence for GDAs</li>
                  </ul>
                </div>
                <div className={`tab-pane ${hash === '#version_17' ? 'active show' : ''}`} id="version_17">
                  <h2 className="version-number">December 13th, 2021: Release of version 17.4 of DISGENET</h2>
                  <ul>
                    <li className="bullet-item">More variants with allelic frequency information</li>
                    <li className="bullet-item">
                      New: information on loss-of-function and gain-of-function variants associated with
                      diseases{' '}
                    </li>
                  </ul>
                  <h2 className="version-number">November 26th, 2021: Release of version 17.3 of DISGENET</h2>
                  <ul>
                    <li className="bullet-item">
                      New: now you can search using Ensembl gene identifiers in the web interface, the
                      disgenet2r package, and the REST API
                    </li>
                  </ul>

                  <h2 className="version-number">November 22th, 2021: Release of the web interface</h2>
                  <ul>
                    <li className="bullet-item">
                      New: you can now explore DISGENET using our new web interface, with search and filtering
                      features{' '}
                    </li>
                  </ul>
                  <h2 className="version-number">November 11th, 2021: Release of version 17 of DISGENET</h2>
                  <p>
                    The version 17 of DISGENET contains 1,256,954 gene-disease associations between 22,703
                    genes and 31,781 diseases, disorders, traits, and clinical or abnormal human phenotypes,
                    648,990 variant-disease associations between 375,441 variants and 14,276 diseases, traits,
                    and phenotypes and 36,040,896 disease-disease associations. DISGENET contains information
                    that comprises 855,346 scientific publications.
                  </p>

                  <ul>
                    <li className="bullet-item">All data sources updated</li>
                    <li className="bullet-item">
                      New association types: chemical response, protective variant, and variant of uncertain
                      significance
                    </li>
                  </ul>
                </div>

                <div className={`tab-pane ${hash === '#version_16' ? 'active show' : ''}`} id="version_16">
                  <h2 className="version-number">September 16th, 2021: New release of the REST API!</h2>
                  <ul>
                    <li className="bullet-item">
                      New endpoints for the retrieval of attributes for genes, diseases, variants and
                      publications
                    </li>
                    <li className="bullet-item">
                      New endpoints to retrieve the summary and the evidences for the associations (GDAs and
                      VDAs)
                    </li>
                    <li className="bullet-item">
                      Free text search support in the gene and disease entity endpoints, useful to recover
                      database identifiers from free text expressions (e.g., to obtain the UMLS identifiers
                      for the string “schizophrenia”)
                    </li>
                    <li className="bullet-item">
                      Protein class information added in the response of the GDA endpoints (summary and
                      evidence)
                    </li>
                  </ul>

                  <h2 className="version-number">August 3rd, 2021: Release of the disgenet2r package </h2>
                  <p>
                    The package contains a set of functions to retrieve, visualize and expandDISGENET data. It
                    allows to:
                  </p>
                  <ul>
                    <li className="bullet-item">
                      Search by gene or multiple genes, using different identifiers
                    </li>
                    <li className="bullet-item">
                      Search one disease, or multiple diseases, using different identifiers
                    </li>
                    <li className="bullet-item">Perform different operation with disease vocabularies</li>
                    <li className="bullet-item">
                      Visualize the results of your queries using interactive networks, heatmaps, and other
                      types of plots
                    </li>
                  </ul>

                  <h2 className="version-number">July 20th, 2021: Release of version 16 of DISGENET</h2>
                  <p>
                    The version 16.0 of DISGENET contains 1,203,188 gene-disease associations, between 22148
                    genes and 31107 diseases, disorders, traits, and clinical or abnormal human phenotypes,
                    and 408,152 variant-disease associations, between 203,964 variants and 13,877 diseases,
                    traits, and phenotypes, and over 34,000,000 disease-disease associations.DISGENET contains
                    information that comprises 842,987 scientific publications.
                  </p>
                </div>

                <div className={`tab-pane ${hash === '#version_15' ? 'active show' : ''}`} id="version_15">
                  <h2 className="version-number">June 23th, 2021: Release of version 15 of DISGENET</h2>
                  <p>
                    The version 15.0 of DISGENET contains 1,191,107 gene-disease associations, between 22,079
                    genes and 30,648 diseases, disorders, traits, and clinical or abnormal human phenotypes,
                    370,769 variant-disease associations, between 181,493 variants and 13,449 diseases,
                    traits, and phenotypes, and over 34,000,000 disease-disease associations. DISGENET
                    contains information that comprises 846,941 scientific publications.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </MathJaxContext>
  );
}
export default AboutContent;
