import React from 'react';
import { TableFromFile } from '../Tsv_parser';

function AboutGeneTypes() {
  return (
    <div>
      {' '}
      <h2 className="mt-5">
        DISGENET provides disease information for protein coding genes as well as non-coding genes
      </h2>
      <div className="table-responsive">
        <table className="table panther-table" style={{ float: 'middle', margin: '10px 10px 0px 30px' }}>
          <thead className="text-center">
            <tr>
              <th>Type of Gene</th>
              <th>Number of genes</th>
              <th>Number of GDAS</th>
            </tr>
          </thead>
          <tbody className="text-center">
            <TableFromFile filePath="../../static/files/gene_type_stats.tsv" />
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default AboutGeneTypes;
