import React from 'react';
import {
  PostContentTrialSuccess,
  PostContentLicenseGuide,
  PostContentNLP,
  PostContentGenomicsResearch,
  PostContentVersion243,
  PostContentVersion244,
} from './Posts/Pre-2025.jsx';
import { Version251 } from './Posts/Version251.jsx';

const posts = [
  {
    title: 'DISGENET Version 25.1: What’s New?',
    postName: 'DISGENET-Version-25-1-Whats-New',
    date: 'April 03, 2025',
    image: '../../static/images/blog/8_disgenet-version-25.1-data-increase.jpg',
    description:
      'DISGENET, the largest and most extensive gene-disease association network, has released version 25.1, which means new functions and features designed to support your genomics research.  [...]',
    content: '',
  },
  {
    title: 'DISGENET Version 24.4: What’s New?',
    postName: 'DISGENET-Version-24-4-Whats-New',
    date: 'December 05, 2024',
    image: '../../static/images/blog/Advancedsearch-POST.jpg',
    description:
      'DISGENET, the largest and most extensive gene-disease association network, has released version 24.4, which means new functions and features designed to support your genomics research.  [...]',
    content: '',
  },
  {
    title: 'DISGENET Version 24.3: What’s New?',
    postName: 'DISGENET-Version-24-3-Whats-New',
    date: 'October 08, 2024',
    image: '../../static/images/blog/DISGENET-Assistant-post5.jpg',
    description:
      'DISGENET, the largest and most extensive gene-disease association network, has released version 24.3, featuring several new additions. DISGENET version 24.3 includes: The DISGENET AI Assistant (Beta), New Clinical Trial Data Source, Expanded Data Coverage, With these enhancements, DISGENET continues to provide you [...]',
    content: '',
  },
  {
    title: 'Genetics In Drug Discovery: The Key To Clinical Trial Success?',
    postName: 'The-Key-To-Clinical-Trial-Success',
    date: 'August 28, 2024',
    image: '../../static/images/blog/august28-post4.jpg',
    description:
      'By leveraging human genetics to identify drug targets with a proven link to disease mechanisms, the probability of clinical trial success greatly increases [1]. Only 10% of drugs that enter phase 1 clinical trials eventually make it to market [2], primarily due to issues related to efficacy or safety that arise later on [...]',
    content: '',
  },
  {
    title: 'DISGENET Academic License: Eligibility, Application & Free Access',
    postName: 'disgenet-academic-license-guide',
    date: 'August 2, 2024',
    image: '../../static/images/blog/image-post1-1024x613.png',
    description:
      'DISGENET is the most extensive and reliable gene-disease association network, providing the latest and most relevant data in the field. It was created by MedBioinformatics, and as an academic or not-for-profit researcher, you can access DISGENET’s core biomedical data at no cost. For deeper insights,  [...]',
    content: '',
  },
  {
    title: 'How DISGENET Uses NLP To Accelerate Drug Development',
    postName: 'disgenet-nlp-to-accelerate-drug-development',
    tags: ['Bioinformatics'],
    date: 'July 19, 2024',
    image: '../../static/images/blog/July-2024-list-.jpg',
    description:
      'With DISGENET, you can easily unlock new insights and greatly accelerate your drug development journey. Every year, more than 1 million papers enter PubMed in the biomedical field [1]. While medical knowledge doubles every 73 days and is increasing at an exponential rate with no evidence of slowing [2], drug  [...]',
    content: '',
  },
  {
    title: 'DISGENET: The Next Chapter For Disease Genomics Research',
    postName: 'The-Next-Chapter-For-Disease-Genomics-Research',
    date: 'June 19, 2024',
    image: '../../static/images/blog/June-19-2024-post3.png',
    description:
      'From today, DisGeNET.org and DISGENET plus will become one unified platform found at disgenet.com. DisGeNET has become a cornerstone for bioinformatics research since starting in 2010. As a small team, we have dedicated 15 years to this valuable resource and we are committed to continuously improving   [...]',
    content: '',
  },
];

const generateContent = post => {
  switch (post.postName) {
    case 'The-Key-To-Clinical-Trial-Success':
      return <PostContentTrialSuccess />;
    case 'disgenet-academic-license-guide':
      return <PostContentLicenseGuide />;
    case 'disgenet-nlp-to-accelerate-drug-development':
      return <PostContentNLP />;
    case 'The-Next-Chapter-For-Disease-Genomics-Research':
      return <PostContentGenomicsResearch />;
    case 'DISGENET-Version-24-3-Whats-New':
      return <PostContentVersion243 />;
    case 'DISGENET-Version-24-4-Whats-New':
      return <PostContentVersion244 />;
    case 'DISGENET-Version-25-1-Whats-New':
      return <Version251 />;
    default:
      return null;
  }
};

posts.forEach(post => {
  post.content = generateContent(post);
});

export { posts, generateContent };
