import React from 'react';

function AboutCiteUs() {
  return (
    <div>
      {' '}
      <h2>How to cite us</h2>
      <p>
        If you use DISGENET in your work, please cite our URL{' '}
        <a href="https://www.disgenet.com/" target="_blank" rel="noreferrer">
          {'https://www.disgenet.com'}
        </a>{' '}
        and our latest publication:
      </p>
      <p>
        Piñero, J., Ramírez-Anguita, J. M., Saüch-Pitarch, J., Ronzano, F., Centeno, E., Sanz, F., & Furlong,
        L. I. (2020). The DisGeNET knowledge platform for disease genomics: 2019 update. Nucleic acids
        research, 48(D1), D845-D855.{' '}
        <a href="https://doi.org/10.1093/nar/gkz1021" target="_blank" rel="noreferrer">
          {'https://doi.org/10.1093/nar/gkz1021'}
        </a>
      </p>
    </div>
  );
}

export default AboutCiteUs;
