import React from 'react';
import { Helmet } from 'react-helmet-async';
import { TopRightMenu } from '../Components/NavMenus/TopNavigation';
import Footer from '../Components/Home/Footer';
import ApplyTrial from '../Components/SignUp/ApplyTrialPage';
import Logo from '../Components/Common/Logo';

function ApplyTrialPage() {
  return (
    <React.Fragment>
      <div className="header-content">
        <Helmet>
          <title>DISGENET: Apply for FREE TRIAL</title>
          <meta
            name="description"
            content="Dive Deeper with Millions of Data Points Using DISGENET Advanced."
          />
          {/* Open Graph Tags for LinkedIn */}
          <meta property="og:title" content="DISGENET: Apply for FREE TRIAL" />
          <meta
            property="og:description"
            content="Unlock millions of data points and enhance your research with DISGENET Advanced. Apply for a free trial today!"
          />
          <meta property="og:url" content="https://www.disgenet.com/free-trial-apply" />
          <meta property="og:type" content="website" />
          <meta property="og:image" content="https://www.disgenet.com/free-trial-apply" />

          <link rel="canonical" href="/free-trial-apply" />
        </Helmet>

        <div>
          <Logo />
        </div>
        <div>
          <TopRightMenu />
        </div>
      </div>
      <div className="d-flex justify-content-end mx-4"></div>
      <ApplyTrial />
      <Footer />
    </React.Fragment>
  );
}

export default ApplyTrialPage;
