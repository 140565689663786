import React from 'react';

function jaccardGda() {
  return (
    <math display="block">
      <mrow>
        <msub>
          <mi mathvariant="italic">Jaccard</mi>
          <mrow>
            <mspace width="0.167em" />
            <mi>G</mi>
          </mrow>
        </msub>
        <mo>=</mo>
        <mfrac>
          <mrow>
            <msub>
              <mi>G</mi>
              <mn>1</mn>
            </msub>
            <mo>∩</mo>
            <msub>
              <mi>G</mi>
              <mn>2</mn>
            </msub>
          </mrow>
          <mrow>
            <mi>G</mi>
            <mn>1</mn>
            <mo>∪</mo>
            <mi>G</mi>
            <mn>2</mn>
          </mrow>
        </mfrac>
      </mrow>
    </math>
  );
}

function jaccardVda() {
  return (
    <math display="block">
      <mrow>
        <msub>
          <mi mathvariant="italic">Jaccard</mi>
          <mrow>
            <mspace width="0.167em" />
            <mi>V</mi>
          </mrow>
        </msub>
        <mo>=</mo>
        <mfrac>
          <mrow>
            <msub>
              <mi>V</mi>
              <mn>1</mn>
            </msub>
            <mo>∩</mo>
            <msub>
              <mi>V</mi>
              <mn>2</mn>
            </msub>
          </mrow>
          <mrow>
            <mi>V</mi>
            <mn>1</mn>
            <mo>∪</mo>
            <mi>V</mi>
            <mn>2</mn>
          </mrow>
        </mfrac>
      </mrow>
    </math>
  );
}

function gdaScore() {
  return (
    <math display="block" xmlns="http://www.w3.org/1998/Math/MathML">
      <mrow>
        <msub>
          <mi>S</mi>
          <mrow>
            <mi mathvariant="italic">GDA</mi>
          </mrow>
        </msub>
        <mo>=</mo>
        <mi>C</mi>
        <mo>+</mo>
        <mi>M</mi>
        <mo>+</mo>
        <mi>I</mi>
        <mo>+</mo>
        <mi>L</mi>
        <mo>+</mo>
        <mi>T</mi>
      </mrow>
    </math>
  );
}

function gdaScore1() {
  return (
    <math display="block" xmlns="http://www.w3.org/1998/Math/MathML">
      <mrow>
        <mi>C</mi>
        <mo>=</mo>
        <mrow>
          <mo stretchy="true" form="prefix">
            &#123;
          </mo>
          <mtable>
            <mtr>
              <mtd>
                <mn>0.7</mn>
              </mtd>
              <mtd>
                <mi mathvariant="italic">if</mi>
                <mspace width="0.222em" />
                <msub>
                  <mi>N</mi>
                  <mrow>
                    <msub>
                      <mi mathvariant="italic">sources</mi>
                      <mi>i</mi>
                    </msub>
                  </mrow>
                </msub>
                <mo>&gt;</mo>
                <mn>4</mn>
                <mspace width="0.222em" />
              </mtd>
            </mtr>

            <mtr>
              <mtd>
                <mn>0.6</mn>
              </mtd>
              <mtd>
                <mi mathvariant="italic">if</mi>
                <mspace width="0.222em" />
                <msub>
                  <mi>N</mi>
                  <mrow>
                    <msub>
                      <mi mathvariant="italic">sources</mi>
                      <mi>i</mi>
                    </msub>
                  </mrow>
                </msub>
                <mo>=</mo>
                <mn>4</mn>
                <mspace width="0.222em" />
              </mtd>
            </mtr>
            <mtr>
              <mtd>
                <mn>0.5</mn>
              </mtd>
              <mtd>
                <mi mathvariant="italic">if</mi>
                <mspace width="0.222em" />
                <msub>
                  <mi>N</mi>
                  <mrow>
                    <msub>
                      <mi mathvariant="italic">sources</mi>
                      <mi>i</mi>
                    </msub>
                  </mrow>
                </msub>
                <mo>=</mo>
                <mn>3</mn>
                <mspace width="0.222em" />
              </mtd>
            </mtr>
            <mtr>
              <mtd>
                <mn>0.45</mn>
              </mtd>
              <mtd>
                <mi mathvariant="italic">if</mi>
                <mspace width="0.222em" />
                <msub>
                  <mi>N</mi>
                  <mrow>
                    <msub>
                      <mi mathvariant="italic">sources</mi>
                      <mi>i</mi>
                    </msub>
                  </mrow>
                </msub>
                <mo>=</mo>
                <mn>2</mn>
                <mspace width="0.222em" />
              </mtd>
            </mtr>
            <mtr>
              <mtd>
                <mn>0.4</mn>
              </mtd>
              <mtd>
                <mi mathvariant="italic">if</mi>
                <mspace width="0.222em" />
                <msub>
                  <mi>N</mi>
                  <mrow>
                    <msub>
                      <mi mathvariant="italic">sources</mi>
                      <mi>i</mi>
                    </msub>
                  </mrow>
                </msub>
                <mo>=</mo>
                <mn>1</mn>
                <mspace width="0.222em" />
              </mtd>
            </mtr>
            <mtr>
              <mtd>
                <mn>0</mn>
              </mtd>
              <mtd>
                <mi mathvariant="italic">otherwise</mi>
              </mtd>
            </mtr>
          </mtable>
        </mrow>
      </mrow>
    </math>
  );
}

function gdaScore2() {
  return (
    <math display="block" xmlns="http://www.w3.org/1998/Math/MathML">
      <mrow>
        <mi>M</mi>
        <mo>=</mo>
        <mrow>
          <mo stretchy="true" form="prefix">
            &#123;
          </mo>
          <mtable>
            <mtr>
              <mtd>
                <mn>0.1</mn>
              </mtd>
              <mtd>
                <mi>i</mi>
                <mi>f</mi>
                <mspace width="0.222em" />
                <msub>
                  <mi>N</mi>
                  <mrow>
                    <mi mathvariant="italic">sourcej</mi>
                  </mrow>
                </msub>
                <mo>&gt;</mo>
                <mn>1</mn>
              </mtd>
            </mtr>
            <mtr>
              <mtd>
                <mn>0.05</mn>
              </mtd>
              <mtd>
                <mi>i</mi>
                <mi>f</mi>
                <mspace width="0.222em" />
                <msub>
                  <mi>N</mi>
                  <mrow>
                    <mi mathvariant="italic">sourcej</mi>
                  </mrow>
                </msub>
                <mo>=</mo>
                <mn>1</mn>
              </mtd>
            </mtr>
            <mtr>
              <mtd>
                <mn>0</mn>
              </mtd>
              <mtd>
                <mi mathvariant="italic">otherwise</mi>
              </mtd>
            </mtr>
          </mtable>
        </mrow>
      </mrow>
    </math>
  );
}

function gdaScore3() {
  return (
    <math display="block" xmlns="http://www.w3.org/1998/Math/MathML">
      <mrow>
        <mi>I</mi>
        <mo>=</mo>
        <mrow>
          <mo stretchy="true" form="prefix">
            &#123;
          </mo>
          <mtable>
            <mtr>
              <mtd>
                <mn>0.05</mn>
              </mtd>
              <mtd>
                <mi mathvariant="italic">if</mi>
                <mspace width="0.222em" />
                <msub>
                  <mi>N</mi>
                  <mrow>
                    <msub>
                      <mi mathvariant="italic">sources</mi>
                      <mi>k</mi>
                    </msub>
                  </mrow>
                </msub>
                <mo>==</mo>
                <mn>1</mn>
                <mspace width="0.222em" />
              </mtd>
            </mtr>
            <mtr>
              <mtd>
                <mn>0.15</mn>
              </mtd>
              <mtd>
                <mi mathvariant="italic">if</mi>
                <mspace width="0.222em" />
                <msub>
                  <mi>N</mi>
                  <mrow>
                    <msub>
                      <mi mathvariant="italic">sources</mi>
                      <mi>k</mi>
                    </msub>
                  </mrow>
                </msub>
                <mo>==</mo>
                <mn>2</mn>
                <mspace width="0.222em" />
              </mtd>
            </mtr>
            <mtr>
              <mtd>
                <mn>0.25</mn>
              </mtd>
              <mtd>
                <mi mathvariant="italic">if</mi>
                <mspace width="0.222em" />
                <msub>
                  <mi>N</mi>
                  <mrow>
                    <msub>
                      <mi mathvariant="italic">sources</mi>
                      <mi>k</mi>
                    </msub>
                  </mrow>
                </msub>
                <mo>&gt;=</mo>
                <mn>3</mn>
                <mspace width="0.222em" />
              </mtd>
            </mtr>
            <mtr>
              <mtd>
                <mn>0</mn>
              </mtd>
              <mtd>
                <mi mathvariant="italic">otherwise</mi>
              </mtd>
            </mtr>
          </mtable>
        </mrow>
      </mrow>
    </math>
  );
}

function gdaScore4() {
  return (
    <math display="block" xmlns="http://www.w3.org/1998/Math/MathML">
      <mrow>
        <mi>L</mi>
        <mo>=</mo>
        <mrow>
          <mo stretchy="true" form="prefix">
            &#123;
          </mo>
          <mtable>
            <mtr>
              <mtd>
                <mn>0.4</mn>
              </mtd>
              <mtd>
                <mi mathvariant="italic">if</mi>
                <mspace width="0.222em" />
                <msub>
                  <mi>N</mi>
                  <mrow>
                    <mi mathvariant="italic">pubs</mi>
                  </mrow>
                </msub>
                <mo>&gt;</mo>
                <mn>99</mn>
                <mspace width="0.222em" />
              </mtd>
            </mtr>
            <mtr>
              <mtd>
                <mn>0.3</mn>
              </mtd>
              <mtd>
                <mi mathvariant="italic">if</mi>
                <mspace width="0.222em" />
                <msub>
                  <mi>N</mi>
                  <mrow>
                    <mi mathvariant="italic">pubs</mi>
                  </mrow>
                </msub>
                <mo>&gt;</mo>
                <mn>9</mn>
              </mtd>
            </mtr>
            <mtr>
              <mtd>
                <mn>0.2</mn>
              </mtd>
              <mtd>
                <mi>i</mi>
                <mi>f</mi>
                <mspace width="0.222em" />
                <msub>
                  <mi>N</mi>
                  <mrow>
                    <mi mathvariant="italic">pubs</mi>
                  </mrow>
                </msub>
                <mo>&gt;</mo>
                <mn>1</mn>
              </mtd>
            </mtr>
            <mtr>
              <mtd>
                <mn>0.1</mn>
              </mtd>
              <mtd>
                <mi>i</mi>
                <mi>f</mi>
                <mspace width="0.222em" />
                <msub>
                  <mi>N</mi>
                  <mrow>
                    <mi mathvariant="italic">pubs</mi>
                  </mrow>
                </msub>
                <mo>=</mo>
                <mn>1</mn>
              </mtd>
            </mtr>
            <mtr>
              <mtd>
                <mn>0</mn>
              </mtd>
              <mtd>
                <mi mathvariant="italic">otherwise</mi>
              </mtd>
            </mtr>
          </mtable>
        </mrow>
      </mrow>
    </math>
  );
}

function gdaScore5() {
  return (
    <math display="block" xmlns="http://www.w3.org/1998/Math/MathML">
      <mrow>
        <mi>T</mi>
        <mo>=</mo>
        <mrow>
          <mo stretchy="true" form="prefix">
            &#123;
          </mo>
          <mtable>
            <mtr>
              <mtd>
                <mn>0.1</mn>
              </mtd>
              <mtd>
                <mi mathvariant="italic">if</mi>
                <mspace width="0.222em" />
                <msub>
                  <mi>N</mi>
                  <mrow>
                    <mi>c</mi>
                  </mrow>
                </msub>
                <mo>&gt;</mo>
                <mn>0</mn>
                <mspace width="0.222em" />
              </mtd>
            </mtr>
            <mtr>
              <mtd>
                <mn>0</mn>
              </mtd>
              <mtd>
                <mspace width="0.222em" />
                <msub>
                  <mi>N</mi>
                  <mrow>
                    <mi>c</mi>
                  </mrow>
                </msub>
                <mo>=</mo>
                <mn>0</mn>
                <mspace width="0.222em" />
              </mtd>
            </mtr>
          </mtable>
        </mrow>
      </mrow>
    </math>
  );
}

function DiseaseSpecificityIndex() {
  return (
    <math display="block" xmlns="http://www.w3.org/1998/Math/MathML">
      <mrow>
        <mi mathvariant="italic">DSI</mi>
        <mo>=</mo>
        <mfrac>
          <mrow>
            <msub>
              <mi mathvariant="italic">log</mi>
              <mn>2</mn>
            </msub>
            <mo stretchy="false" form="prefix">
              (
            </mo>
            <mfrac>
              <msub>
                <mi>N</mi>
                <mi>d</mi>
              </msub>
              <msub>
                <mi>N</mi>
                <mi>T</mi>
              </msub>
            </mfrac>
            <mo stretchy="false" form="postfix">
              )
            </mo>
          </mrow>
          <mrow>
            <msub>
              <mi mathvariant="italic">log</mi>
              <mn>2</mn>
            </msub>
            <mo stretchy="false" form="prefix">
              (
            </mo>
            <mfrac>
              <mn>1</mn>
              <msub>
                <mi>N</mi>
                <mi>T</mi>
              </msub>
            </mfrac>
            <mo stretchy="false" form="postfix">
              )
            </mo>
          </mrow>
        </mfrac>
      </mrow>
    </math>
  );
}

function DiseasePleiotropyIndex() {
  return (
    <math display="block" xmlns="http://www.w3.org/1998/Math/MathML">
      <mrow>
        <mi mathvariant="italic">DPI</mi>
        <mo>=</mo>
        <mo stretchy="false" form="prefix">
          (
        </mo>
        <mfrac>
          <msub>
            <mi>N</mi>
            <mrow>
              <mi mathvariant="italic">dc</mi>
            </mrow>
          </msub>
          <msub>
            <mi>N</mi>
            <mrow>
              <mi mathvariant="italic">TC</mi>
            </mrow>
          </msub>
        </mfrac>
        <mo stretchy="false" form="postfix">
          )
        </mo>
      </mrow>
    </math>
  );
}

function vdaScore() {
  return (
    <math display="block" xmlns="http://www.w3.org/1998/Math/MathML">
      <mrow>
        <msub>
          <mi>S</mi>
          <mrow>
            <mi mathvariant="italic">VDA</mi>
          </mrow>
        </msub>
        <mo>=</mo>
        <mi>C</mi>
        <mo>+</mo>
        <mi>L</mi>
        <mo>+</mo>
        <mi>B</mi>
      </mrow>
    </math>
  );
}

function vdaScore1() {
  return (
    <math display="block" xmlns="http://www.w3.org/1998/Math/MathML">
      <mrow>
        <mi>C</mi>
        <mo>=</mo>
        <mrow>
          <mo stretchy="true" form="prefix">
            &#123;
          </mo>
          <mtable>
            <mtr>
              <mtd>
                <mn>0.4</mn>
              </mtd>
              <mtd>
                <mi mathvariant="italic">if</mi>
                <mspace width="0.222em" />
                <msub>
                  <mi>N</mi>
                  <mrow>
                    <msub>
                      <mi mathvariant="italic">sources</mi>
                      <mi>i</mi>
                    </msub>
                  </mrow>
                </msub>
                <mo>==</mo>
                <mn>1</mn>
                <mspace width="0.222em" />
              </mtd>
            </mtr>
            <mtr>
              <mtd>
                <mn>0.6</mn>
              </mtd>
              <mtd>
                <mi mathvariant="italic">if</mi>
                <mspace width="0.222em" />
                <msub>
                  <mi>N</mi>
                  <mrow>
                    <msub>
                      <mi mathvariant="italic">sources</mi>
                      <mi>i</mi>
                    </msub>
                  </mrow>
                </msub>
                <mo>==</mo>
                <mn>2</mn>
                <mspace width="0.222em" />
              </mtd>
            </mtr>
            <mtr>
              <mtd>
                <mn>0.7</mn>
              </mtd>
              <mtd>
                <mi mathvariant="italic">if</mi>
                <mspace width="0.222em" />
                <msub>
                  <mi>N</mi>
                  <mrow>
                    <msub>
                      <mi mathvariant="italic">sources</mi>
                      <mi>i</mi>
                    </msub>
                  </mrow>
                </msub>
                <mo>&gt;</mo>
                <mn>2</mn>
                <mspace width="0.222em" />
              </mtd>
            </mtr>
            <mtr>
              <mtd>
                <mn>0</mn>
              </mtd>
              <mtd>
                <mi mathvariant="italic">otherwise</mi>
              </mtd>
            </mtr>
          </mtable>
        </mrow>
      </mrow>
    </math>
  );
}

function vdaScore2() {
  return (
    <math xmlns="http://www.w3.org/1998/Math/MathML" display="block">
      <mi>L</mi>
      <mo>=</mo>
      <mrow>
        <mo stretchy="true" form="prefix">
          &#123;
        </mo>
        <mtable columnalign="left left" rowspacing=".2em" columnspacing="1em" displaystyle="false">
          <mtr>
            <mtd>
              <mn>0.3</mn>
            </mtd>
            <mtd>
              <mi>i</mi>
              <mi>f</mi>
              <mtext>&#xA0;</mtext>
              <msub>
                <mi>N</mi>
                <mrow className="MJX-TeXAtom-ORD">
                  <mi>p</mi>
                  <mi>u</mi>
                  <mi>b</mi>
                  <mi>s</mi>
                </mrow>
              </msub>
              <mo>&gt;</mo>
              <mn>9</mn>
              <mtext>&#xA0;</mtext>
            </mtd>
          </mtr>
          <mtr>
            <mtd>
              <mn>0.2</mn>
            </mtd>
            <mtd>
              <mi>i</mi>
              <mi>f</mi>
              <mtext>&#xA0;</mtext>
              <msub>
                <mi>N</mi>
                <mrow className="MJX-TeXAtom-ORD">
                  <mi>p</mi>
                  <mi>u</mi>
                  <mi>b</mi>
                  <mi>s</mi>
                </mrow>
              </msub>
              <mo>&gt;</mo>
              <mn>1</mn>
              <mtext>&#xA0;</mtext>
            </mtd>
          </mtr>
          <mtr>
            <mtd>
              <mn>0.1</mn>
            </mtd>
            <mtd>
              <mi>i</mi>
              <mi>f</mi>
              <mtext>&#xA0;</mtext>
              <msub>
                <mi>N</mi>
                <mrow className="MJX-TeXAtom-ORD">
                  <mi>p</mi>
                  <mi>u</mi>
                  <mi>b</mi>
                  <mi>s</mi>
                </mrow>
              </msub>
              <mo>=</mo>
              <mn>1</mn>
              <mtext>&#xA0;</mtext>
            </mtd>
          </mtr>
          <mtr>
            <mtd>
              <mn>0</mn>
            </mtd>
            <mtd>
              <mi>o</mi>
              <mi>t</mi>
              <mi>h</mi>
              <mi>e</mi>
              <mi>r</mi>
              <mi>w</mi>
              <mi>i</mi>
              <mi>s</mi>
              <mi>e</mi>
            </mtd>
          </mtr>
        </mtable>
        <mo fence="true" stretchy="true"></mo>
      </mrow>
      <mspace linebreak="newline" />
    </math>
  );
}

function vdaScore3() {
  return (
    <math xmlns="http://www.w3.org/1998/Math/MathML" display="block">
      <mi>B</mi>
      <mo>=</mo>
      <mrow>
        <mo stretchy="true" form="prefix">
          &#123;
        </mo>
        <mtable columnalign="left left" rowspacing=".2em" columnspacing="1em" displaystyle="false">
          <mtr>
            <mtd>
              <mn>0.2</mn>
            </mtd>
            <mtd>
              <mi>i</mi>
              <mi>f</mi>
              <mtext>&#xA0;</mtext>
              <msub>
                <mi>N</mi>
                <mrow className="MJX-TeXAtom-ORD">
                  <msub>
                    <mi mathvariant="italic">sources</mi>
                    <mi>k</mi>
                  </msub>
                </mrow>
              </msub>
              <mo>&gt;</mo>
              <mn>0</mn>
              <mtext>&#xA0;</mtext>
            </mtd>
          </mtr>
          <mtr>
            <mtd>
              <mn>0</mn>
            </mtd>
            <mtd>
              <mi>o</mi>
              <mi>t</mi>
              <mi>h</mi>
              <mi>e</mi>
              <mi>r</mi>
              <mi>w</mi>
              <mi>i</mi>
              <mi>s</mi>
              <mi>e</mi>
            </mtd>
          </mtr>
        </mtable>
        <mo fence="true" stretchy="true"></mo>
      </mrow>
      <mspace linebreak="newline" />
    </math>
  );
}

function evidenceIndex() {
  return (
    <math display="block" xmlns="http://www.w3.org/1998/Math/MathML">
      <mrow>
        <mi mathvariant="italic">EI</mi>
        <mo>=</mo>
        <mfrac>
          <msub>
            <mi>N</mi>
            <msub>
              <mrow>
                <mi mathvariant="italic">pubs</mi>
              </mrow>
              <mo>+</mo>
            </msub>
          </msub>
          <msub>
            <mi>N</mi>
            <msub>
              <mrow>
                <mi mathvariant="italic">pubs</mi>
              </mrow>
              <mrow>
                <mi mathvariant="italic">total</mi>
              </mrow>
            </msub>
          </msub>
        </mfrac>
      </mrow>
    </math>
  );
}

export {
  jaccardGda,
  jaccardVda,
  gdaScore,
  gdaScore1,
  gdaScore2,
  gdaScore3,
  gdaScore4,
  gdaScore5,
  DiseaseSpecificityIndex,
  DiseasePleiotropyIndex,
  vdaScore,
  vdaScore1,
  vdaScore2,
  vdaScore3,
  evidenceIndex,
};
