const listSourceAcademic = {
  CURATED: ['CURATED'],
};

// sources GDA and DDA
const listSourceGDAs_DDAs = {
  CURATED: ['CURATED', 'CLINGEN', 'CLINVAR', 'MGD_HUMAN', 'ORPHANET', 'PSYGENET', 'RGD_HUMAN', 'UNIPROT'],
  CLINICALTRIALS: ['CLINICALTRIALS'],
  INFERRED: ['INFERRED', 'HPO', 'FINNGEN', 'GWASCAT', 'PHEWASCAT', 'UKBIOBANK'],
  MODELS: ['MODELS', 'MGD_MOUSE', 'TEXTMINING_MODELS', 'RGD_RAT'],
  TEXTMINING: ['TEXTMINING_HUMAN'],
  ALL: ['ALL'],
};

// sources Variant and VDAs
const listSourceVariantAll_VDAs = {
  CURATED: ['CURATED', 'CLINVAR', 'GWASCAT', 'PHEWASCAT', 'UNIPROT'],
  TEXTMINING_HUMAN: ['TEXTMINING_HUMAN'],
  BIOBANK: ['BIOBANK', 'FINNGEN', 'UKBIOBANK'],
  ALL: ['ALL'],
};

// sources Disease
const listSourceDiseaseAll = {
  CURATED: ['CURATED', 'CLINGEN', 'CLINVAR', 'MGD_HUMAN', 'ORPHANET', 'PSYGENET', 'RGD_HUMAN', 'UNIPROT'],
  CLINICALTRIALS: ['CLINICALTRIALS'],
  BIOBANK: ['BIOBANK', 'FINNGEN', 'UKBIOBANK'],
  INFERRED: ['INFERRED', 'FINNGEN', 'GWASCAT', 'HPO', 'INFERRED', 'PHEWASCAT', 'UKBIOBANK'],
  MODELS: ['MODELS', 'MGD_MOUSE', 'TEXTMINING_MODELS', 'RGD_RAT'],
  TEXTMINING: ['TEXTMINING_HUMAN'],
  ALL: ['ALL'],
};
// sources Gene
const listSourceGeneALL = {
  CURATED: ['CURATED', 'CLINGEN', 'CLINVAR', 'MGD_HUMAN', 'ORPHANET', 'PSYGENET', 'RGD_HUMAN', 'UNIPROT'],
  CLINICALTRIALS: ['CLINICALTRIALS'],
  INFERRED: ['INFERRED', 'FINNGEN', 'GWASCAT', 'HPO', 'PHEWASCAT', 'UKBIOBANK'],
  MODELS: ['MODELS', 'MGD_MOUSE', 'TEXTMINING_MODELS', 'RGD_RAT'],
  TEXTMINING: ['TEXTMINING_HUMAN'],
  ALL: ['ALL'],
};

export {
  listSourceGDAs_DDAs,
  listSourceVariantAll_VDAs,
  listSourceAcademic,
  listSourceDiseaseAll,
  listSourceGeneALL,
};
