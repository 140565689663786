export default function buildGraphEndpoint(
  view,
  source,
  tab,
  searchItems,
  pageNum,
  pageSize,
  orderByParam,
  order,
) {
  // line chart:
  // const url = `${baseUrl}?source=${source}${keySearch}&page_number=${0}&page_size=${20}&order_by=${orderByParam}&order=desc`;
  // network chart:
  // const url = `${baseUrl}?source=${source}${keySearch}&page_number=${0}&page_size=${pageSize}&order_by=${orderByParam}&order=desc`;
  // heatmap chart:
  // const url = `${baseUrl}?source=${source}${keySearch}&page_number=${0}&page_size=${pageSize}&order_by=${orderByParam}&order=desc`;

  //   // view mappings
  let viewType;
  viewType = view === 'ALL' ? '/all' : '/summary';
  viewType = ['VDA', 'GDA'].includes(tab) && ['VDA', 'GDA'].includes(tab) ? '/summary' : viewType; // VDA ALL and GDA ALL
  viewType = tab === 'DDA' ? '' : viewType; // DDA has its own backend pattern
  // This is the only difference with ResultsEndpoint
  //   viewType = isEvidence === true ? '' : viewType; // Evidence grids

  // idents mappings
  // The param name:
  let filterParam = null;
  filterParam = view === 'GENES' ? 'gene_ncbi_id' : filterParam;
  filterParam = view === 'DISEASES' ? 'disease' : filterParam;
  filterParam = view === 'VARIANTS' ? 'variant' : filterParam;
  filterParam = view === 'CHEMICALS' ? 'chem_ids_init' : filterParam;
  filterParam = viewType === '/all' ? null : filterParam; // no idents for /all views

  // The backend idents value:
  const backendDiseaseVocabPrefix = viewType !== '/all' && filterParam === 'disease' ? 'UMLS_' : ''; // disease idents need to be patched for association views
  const filterValue = searchItems.map(item => backendDiseaseVocabPrefix + item.id).join(',');

  // tab mappings
  let tabType = tab?.toLowerCase().replace(/s$/, '');
  tabType = tabType === 'chemical' ? 'chem' : tabType;

  const paramsMap = {
    source: source,
    ...(filterParam && { [filterParam]: filterValue }),
    page_number: pageNum,
    page_size: pageSize,
    order_by: orderByParam,
    order: order,
  };
  // if a params is null, dont include in endpoint
  const params = new URLSearchParams(Object.entries(paramsMap).filter(([_, value]) => value !== null));

  const baseEndpoint = `api/${tabType}${viewType}`;
  let endPoint = `${baseEndpoint}?${params.toString()}`;

  return endPoint;
}
