import React from 'react';
import CommonHeader from '../../Components/Headers/CommonHeader.jsx';
import { CaseStudy } from '../../Components/CaseStudy/CaseStudy.jsx';
import SummaryStudy from '../../Components/CaseStudy/SummaryStudy.jsx';
import Footer from '../../Components/Home/Footer.jsx';

export default function CaseStudyPage({ study }) {
  return (
    <React.Fragment>
      <CommonHeader />
      {/* This page shows a specific case study article disgenet.com/specific-case-studies*/}
      <div className="row p-4">
        <div className="col-12 col-md-2 order-0 order-md-0 mb-4 mb-md-0">
          <SummaryStudy />
        </div>
        <div className="col-12 col-md-8 order-1 order-md-1">
          <CaseStudy study={study} />
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}
