import { Auth } from 'aws-amplify';
import { API_URL } from '../Components/Constants';

export default function fetchEndpoint(endpoint, authorization, setAuthError) {
  const headers = {
    'Content-Type': 'application/json',
    accept: 'application/json',
    Authorization: authorization,
  };
  const url = `${API_URL}${endpoint}`;
  return fetch(url, { headers })
    .then(response => response.json())
    .then(response => {
      switch (response.status) {
        case 'OK':
          return response;

        case 400:
          return response;
        case 403:
          // Requested resource unauthorized, pass along message
          return response;
        // Promise.resolve(
        //   signOut().then(() => {
        //     setIsAuthenticated(false);
        //     setAuthorization('');
        //     setGroups([]);
        //   }),
        // );
        // setAuthError('session expired, login again');
        // break;
        case 404:
          return response;
        case 429:
          //alert('You have reached the maximum daily download limit.');
          // setAuthError({
          //   title: response.error,
          //   message: response.message,
          // });
          return response;
        case 500:
          // setAuthError({
          //   title: 'internal server error',
          //   message: response.message,
          // });
          // break;
          return response;
        default:
          setAuthError({
            title: 'action forbidden',
            message: '',
          });
      }
    });
  //.catch(error => console.error('Error:', error));
}
