import React, { useState, useEffect, useRef } from 'react';
import MarkdownIt from 'markdown-it';
import MarkdownItExternalLinks from 'markdown-it-external-links';
import { userAppContext } from '../../Context/Context';

export default function ChatBot() {
  const { isAuthenticated, userApiKey, groups } = userAppContext();
  const [copiedMessageId, setCopiedMessageId] = useState(null);
  const [downloadId, setDownloadId] = useState(null);
  const [lastActivityTime, setLastActivityTime] = useState(new Date().getTime());

  const initialBotMessage = {
    id: 'bot',
    sender: 'bot',
    message: 'Hello! How can I help you?\nDo you want to see what kind of questions I can help you answer?',
    timestamp: new Date(),
  };
  const [messages, setMessages] = useState(() => {
    const savedMessages = localStorage.getItem('chatMessages');
    return savedMessages
      ? JSON.parse(savedMessages).map(message => ({
          ...message,
          timestamp: new Date(message.timestamp),
        }))
      : [initialBotMessage];
  });
  const [inputMessage, setInputMessage] = useState('');
  const [isBotTyping, setIsBotTyping] = useState(false);

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
    localStorage.setItem('chatMessages', JSON.stringify(messages));
  }, [messages]);

  const resetInactivityTimeout = () => {
    setLastActivityTime(new Date().getTime());
  };

  useEffect(() => {
    const checkInactivity = setInterval(() => {
      const currentTime = new Date().getTime();
      if (currentTime - lastActivityTime >= 3600000) {
        handleEndChat(null, userApiKey);
      }
    }, 600000);

    return () => clearInterval(checkInactivity);
  }, [lastActivityTime, userApiKey]);

  const handleSendMessage = async (e, userApiKey) => {
    if (inputMessage.trim() === '') return;

    const currentTime = new Date();
    const userMessage = {
      id: `user-${currentTime.getTime()}`,
      sender: 'user',
      message: inputMessage,
      timestamp: currentTime,
    };

    setMessages(prevMessages => [...prevMessages, userMessage]);
    setInputMessage('');
    setIsBotTyping(true);
    resetInactivityTimeout();

    const url = `${process.env.REACT_APP_URL_CHAT_API}get?msg=${encodeURIComponent(inputMessage)}`;
    const headers = { Authorization: userApiKey };

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: headers,
        credentials: 'include',
      });

      if (!response.ok || !response.body) {
        throw new Error('Network response was not ok');
      }

      let botMessageId = `bot-${currentTime.getTime()}`;
      let accumulatedMessage = '';

      setMessages(prevMessages => [
        ...prevMessages,
        { id: botMessageId, sender: 'bot', message: '', timestamp: new Date() },
      ]);

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      let done = false;
      while (!done) {
        const { value, done: chunkDone } = await reader.read();
        done = chunkDone;

        if (value) {
          let chunk = decoder.decode(value, { stream: true });

          accumulatedMessage += chunk;

          setIsBotTyping(false);

          setMessages(prevMessages => {
            const updatedMessages = [...prevMessages];
            const lastIndex = updatedMessages.findIndex(msg => msg.id === botMessageId);
            if (lastIndex !== -1) {
              updatedMessages[lastIndex] = {
                ...updatedMessages[lastIndex],
                message: accumulatedMessage,
              };
            }
            return updatedMessages;
          });
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setMessages(prevMessages => [
        ...prevMessages,
        {
          id: `bot-error-${currentTime.getTime()}`,
          sender: 'bot',
          message: 'Sorry, there was an error.',
          timestamp: new Date(),
        },
      ]);
    } finally {
      setIsBotTyping(false);
    }
  };

  const handleEndChat = async (e, userApiKey) => {
    const headers = {
      Authorization: userApiKey,
    };
    const urlClear = `${process.env.REACT_APP_URL_CHAT_API}clear`;

    try {
      const response = await fetch(urlClear, {
        method: 'GET',
        headers: headers,
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      localStorage.removeItem('chatMessages');
      setMessages([initialBotMessage]);
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  };

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      if (e.shiftKey) {
        setInputMessage(inputMessage + '\n');
      } else {
        handleSendMessage(e, userApiKey);
      }
      e.preventDefault();
    }
  };

  const handleButtonClick = e => {
    handleSendMessage(e, userApiKey);
  };

  const formatTimestamp = timestamp => {
    const options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    };
    return new Intl.DateTimeFormat('default', options).format(timestamp);
  };

  const formatMessage = message => {
    const md = new MarkdownIt();
    md.use(MarkdownItExternalLinks, { externalClassName: 'external-link' });

    md.renderer.rules.link_open = (tokens, idx) => {
      const href = tokens[idx].attrGet('href');
      return `<a href="${href}" target="_blank" rel="noopener noreferrer">`;
    };

    md.renderer.rules.link_close = (tokens, idx) => {
      return ' <i class="bi bi-box-arrow-up-right"></i>&nbsp;</a>';
    };

    return md.render(message);
  };

  const copyToClipboard = (message, messageId) => {
    navigator.clipboard.writeText(message).then(
      () => {
        setCopiedMessageId(messageId);
        setTimeout(() => setCopiedMessageId(null), 1000);
      },
      err => {
        console.error('Error copying text to clipboard:', err);
      },
    );
  };

  const downloadChatAsTxt = () => {
    const textContent = messages
      .map(msg => {
        const time = formatTimestamp(msg.timestamp);
        return `[${time}] ${msg.sender === 'user' ? 'You' : 'Bot'}: ${msg.message}`;
      })
      .join('\n');

    const blob = new Blob([textContent], { type: 'text/plain' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = 'chat.txt';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);

    setDownloadId(true);

    setTimeout(() => {
      setDownloadId(null);
    }, 2000);
  };

  const isAcademicUser = groups?.includes('ROLE_ACADEMIC');

  return isAuthenticated && !isAcademicUser ? (
    <React.Fragment>
      <div className="pt-2 container overflow-bot">
        <div>
          <h1 className="text-center p-2">
            DISGENET Assistant <i className="bi bi-stars"></i>
          </h1>
        </div>
        <div className="p-2 row justify-content-center">
          <div className="chat-bot-wrapper">
            <div className="chat-bot-container">
              <div className="chat-bot-messages">
                {messages.map((message, index) => (
                  <React.Fragment key={`message-${index}`}>
                    {message.sender === 'user' && (
                      <div>
                        <div className="timestamp-message">{formatTimestamp(message.timestamp)}</div>
                        <div className="user-message d-flex justify-content-end">
                          <div
                            className="bot-message-content"
                            dangerouslySetInnerHTML={{ __html: formatMessage(message.message) }}
                          />
                        </div>
                      </div>
                    )}
                    {message.sender === 'bot' && (
                      <div>
                        <div className="timestamp-message">{formatTimestamp(message.timestamp)}</div>
                        <div className="bot-message received d-flex justify-content-start">
                          <div className="bot-avatar">
                            <i className="bi bi-robot"></i>
                          </div>
                          <div className="bot-message-content position-relative">
                            <div
                              className=""
                              dangerouslySetInnerHTML={{ __html: formatMessage(message.message) }}
                            />

                            <br></br>
                            {message.message !== initialBotMessage.message && message.isValid && (
                              <button
                                className="copy-bot-btn"
                                onClick={() => copyToClipboard(message.message, message.id)}
                              >
                                {copiedMessageId === message.id ? (
                                  <span>
                                    Copied <i className="bi-check"></i>
                                  </span>
                                ) : (
                                  <span>
                                    Copy <i className="bi-copy"></i>
                                  </span>
                                )}
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                ))}
                <div ref={messagesEndRef} />
                {isBotTyping && (
                  <div className="bot-message d-flex justify-content-start">
                    <div className="bot-avatar">
                      <i className="bi bi-robot"></i>
                    </div>
                    <div className="bot-message-content">
                      <i>typing...</i>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="div-chat-bot-input mt-auto p-2 d-flex">
            <div className="chat-input-container">
              <textarea
                className="chat-bot-input"
                placeholder="Type your question..."
                value={inputMessage}
                onChange={e => setInputMessage(e.target.value)}
                onKeyPress={handleKeyPress}
              />
              <button className="button-chat-bot" aria-label="Send message" onClick={handleButtonClick}>
                <i className="bi bi-send"></i>
              </button>
            </div>
          </div>
          <div className="chat-actions">
            <div className="end-chat">
              <button className=" btn-gradient-pink" onClick={e => handleEndChat(e, userApiKey)}>
                Clear Chat
              </button>
            </div>
            <div className="download-chat">
              <button className=" btn-gradient-pink" onClick={downloadChatAsTxt}>
                {downloadId ? (
                  <span>
                    Download <i className="bi-check"></i>
                  </span>
                ) : (
                  <span>Download Chat</span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <div className="container overflow-bot">
        <div>
          <h1 className="text-center p-2">
            DISGENET Assistant <i className="bi bi-stars"></i>
          </h1>
        </div>
        <div className="p-2 row justify-content-center">
          <div className="chat-bot-wrapper">
            <div className="chat-bot-container">
              <div className="chat-bot-messages">
                <React.Fragment>
                  {isAcademicUser ? (
                    <div className="bot-message received d-flex justify-content-start">
                      <div className="bot-avatar">
                        <i className="bi bi-robot"></i>
                      </div>
                      <p className="bot-message-content">
                        As an academic user, you do not have access to the chat. Please explore other options
                        available in{' '}
                        <a className="" href="/plans" target="_blank" rel="noopener noreferrer">
                          <u>Plans</u>.
                        </a>{' '}
                      </p>
                    </div>
                  ) : (
                    <div>
                      <div className="bot-message received d-flex justify-content-start">
                        <div className="bot-avatar">
                          <i className="bi bi-robot"></i>
                        </div>
                        <p className="bot-message-content">
                          Hello! You need to be logged in to access the chat
                        </p>
                      </div>
                      <div className="bot-message received d-flex justify-content-start">
                        <div className="bot-avatar">
                          <i className="bi bi-robot"></i>
                        </div>
                        <p className="bot-message-content">
                          Access:{' '}
                          <a className="" href="/login" target="_blank" rel="noopener noreferrer">
                            <u>Login</u>
                          </a>
                          ,{' '}
                          <a className="" href="/plans" target="_blank" rel="noopener noreferrer">
                            <u>Register</u>
                          </a>{' '}
                          or{' '}
                          <a className="" href="/Support" target="_blank" rel="noopener noreferrer">
                            <u>SUPPORT</u>
                          </a>
                        </p>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
