export function checkTrialExpiration(userInfo) {
  const trialTime = 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds
  const created_at = Number.parseInt(userInfo['custom:created_at']);
  const extendedTrialTime = userInfo['custom:extended_trial_days']
    ? Number.parseInt(userInfo['custom:extended_trial_days']) * 24 * 60 * 60 * 1000
    : 0;
  const expiryTime = created_at + trialTime + extendedTrialTime;
  const today = new Date().getTime();

  return expiryTime < today;
}
