import { vdaScore, vdaScore1, vdaScore2, vdaScore3 } from '../AboutHelpers.js';

function AboutMetricsVdaScore() {
  return (
    <div id="vdaScore">
      <h3>VDA Score</h3>
      <p>The DISGENET Score (S) for VDAs is computed according to:</p>
      <span>{vdaScore()}</span>
      <div>
        <dt>where: </dt>
        <span>{vdaScore1()}</span>
        <p />
        <ul>
          <li className="bullet-item">
            N
            <sub>
              sources<sub>i</sub>
            </sub>{' '}
            is the number of CURATED sources supporting a VDA
          </li>
          <li className="bullet-item">i &#8712; UNIPROT,CLINVAR, GWASCAT, PHEWASCAT</li>
        </ul>
      </div>
      <div>
        <dt>where: </dt>
        <span>{vdaScore2()}</span>
        <p />
        <ul>
          <li className="bullet-item">
            N<sub>pubs</sub> is the number of publications supporting a VDA extracted by text mining.
          </li>
        </ul>
      </div>
      <dt>where: </dt>
      <span>{vdaScore3()}</span>
      <p />
      <ul>
        <li className="bullet-item">
          N
          <sub>
            sources<sub>k</sub>
          </sub>{' '}
          is the number of sources supporting a VDA
        </li>
        <li className="bullet-item">k &#8712; UKBIOBANK, FINNGEN</li>
      </ul>
    </div>
  );
}

export default AboutMetricsVdaScore;
