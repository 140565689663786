import { DiseasePleiotropyIndex } from '../AboutHelpers.js';

function AboutMetricsPleiotropy() {
  return (
    <div id="pleiotropy">
      <h3>Disease Pleiotropy Index</h3>
      The rationale is similar than for the DSI, but we consider if the multiple diseases associated to the
      gene (or variant) are similar among them (belong to the same MeSH disease class , e.g. Cardiovascular
      Diseases) or are completely different diseases and belong to different disease classes.The Disease
      Pleiotropy Index (DPI) is computed according to:
      <span>{DiseasePleiotropyIndex()}</span>
      <dt>where: </dt>
      <ul>
        - N<sub>dc</sub> is the number of the different MeSH disease classes of the diseases associated to the
        gene/variant - N<sub>TC</sub> is the total number of MeSH diseases classes in DISGENET (29)
      </ul>
      <p>
        The DPI ranges from 0 to 1. Example: gene KCNT1 is associated to 39 diseases, 4 disease groups, and 18
        phenotypes. 29 out of the 39 diseases have a MeSH disease class. The 29 diseases are associated to 5
        different MeSH classes. The DPI index for KCNT1 = 5/29 ~0.172. Nevertheless, gene APOE, associated to
        more than 700 diseases of 27 disease classes has a DPI of 0.931.
      </p>
      <p>
        If the gene/variant has no DPI value, it implies that the gene/variant is associated only to
        phenotypes, or that the associated diseases do not map to any MeSH classes.
      </p>
    </div>
  );
}

export default AboutMetricsPleiotropy;
