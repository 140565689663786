import React from 'react';
import PricingTables from './PricingTable';
import { useNavigate } from 'react-router-dom';

const pricings = [
  {
    title: 'Academic',
    licence1: 'Not-For-Profit Research',
    image: <img src="../../static/images/bioinformatics_icon.svg" style={{ width: '60px' }} />,
    webPlatform: ['Academic plan includes'],
    limits: ['Expert-curated resources', 'A variety of metrics & ontologies', 'Quarterly updates'],
    registerText: 'Apply for Free',
    forWhom1: 'For academics, students',
    forWhom2: 'and not-for-profit researchers',
    included:
      'Access fundamental bioinformatics information for academic research, including well-known disease genes and variants.',
  },
  {
    title: 'Standard',
    licence1: 'Web Explorer',
    image: <img src="../../static/images/bioinformatics_icon.svg" style={{ width: '60px' }} />,
    webPlatform: ['Academic features plus'],
    limits: ['Text-mined data', 'Drugs & chemicals', 'Pathway annotations'],
    registerText: 'Contact Us',
    forWhom1: 'For for-profit researchers',
    forWhom2: 'and research teams',
    included:
      'Access the full database, including drugs, chemicals, and the latest findings, accessible through a user-friendly web platform.',
  },
  {
    info: '✳ Most Popular ✳',
    title1: 'Advanced',
    licence: 'API Access',
    webPlatform: ['Standard features plus'],
    limits: ['Integrate with existing systems', 'Data analysis and visualization', 'Disgenet2r R package'],
    registerText: 'Try for Free',
    forWhom1: 'For academics, enterprises',
    forWhom2: 'and for-profit researchers',
    included:
      'Streamline your workflows by connecting the full database, including all the latest findings, with your existing systems via an API.',
  },
  {
    title: 'Premium',
    licence1: 'Flexibility & Customization',
    // licence1: <img src="../../static/images/bioinformatics_icon.svg" style={{ width: '60px' }} />,
    webPlatform: ['Advanced features plus'],
    limits: ['Create bespoke datasets', 'Develop custom algorithms', 'Full control over data'],
    registerText: 'Contact Us',
    forWhom1: 'For large enterprises,',
    forWhom2: 'high-volume data users',
    included:
      'Gain complete control of the DISGENET data for your specific research needs by integrating it into your own data infrastructure.',
  },
];

export function PricingCard({ title, title1, info, licence, licence1, webPlatform, limits, registerText }) {
  const navigate = useNavigate();

  const redirectToRegister = () => {
    let registerUrl = '/Register';

    if (title === 'Academic') {
      registerUrl = '/academic-apply';
    }
    if (title1 === 'Advanced') {
      registerUrl = '/free-trial-apply';
    }
    if (title === 'Standard' || title === 'Premium') {
      registerUrl = '/Support';
    }

    navigate(registerUrl);
  };

  return (
    <div className="PricingCard">
      <header>
        <p className="card-info">{info}</p>
        <p className="card-title">{title}</p>
        <p className="card-title1">{title1}</p>
        <p className="card-price">{licence}</p>
        <p className="card-price1">{licence1}</p>
        {/* <p className="card-price">{image}</p> */}
      </header>
      <div className="card-webPlatform">
        {Object.entries(webPlatform).map(([key, value]) => (
          <div key={key}>{value}</div>
        ))}
      </div>
      <div className="card-features">
        {limits.map((limit, index) => (
          <div key={index}>{limit}</div>
        ))}
      </div>
      <button className="card-btn" onClick={redirectToRegister}>
        {registerText}
      </button>
    </div>
  );
}

function WhatsIncluded({ forWhom1, forWhom2, forWhom, included }) {
  return (
    <div className="whats-included-div">
      <p className="whats-included-tit">
        <strong>{forWhom1}</strong>
      </p>
      <p className="whats-included-tit">
        <strong>{forWhom2}</strong>
      </p>
      <p className="whats-included">{included}</p>
    </div>
  );
}

function RenderPricings() {
  const scrollToPricingSection = () => {
    const pricingSection = document.getElementById('pricingSection');
    pricingSection.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="PricingApp">
      <link rel="canonical" href="/plans" />
      <div className="app-container">
        <header>
          <h1 className="header-topic gradient-color" id="pricingSection">
            Our Plans
          </h1>
        </header>
        <div className="pricing-cards p-3">
          {pricings.map(pricing => (
            <PricingCard key={pricing.title} {...pricing} />
          ))}
        </div>
        <div className="div-whats-included">
          <h4 className="gradient-color text-center p-4">What’s included?</h4>
          <div className="whats-included">
            {pricings.map(pricing => (
              <WhatsIncluded key={pricing.included} {...pricing} />
            ))}
          </div>
          <div className="pt-4">
            <PricingTables />
          </div>
        </div>
        <div className="text-center pt-4">
          <button className="your-plan-btn" onClick={scrollToPricingSection}>
            FIND YOUR PERFECT PLAN
          </button>
        </div>
      </div>
    </div>
  );
}

export default RenderPricings;
