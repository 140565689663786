import { gdaScore, gdaScore1, gdaScore2, gdaScore3 } from '../AboutHelpers.js';

function AboutMetricsGdaScore() {
  return (
    <div id="gdaScore">
      <h3>GDA Score</h3>
      <p>The DISGENET Score (S) for GDAs is computed according to: </p>
      <span>{gdaScore()}</span>
      <dt>where: </dt>
      <span>{gdaScore1()}</span>
      <p />{' '}
      <ul>
        <li className="bullet-item">
          N
          <sub>
            sources<sub>i</sub>
          </sub>{' '}
          is the number of CURATED sources supporting a GDA
        </li>
        <li className="bullet-item">
          i &#8712; CLINGEN, CLINVAR, PSYGENET, ORPHANET, UNIPROT, MGD HUMAN, RGD RAT, RGD HUMAN
        </li>
      </ul>
      <dt>where: </dt>
      {gdaScore2()}
      <p />
      <ul>
        <li className="bullet-item">
          N
          <sub>
            sources<sub>k</sub>
            is the number of INFERRED sources supporting a GDA
          </sub>{' '}
        </li>
        <li className="bullet-item">k &#8712; HPO, GWASCAT, PHEWASCAT, UKBIOBANK, FINNGEN</li>
      </ul>
      <dt>where: </dt>
      <span>{gdaScore3()}</span>
      <p />
      <ul>
        <li className="bullet-item">
          N<sub>pubs</sub> is the number of publications supporting a GDA extracted by text mining.
        </li>
      </ul>
    </div>
  );
}

export default AboutMetricsGdaScore;
