import { useNavigate } from 'react-router-dom';
import { userAppContext } from '../Context/Context';
import { useEffect } from 'react';

const UseProtectedRoute = ({ children }) => {
  const { groups, user } = userAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (groups?.includes('ROLE_TRIAL')) {
      const trialTime = 7 * 24 * 60 * 60 * 1000;
      const created_at = Number.parseInt(user['custom:created_at'] || 0);
      const extendedTrialTime =
        Number.parseInt(user['custom:extended_trial_days'] || 0) * 24 * 60 * 60 * 1000;
      const expiryTime = created_at + trialTime + extendedTrialTime;
      const today = new Date().getTime();

      if (expiryTime < today) {
        navigate('/see-plans');
      }
    }
  }, [groups, user, navigate]);

  return children;
};

export default UseProtectedRoute;
