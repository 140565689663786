import React from 'react';
import { InlineWidget } from 'react-calendly';

function Calendly() {
  return (
    <>
      <button
        type="button"
        // className="schedule_demo  btn rounded-pill"
        className="btn-gradient-pink py-2"
        data-toggle="modal"
        data-target="#calendlyModal"
      >
        SCHEDULE A DEMO
      </button>

      <div
        className="modal fade"
        id="calendlyModal"
        tabIndex="-1"
        aria-labelledby="calendlyModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content calendly">
            <div className="modal-header px-4">
              <h5
                className="modal-title text-light font-weight-bold p-0 text-center "
                id="calendlyModalLabel"
              >
                Book your meeting
              </h5>
              <button
                type="button"
                className="close btn-dark text-light"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body p-1">
              <InlineWidget
                url="https://calendly.com/medbioinformatics/disgenet-demo"
                pageSettings={{
                  backgroundColor: 'ffffff',
                  hideEventTypeDetails: false,
                  hideLandingPageDetails: false,
                  primaryColor: 'E61C55',
                  textColor: '264761',
                }}
                styles={{
                  width: 'auto',
                  height: '800px',
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Calendly;
