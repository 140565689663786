import { evidenceIndex } from '../AboutHelpers.js';

function AboutMetricsEvidenceIndex() {
  return (
    <div id="evindex">
      <h3>Evidence Index</h3>
      <p>
        The "Evidence index" (EI) indicates the existence of contradictory results in publications supporting
        the gene/variant-disease associations. This index is computed for associations supported by text
        mining, and by PsyGeNET, by identifying the publications reporting a negative finding on a particular
        VDA or GDA. Note that only in the case of PsyGeNET, the information used to compute the EI has been
        validated by experts.
      </p>
      <p>The EI is computed as follows: </p>
      <span>{evidenceIndex()}</span>
      <p>
        EI = 1 indicates that all the publications support the GDA or the VDA, while {'EI < 1'} indicates that
        there are publications that assert that there is no association between the gene/variants and the
        disease. If the gene/variant has no EI value, it indicates that the index has not been computed for
        this association.
      </p>
      <dt>where: </dt>
      <ul>
        <p>
          N
          <sub>
            pubs<sub>+</sub>
          </sub>{' '}
          is the number of publication supporting an association
        </p>
        <p>
          N
          <sub>
            pubs<sub>total</sub>
          </sub>{' '}
          is the total number of publications.
        </p>
      </ul>
    </div>
  );
}

export default AboutMetricsEvidenceIndex;
