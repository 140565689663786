import React, { useEffect, useState, useMemo } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Element } from 'react-scroll';
import { searchContext, userAppContext } from '../../Context/Context.jsx';
import GeneContent from '../GeneContent/GeneContent.jsx';
import ChangeViewsButton from './ChangeViewsButton.jsx';
import { scroller } from 'react-scroll';

import ManageGrid from './ManageGrid.jsx';
import ManageGraph from './ManageGraph.jsx';

import SummaryGDAGraphs from '../Graphs/SummaryGDA/SummaryGDAGraphs.jsx';
import SummaryVDAGraphs from '../Graphs/SummaryVDA/SummaryVDAGraphs.jsx';
import DDAGraphs from '../Graphs/DDA/DDAGraphs.jsx';
import InfoDiv from './InfoDiv.jsx';

import updateParams from '../Utils/UpdateParams.jsx';
import { useNavigate } from 'react-router-dom';
import MappingResults from '../Mapping/MappingResults.jsx';

function SearchResults() {
  const { view, source, tab, searchItems, setSearchItems, geneContent, setGeneContent, mappingContent } =
    searchContext();
  const { restrictGraphDisplay, isAuthenticated, groups } = userAppContext();
  const [isInfoSidebarOpen, setIsInfoSidebarOpen] = useState(false);
  // const tabIndex = tab ? tabList.findIndex(t => t.key === tab) : 0;
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedView, setSelectedView] = useState('tables');

  const isTrial = groups?.includes('ROLE_TRIAL');

  // Tab configuration
  const tabLabels = [
    { key: 'GDA', label: 'GDAs' },
    { key: 'VDA', label: 'VDAs' },
    { key: 'DDA', label: 'DDAs' },
    { key: 'DISEASES', label: 'DISEASES' },
    { key: 'GENES', label: 'GENES' },
    { key: 'VARIANTS', label: 'VARIANTS' },
    { key: 'CHEMICALS', label: 'CHEMICALS' },
  ];

  const tabList = useMemo(() => {
    if (view === 'ALL') {
      return tabLabels.filter(t =>
        ['GDA', 'VDA', 'DISEASES', 'GENES', 'VARIANTS', 'CHEMICALS'].includes(t.key),
      );
    } else if (view === 'GENES' && ['GDA', 'VDA'].includes(tab)) {
      return tabLabels.filter(t => ['GDA', 'VDA'].includes(t.key));
    } else if (view === 'DISEASES' && ['GDA', 'VDA', 'DDA'].includes(tab)) {
      return tabLabels.filter(t => ['GDA', 'VDA', 'DDA'].includes(t.key));
    } else if (view === 'VARIANTS' && ['VDA'].includes(tab)) {
      return tabLabels.filter(t => ['VDA'].includes(t.key));
    } else if (view === 'CHEMICALS' && ['GDA', 'VDA'].includes(tab)) {
      return tabLabels.filter(t => ['GDA', 'VDA'].includes(t.key));
    } else {
      console.error('Invalid tab selection');
      return [];
    }
  }, [view, tab]);

  const navigate = useNavigate();
  const handleTabSelect = index => {
    // Update nav component state
    setTabIndex(index);
    // Update URL state
    const newTab = tabList[index].key;
    const newParams = updateParams({ tab: newTab });
    navigate({ pathname: window.location.pathname, search: newParams });
  };

  useEffect(() => {
    // Update nav component state based on app state
    const newIndex = tabList.findIndex(t => t.key === tab);
    setTabIndex(newIndex);
  }, [tab, tabList]);

  // Table/Graph button
  // Button is only shown on Association views, and not CHEMICALS
  // const showManageGraph = view !== tab && ['GDA', 'VDA', 'DDA'].includes(tab);
  const showManageGraph = view !== 'ALL' && view !== 'CHEMICALS';

  // Grid view items
  // Every page has a summary
  const showSummary = true;

  // DDA and some ALL views do not have an evidence grid
  let showEvidence = true;
  if (tab === 'DDA') showEvidence = false;
  if (view === 'ALL' && !['GDA', 'VDA'].includes(tab)) showEvidence = false;

  // Graph view items
  const graphMap = {
    GDA: SummaryGDAGraphs,
    VDA: SummaryVDAGraphs,
    DDA: DDAGraphs,
  };

  useEffect(() => {
    // page should return to table view when last searchItem is removed
    if (searchItems?.length === 0) setSelectedView('tables');
  }, [searchItems]);

  // scroll to ref animation
  const scrollToRef = ref => {
    scroller.scrollTo(ref, {
      duration: 750,
      // delay: 50,
      // smooth: 'easeInOutQuad',
      smooth: true,
    });
  };

  useEffect(() => {
    if (geneContent.isActive) {
      scrollToRef('Extra-Content');
    }
    if (mappingContent.isActive) {
      scrollToRef('Mapping');
    }
  }, [geneContent.isActive, mappingContent.isActive]);

  return (
    <>
      {!isAuthenticated && view !== 'ALL' && (
        <div className="info-container p-2 text-center">
          <p style={{ fontSize: '18px', color: 'rgb(190,0,128)' }}>
            As an unregistered user you are viewing only 10 results ordered by score for each query. To view
            more, register for an account. Learn more about our <a href="/plans">plans here</a>.
          </p>
        </div>
      )}
      {isTrial && (
        <div className="info-container p-2 text-center">
          <p style={{ fontSize: '18px', color: 'rgb(190,0,128)' }}>
            Please note that the examples shown on this page only display the top-30 results ordered by score.
            To view more, upgrade your account. Learn more about our <a href="/plans">plans here</a>.
          </p>
        </div>
      )}
      <div className={`search-results-container ${selectedView === 'tables' ? 'flex' : ''}`}>
        {isInfoSidebarOpen && (
          <div className="info-container">
            <InfoDiv searchItems={searchItems} view={view} setIsInfoSidebarOpen={setIsInfoSidebarOpen} />
          </div>
        )}

        <div className="p-5" style={{ flex: '1', marginLeft: '10px' }}>
          <Tabs
            className="react-tabs superposed-content"
            selectedIndex={tabIndex}
            onSelect={index => handleTabSelect(index)}
          >
            <div className="d-flex justify-content-between align-items-center">
              <TabList className="react-tabs__tab-list">
                {tabList.map(t => (
                  <Tab key={t.key} className={'react-tabs__tab bg-white'}>
                    {/* <i className="fa fa-dot-circle-o" /> */}
                    {t.label}
                  </Tab>
                ))}
              </TabList>
              {showManageGraph && !restrictGraphDisplay && (
                <ChangeViewsButton setSelectedView={setSelectedView} selectedView={selectedView} />
              )}
            </div>

            <div>
              {tabList.map(t => (
                <TabPanel key={t.key} className="tab-pane">
                  <>
                    <Element name="Summary">
                      {selectedView === 'tables' && showSummary && (
                        <ManageGrid
                          source={source}
                          view={view}
                          tab={tab}
                          searchItems={searchItems}
                          setSearchItems={setSearchItems}
                          isEvidence={false}
                          isMapping={false}
                          isInfoSidebarOpen={isInfoSidebarOpen}
                          setIsInfoSidebarOpen={setIsInfoSidebarOpen}
                        />
                      )}
                      {selectedView === 'graphs' && showManageGraph && (
                        <ManageGraph
                          source={source}
                          view={view}
                          tab={tab}
                          searchItems={searchItems}
                          setSearchItems={setSearchItems}
                          sectionTitle={t.label}
                          names={searchItems}
                          title={view}
                          Graph={graphMap[t.key]}
                          setIsInfoSidebarOpen={setIsInfoSidebarOpen}
                        />
                      )}
                    </Element>
                    <Element name="Evidence">
                      {selectedView === 'tables' && showEvidence && (
                        <ManageGrid
                          view={view}
                          source={source}
                          tab={tab}
                          searchItems={searchItems}
                          setSearchItems={setSearchItems}
                          isEvidence={true}
                          isMapping={false}
                          setIsInfoSidebarOpen={setIsInfoSidebarOpen}
                          isInfoSidebarOpen={isInfoSidebarOpen}
                        />
                      )}
                    </Element>{' '}
                    <Element name="Mapping">
                      {selectedView === 'tables' && mappingContent?.isActive && (
                        <MappingResults mappingContent={mappingContent} />
                      )}
                    </Element>
                  </>
                </TabPanel>
              ))}
            </div>
          </Tabs>
          <div className="gene-content-container">
            <Element name="Extra-Content" />
            {geneContent.isActive && (
              <GeneContent
                isActive={geneContent.isActive}
                geneInfo={geneContent.geneInfo}
                setGeneContent={setGeneContent}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
export default SearchResults;
