import React from 'react';

function AboutSchema() {
  return (
    <div>
      <h2>The DISGENET schema</h2>
      <p>
        DISGENET data is standardized using community-supported standards and ontologies. The data can be
        filtered and selected by using different attributes, metrics and scores available for the genes,
        variants, diseases and their associations.
      </p>
      <img
        src="../../static/images/Release_DGNplusSchema_SVG.svg"
        style={{ float: 'middle', margin: '20px 20px 20px 40px' }}
        width="80%"
        height="80%"
        alt="DISGENET schema"
      />
      <div className="divider" />
    </div>
  );
}

export default AboutSchema;
