import acceleratingresearchwithdisgenet from './CaseStudies/acceleratingresearchwithdisgenet';

const studies = [
  {
    title: 'How Harvard Medical School Accelerates Research & Student Learning With DISGENET',
    slug: 'how-harvard-accelerates-research-with-disgenet', //link
    date: 'Mar 24, 2025',
    image: '../../static/images/caseStudies/1_card_image.jpg',
    description:
      'Discover how Harvard Medical School accelerates research and enhances student learning with DISGENET. Alba Gutiérrez-Sacristán, an instructor in Biomedical Informatics, leverages DISGENET to streamline [...]',
  },
  // {
  //   title: 'Case Study 2',
  //   slug: 'example-Casestudy2',
  //   date: 'fer 19, 2025',
  //   image: '../../static/images/blog/DISGENET-Assistant-post5.jpg',
  //   description: 'text summary...dgdfgfdgf[...]',
  // },
];

const generateContent = study => {
  switch (study.slug) {
    case 'how-harvard-accelerates-research-with-disgenet':
      return acceleratingresearchwithdisgenet();
    // case 'example-Casestudy2':
    //   return <PostContentLicenseGuide />;
    default:
      return null;
  }
};

studies.forEach(study => {
  study.content = generateContent(study);
});

export { studies, generateContent };
