import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'aws-amplify/auth';
import { userAppContext } from '../Context/Context';
import CommonHeader from '../Components/Headers/CommonHeader';
import Footer from '../Components/Home/Footer';

const LogoutPage = () => {
  const navigate = useNavigate();
  const { setIsAuthenticated, setAuthorization, setGroups } = userAppContext();

  useEffect(() => {
    const handleLogout = async () => {
      try {
        await signOut();
        setIsAuthenticated(false);
        setGroups([]);
        setAuthorization('');
        navigate('/');
      } catch (error) {
        console.error('Error during logout:', error);
      } finally {
        // nothing
      }
    };

    handleLogout();
  }, [navigate, setIsAuthenticated, setAuthorization, setGroups]);

  return (
    <>
      <CommonHeader />
      <div>
        <div className="logout-page">
          <h1>Logging out...</h1>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default LogoutPage;
