import React from 'react';
import { useNavigate } from 'react-router-dom';

export const LinksExamples = ({ homeClasses = '' }) => {
  const navigate = useNavigate();

  const examplesData = [
    { label: 'Obesity', view: 'DISEASES', ident: 'C0028754', tab: 'GDA' },
    { label: 'LEPR', view: 'GENES', ident: '3953', tab: 'GDA' },
    { label: 'rs121913279', view: 'VARIANTS', ident: 'rs121913279', tab: 'VDA' },
    { label: 'Streptozocin', view: 'CHEMICALS', ident: 'C-235751', tab: 'GDA' },
  ];

  const handleClick = (view, ident, tab) => {
    navigate(`/search?view=${view}&idents=${ident}&source=ALL&tab=${tab}`);
  };

  return (
    <div className="mt-3">
      <span>Examples:</span>
      {examplesData.map((example, index) => (
        <span
          key={index}
          style={{
            margin: '0.25rem 0.65rem',
            fontSize: '0.9rem',
            color: 'rgb(61,72,95)',
            cursor: 'pointer',
            textDecoration: 'none',
          }}
          onClick={() => handleClick(example.view, example.ident, example.tab)}
        >
          {example.label} <i className="bi-box-arrow-right"></i>
        </span>
      ))}
    </div>
  );
};
