import { DiseaseSpecificityIndex } from '../AboutHelpers.js';

function AboutMetricsSpecificity() {
  return (
    <div id="specificity">
      <h3>Disease Specificity Index</h3>
      There are genes (or variants) that are associated with multiple diseases (e.g.TNF) while others are
      associated with a small set of diseases or even to a single disease.The Disease Specificity Index (DSI)
      is a measure of this property of the genes (and variants).It reflects if a gene (or variant) is
      associated to several or fewer diseases.It is computed according to:
      <span>{DiseaseSpecificityIndex()}</span>
      <dt>where: </dt>
      <ul>
        - N<sub>d</sub> is the number of diseases associated to the gene/variant - N<sub>T</sub> is the total
        number of diseases in DISGENET
      </ul>
      <p>
        The DSI ranges from 0.23 to 1. Example: TNF, associated with more than 3,500 diseases, has a DSI of
        0.23, while MOB3A is associated with one disease, with a DSI of 1.
      </p>
      <p>If the DSI is empty, it implies that the gene/variant is associated only to phenotypes.</p>
    </div>
  );
}

export default AboutMetricsSpecificity;
