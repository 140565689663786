import React from 'react';
import { Helmet } from 'react-helmet-async';
import Logo from '../Components/Common/Logo';
import { TopRightMenu } from '../Components/NavMenus/TopNavigation';
import Footer from '../Components/Home/Footer';
import ApplyAcademic from '../Components/SignUp/ApplyAcademicPage';

function ApplyAcademicPage() {
  return (
    <React.Fragment>
      <div className="header-content">
        <Helmet>
          <title>DISGENET: Apply for Academic Access</title>
          <meta
            name="description"
            content="Accelerate Your Research With The Most Reliable & Extensive Database."
          />

          {/* Open Graph Tags for LinkedIn */}
          <meta property="og:title" content="DISGENET: Apply for Academic Access" />
          <meta
            property="og:description"
            content="Accelerate your research by applying for academic access to the most reliable and extensive database for scientific studies."
          />
          <meta property="og:url" content="https://www.disgenet.com/academic-apply" />
          <meta property="og:image" content="https://www.disgenet.com/academic-apply" />

          <meta property="og:type" content="website" />

          <link rel="canonical" href="/academic-apply" />
        </Helmet>
        <div>
          <Logo />
        </div>
        <div>
          <TopRightMenu />
        </div>
      </div>
      <div className="d-flex justify-content-end mx-4"></div>
      <ApplyAcademic />
      <Footer />
    </React.Fragment>
  );
}

export default ApplyAcademicPage;
