import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import '@inovua/reactdatagrid-community/index.css';
import { userAppContext } from '../../Context/Context.jsx';
import buildResultEndpoint from '../Utils/buildResultEndpoint.jsx';
import { filterTypes } from './SetupTablesResources.jsx';
import fetchEndpoint from '../../Services/fetchEndpoint.jsx';
import Filters from './Filters.jsx';
import ErrorMessage from '../UI/ErrorMessage.jsx';

function Grid({
  columns,
  columnVisibilityModel,
  view,
  source,
  tab,
  searchItems,
  isEvidence,
  isMapping,
  setTotalResults,
  ...rest
}) {
  const navigate = useNavigate();
  const {
    isAuthenticated,
    authorization,
    setIsAuthenticated,
    groups,
    setGroups,
    setAuthError,
    setAuthorization,
    restrictGridDisplay,
    restrictGridResultCount,
    restrictBrowseAll,
    restrictSources,
    restrictChemical,
    mappingContent,
  } = userAppContext();

  // Refs to avoid unwanted scrolling
  const gridContainerRef = useRef(null);
  const lastScrollLeft = useRef(0);

  // State Management
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const [gridRef, setGridRef] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [orderByParam, setOrderByParam] = useState(undefined);
  const [order, setOrder] = useState(undefined);
  const [defaultFilterValue, setDefaultFilterValue] = useState(Filters.filter(filter => filter));
  const [isFiltering, setIsFiltering] = useState(false); // New state to track filtering

  const isTrial = groups?.includes('ROLE_TRIAL');
  const isAcademic = groups?.includes('ROLE_ACADEMIC');

  const defaultSortInfo = [];
  const sortInfo = orderByParam ? { dir: isTrial ? 1 : order, name: orderByParam } : defaultSortInfo;

  // Helper function to determine pageSizes
  function getPageSizes() {
    if (!isAuthenticated) return [10];
    if (isTrial) return [10, 30];
    if (restrictBrowseAll && view === 'ALL') return [10, 30];
    return [10, 20, 25, 40, 50, 100];
  }

  // Effect for data fetching
  useEffect(() => {
    if (!(view && source && tab && searchItems)) return;

    let urlEndpoint = buildResultEndpoint({
      view,
      source,
      tab,
      searchItems,
      isEvidence,
      isMapping,
      mappingContent,
      pageNum: restrictGridResultCount || (restrictBrowseAll && view === 'ALL') ? 0 : skip / limit,
      pageSize: limit,
      orderByParam,
      order,
      defaultFilterValue,
    });

    if (!isAuthenticated && skip !== 0) {
      setShowPopup(true);
      return;
    }

    if (restrictBrowseAll && view === 'ALL' && skip !== 0) {
      setShowPopup(true);
      return;
    }

    if (isTrial && skip !== 0) {
      setShowPopup(true);
      return;
    }

    if (!urlEndpoint) return;
    urlEndpoint = !isAuthenticated ? `${urlEndpoint}&skey=unauth` : urlEndpoint;

    setDataSource(prev => {
      const result = fetchEndpoint(
        urlEndpoint,
        authorization,
        setAuthorization,
        setIsAuthenticated,
        setGroups,
        setAuthError,
        prev,
      )
        .then(res => {
          const data = res?.payload;
          const totalCount = res?.paging ? res.paging.totalElements : 0;

          if (data) {
            // Update result count state
            setTotalResults(totalCount);
            setIsSessionExpired(false);
            return Promise.resolve({ data, count: parseInt(totalCount) });
          }

          // If 403, session has expired
          if (res?.status === 403) setIsSessionExpired(true);
          return Promise.resolve({ data: [], count: 0 });
        })
        .catch(error => {
          if (process.env.NODE_ENV === 'production') {
            return Promise.resolve({ data: [], count: 0 });
          } else {
            console.error('Error:', error);
            throw new Error(error);
          }
        });

      return result;
    });
  }, [view, source, tab, searchItems, skip, limit, order, orderByParam, defaultFilterValue]);

  // Sorting and Filtering
  const onSortInfoChange = useCallback(value => {
    const [sort] = Array.isArray(value) ? value : [value];
    setOrder(sort?.dir);
    setOrderByParam(sort?.name);
  }, []);

  const onFilterValueChange = value => {
    const scrollX = gridContainerRef.current?.scrollLeft || 0; // avoid scrolling when filtering
    setDefaultFilterValue(value);
    setIsFiltering(value.length > 0);

    setTimeout(() => {
      if (gridContainerRef.current) {
        gridContainerRef.current.scrollLeft = scrollX;
      }
    }, 0);
  };

  // Render custom context menu
  const renderColumnContextMenu = useCallback(menuProps => {
    const menuPropsLength = menuProps.items?.length;
    menuProps.items = menuProps.items.splice(0, menuPropsLength - 2);
  }, []);

  const handleClosePopup = useCallback(() => {
    setShowPopup(false);
    setSkip(0);
  }, []);

  // Empty state message
  const emptyText =
    dataSource.length == null ? (
      <b>{isAcademic ? 'data unavailable for the Academic Plan' : 'no data available'}</b>
    ) : null;

  const scrollToLeft = useCallback(() => {
    if (!isFiltering) {
      gridRef.current.setScrollLeft(0); //?
    }
  }, [gridRef, isFiltering]);

  // Error handling
  if (isSessionExpired) {
    return <ErrorMessage title={'Session expired'} subtitle={<a>Please refresh the page</a>} />;
  }

  // restriction handling
  if (
    (restrictGridDisplay && view === 'ALL') ||
    (restrictChemical && tab === 'CHEMICALS') ||
    (restrictChemical && view === 'CHEMICALS') ||
    (restrictChemical && isMapping && mappingContent?.tabSegment.includes('chem')) ||
    (restrictChemical && isMapping && mappingContent?.name === 'numberPmidsWithChemsIncludedInEvidence')
  ) {
    return (
      <ErrorMessage
        title={'This content is not available with your current profile'}
        subtitle={
          <a>
            <button onClick={() => navigate('/plans')}>
              View our <a href="/plans">plans</a>
            </button>
          </a>
        }
      />
    );
  }

  return (
    <>
      <ReactDataGrid
        idProperty="name"
        columns={columns}
        style={{ minHeight: 537 }}
        renderColumnContextMenu={renderColumnContextMenu}
        licenseKey={process.env.REACT_APP_DATA_GRID_LICENSE}
        filterTypes={filterTypes}
        pagination={true}
        enableFiltering={true}
        nativeScroll={true}
        limit={limit}
        skip={skip}
        onLimitChange={setLimit}
        pageSizes={getPageSizes()}
        columnUserSelect
        defaultFilterValue={defaultFilterValue}
        onFilterValueChange={onFilterValueChange}
        dataSource={dataSource}
        showColumnMenuFilterOptions={false}
        showColumnMenuGroupOptions={false}
        columnVisibilityModel={columnVisibilityModel}
        onSkipChange={setSkip}
        onReady={setGridRef}
        sortInfo={sortInfo}
        onSortInfoChange={onSortInfoChange}
        scrollTopOnFilter={false}
        scrollTopOnSort={false}
        emptyText={emptyText}
        scrollProps={{
          autoHide: false,
          alwaysShowTrack: true,
          enableSmoothScroll: false,
        }}
      />
      {showPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            {!isAuthenticated && (
              <p>
                As an unregistered user you are viewing only 10 results ordered by score for each query. To
                view more, register for an account. Learn more about our <a href="/plans">plans here</a>.
              </p>
            )}
            {restrictBrowseAll && (
              <p>
                As an Academic user you are viewing only 30 results ordered by score for each query. To view
                more, upgrade your account. Learn more about our <a href="/plans">plans here</a>.
              </p>
            )}
            {isTrial && (
              <p>
                Please note that the examples shown on this page only display the top-30 results ordered by
                score. To view more, <a href="/Profile-area">upgrade</a> your account. Learn more about our{' '}
                <a href="/plans">plans here</a>.
              </p>
            )}
            <button onClick={handleClosePopup}>Close</button>
          </div>
        </div>
      )}
    </>
  );
}

export default Grid;
