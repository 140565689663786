import Calendly from '../../NavMenus/Calendly';

export const acceleratingresearchwithdisgenet = () => {
  const handlePdf = () => {
    const link = document.createElement('a');
    link.href = '../../static/pdf/Case_Study_How_Harvard_Accelerates_Research_With_DISGENET.pdf';
    link.download = 'Case_Study_How_Harvard_Accelerates_Research_With_DISGENET.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <div>
      <h1 className="blog-title">How Harvard Accelerates Research & Student Learning With DISGENET</h1>
      <div className="flex justify-between items-center blog-date">
        <div className="">
          Posted on <strong>March 24, 2025</strong>
        </div>
        <button className="btn-gradient-pink" onClick={handlePdf}>
          PDF
        </button>
      </div>
      <div className="">
        <h2 className="blog-subtitle">Introducing Alba: Harvard Bioinformatics Instructor and Researcher</h2>
        <p className="blog-paragraph">
          Alba Gutiérrez-Sacristán is an instructor in Biomedical Informatics at Harvard Medical School where
          her role combines research and teaching.
          <div className="flex justify-evenly py-3">
            <div className="">
              <img
                src={'../../static/images/caseStudies/1_alba_headshot.jpg'}
                alt={'Alba Gutiérrez-Sacristán'}
                className="p-3 hidden lg:block"
                style={{ clipPath: 'circle(40% at 50% 50%)' }}
              />
            </div>
            <div className="flex justify-evenly items-center w-full">
              <p className="blog-quote">
                <span className="quote-left">&ldquo;</span>
                When they told me, I have been doing a search about this variant here and there and there,
                it&apos;s like, did you check DISGENET? You could have got this done just in one search. We
                all like to save time. We all like to be efficient.
                <span className="quote-right">&rdquo;</span>
                <span style={{ display: 'block', textAlign: 'right' }}>
                  <strong>- Alba</strong>
                </span>
              </p>
            </div>
          </div>
        </p>
      </div>
      <div>
        <h2 className="blog-subtitle">Objectives: Save Time, Ensure Accuracy & Enhance Student Learning</h2>
        <p className="blog-paragraph">
          She teaches courses in systems biology, omics data analysis, and the use of databases, helping
          students navigate the integration of genomic data into their research. Her research integrates
          clinical and genomic data, with a focus on comorbidity studies and phenome-wide association studies
          (PheWAS).
        </p>
      </div>
      <div>
        <h2 className="blog-subtitle">Challenges</h2>
        <ul className="blog-list">
          <li className="blog-paragraph">
            <strong>Managing multiple databases:</strong> Searching ClinVar, UniProt, Orphanet and others is
            tedious and time-consuming.
          </li>
          <li className="blog-paragraph">
            <strong>Keeping up with new research:</strong> With 8,000+ disease genomics papers published
            monthly, staying current is impossible.
          </li>
          <li className="blog-paragraph">
            <strong>Ensuring data quality:</strong> Conflicting or low-quality data makes analysis difficult,
            leading to potential misinterpretation.
          </li>
          <li className="blog-paragraph">
            <strong>Teaching students with varying skill levels:</strong> Some struggle with tools like APIs
            or R, limiting engagement with complex data.
          </li>
          <li className="blog-paragraph">
            <strong>Prioritizing variants for analysis:</strong> High data volume and unclear allele
            frequencies make selection challenging.
          </li>
          <li className="blog-paragraph">
            <strong>Verifying data provenance:</strong> Cross-referencing sources to confirm reliability is
            time-consuming.
          </li>
          <li className="blog-paragraph">
            <strong>Finding population-specific data:</strong> Limited demographic information makes tailored
            analyses difficult.
          </li>
        </ul>
      </div>
      <div>
        <div className="my-5 space-y-5 ">
          <iframe
            // width="720"
            // height="405"
            src="https://www.youtube.com/embed/zFA9kGcwwyI?si=71N-8SGLwXGyBggm"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowfullscreen
            className="w-5/6 md:h-96"
            // className="w-96 h-56"
          ></iframe>
          <Calendly />
        </div>
        <h2 className="blog-subtitle">The Solution</h2>

        <h5 className="blog-sub-subtitle">A Single Platform</h5>
        <p className="blog-paragraph">
          DISGENET integrates a dozen curated databases, eliminating the laborious task of searching multiple
          sources.
        </p>
        <h5 className="blog-sub-subtitle">Always Up-To-Date</h5>
        <p className="blog-paragraph">
          Uses state-of-the-art NLP to extract, standardize, and update disease genomics findings from
          publications and clinical trials every quarter.
        </p>
        <h5 className="blog-sub-subtitle">Smart Data Filtering</h5>
        <p className="blog-paragraph">
          Metrics like the DISGENET Score rank associations by evidence quality, while the Evidence Index (EI)
          highlights contradictions in research.
        </p>
        <h5 className="blog-sub-subtitle">Accessible for All Skill Levels</h5>
        <p className="blog-paragraph">
          Beginners use the web interface, while advanced users leverage Cytoscape, REST API, or R package for
          automation.
        </p>
        <h5 className="blog-sub-subtitle">Better Variant Prioritization</h5>
        <p className="blog-paragraph">
          Alba uses DISGENET’s allele frequency and disease association data (e.g., gnomAD) to focus on
          high-impact variants.
        </p>
        <h5 className="blog-sub-subtitle">Simplified Data Provenance</h5>
        <p className="blog-paragraph">
          Tracks sources with 1.5M+ supporting publications, reducing the need for manual cross-referencing.
        </p>
        <h5 className="blog-sub-subtitle">Population-Specific Filtering</h5>
        <p className="blog-paragraph">
          The Ancestry filter (introduced in v25.1) enables tailored studies based on demographic data.
        </p>
      </div>
      <div>
        <p className="blog-quote">
          <span className="quote-left">&ldquo;</span>
          In our lab, it&apos;s all about integrating clinical and genomic data. We have information from many
          databases. So when we need to develop case studies that make clinical sense, DISGENET is a resource
          I use a lot. All day. It&apos;s on my tabs…Common tabs that you go back to!
          <span className="quote-right">&rdquo;</span>
          <span style={{ display: 'block', textAlign: 'right' }}>
            <strong>- Alba</strong>
          </span>
        </p>
      </div>
      <div>
        <h2 className="blog-subtitle">Results</h2>
        <h5 className="blog-sub-subtitle">Teaching Impact</h5>
        <p className="blog-paragraph">
          <strong>Hands-On Learning:</strong> Students apply theoretical concepts through real-world data
          analysis using SNP-phenotype associations and differential expression, preparing them for future
          bioinformatics roles.
        </p>
        <p className="blog-paragraph">
          <strong>Efficiency in Teaching:</strong> DISGENET streamlines data access, allowing Alba to focus on
          teaching instead of data aggregation, enhancing the quality of instruction and student experience.
        </p>
        <p className="blog-paragraph">
          <strong>Exposure to Industry-Standard Tools:</strong> Students gain experience with tools used in
          academic and industry bioinformatics research, equipping them for careers in research, clinical
          settings, or biotech.
        </p>
        <h5 className="blog-sub-subtitle">Research Impact</h5>
        <p className="blog-paragraph">
          <strong>Supporting Comorbidity Studies:</strong> DISGENET enables Alba to investigate genetic links
          between diseases that co-occur. By comparing genetic data with clinical phenotypes, Alba identifies
          shared genetic factors, even when genomic data is missing from electronic health records (EHRs) or
          claims data.
        </p>
        <p className="blog-paragraph">
          <strong>Facilitating Phenome-Wide Association Studies (PheWAS):</strong> DISGENET allows Alba’s team
          to prioritize high-confidence SNP-phenotype associations based on strong evidence and statistical
          significance, leading to novel insights into complex traits and genetic underpinnings of diseases.
        </p>
        <p className="blog-paragraph">
          <strong>Integration of Clinical and Genomic Data:</strong> DISGENET links clinical phenotypes with
          genomic variants, helping ground research in the most up-to-date literature and ensuring findings
          are informed by reliable data.
        </p>
        <p className="blog-paragraph">
          <strong>Refining Research Hypotheses:</strong> The platform cross-references genetic and variant
          data, validating Alba’s hypotheses and ensuring they are based on well-supported genomic
          associations.
        </p>
        <h5 className="blog-sub-subtitle">Institutional Benefits</h5>
        <p className="blog-paragraph">
          <strong>Enhanced Research Throughput and Cost Savings:</strong> Universities can reduce costs by
          consolidating multiple licenses with DISGENET’s platform, speeding up research and lowering
          time-to-discovery.
        </p>
        <p className="blog-paragraph">
          <strong>Future-Proofing Research:</strong> DISGENET’s evolving roadmap, including AI and big data
          integration, ensures it remains at the cutting edge of genomics and personalized medicine.
        </p>
      </div>
      <div className="flex flex-col">
        <h2 className="blog-subtitle"></h2>
        {/* <h5 className="blog-sub-subtitle">
          Start searching DISGENET{' '}
          <a href="https://disgenet.com/" rel="noopener noreferrer" target="_blank">
            here
          </a>{' '}
          (no account needed)
        </h5> */}
        <Calendly />
        <button className="btn-gradient-blue py-2" onClick={() => (window.location.href = '/')}>
          START SEARCHING
        </button>
      </div>
    </div>
  );
};

export default acceleratingresearchwithdisgenet;
