import { getChartData } from '../Common/HeatmapLineHelpers';
import { GDAModel } from '../../../../Models/GDAModel';
import { VDAModel } from '../../../../Models/VDAModel';
import { DDAModel } from '../../../../Models/DDAModel';
import { getRemainingPages } from '../Common/MultipleRequestsHelpers';
import { wholeNumberParams } from '../Common/Constants';

// structure of an individual heatmap item
class HeatmapItem {
  constructor(data, yVariable, mappingVariables) {
    this.id = data[mappingVariables[0]];
    this.data = [
      {
        x: data[mappingVariables[1]],
        y: data[yVariable],
        genes: mappingVariables[2] ? data[mappingVariables[2]] : '',
        chartVar: yVariable,
      },
    ];
  }
}

// information needed for constructing the HeatmapChart, depending on the tab selected
const uniqueIdentViewsVariables = {
  GDA: {
    model: GDAModel,
    secondReqIdent: {
      DISEASES: 'gene_ncbi_id',
      GENES: 'disease',
    },
    identList: {
      DISEASES: ['geneNcbiID'],
      GENES: ['diseaseUMLSCUI'],
    },
    mappingIdents: {
      DISEASES: ['diseaseName', 'geneSymbol'],
      GENES: ['geneSymbol', 'diseaseName'],
    },
    comparedValues: {
      DISEASES: ['Disease', 'Gene'],
      GENES: ['Gene', 'Disease'],
    },
  },
  VDA: {
    model: VDAModel,
    secondReqIdent: {
      DISEASES: 'variant',
      GENES: ['variant', 'disease'],
      VARIANTS: 'disease',
    },
    identList: {
      DISEASES: ['variantStrID'],
      GENES: ['variantStrID', 'diseaseUMLSCUI'],
      VARIANTS: ['diseaseUMLSCUI'],
    },
    mappingIdents: {
      DISEASES: ['diseaseName', 'variantStrID', 'geneSymbol'],
      GENES: ['diseaseName', 'variantStrID', 'geneSymbol'],
      VARIANTS: ['variantStrID', 'diseaseName', 'geneSymbol'],
    },
    comparedValues: {
      DISEASES: ['Disease', 'Variant'],
      GENES: ['Disease', 'Variant'],
      VARIANTS: ['Variant', 'Disease'],
    },
  },
  DDA: {
    model: DDAModel,
    secondReqIdent: {
      DISEASES: 'disease2',
    },
    identList: {
      DISEASES: ['disease2_UMLSCUI'],
    },
    mappingIdents: {
      DISEASES: ['disease1_Name', 'disease2_Name'],
    },
    comparedValues: {
      DISEASES: ['Disease 1', 'Disease 2'],
    },
  },
};

// logic for mapping the data from backend into a heatmap chart
function getHeatmapData(data, mappingVariables, model, yVariable) {
  const transformedHeatmapData = getChartData(data, yVariable, mappingVariables, model, HeatmapItem);
  return transformedHeatmapData;
}

function getLegendValues(selectedAttribute, data, pagingInfo, setLegendValues) {
  const isAttributeWholeNumber = wholeNumberParams.includes(selectedAttribute);

  const divergeValue = isAttributeWholeNumber ? 0.15 : 0.4;

  if (pagingInfo?.currentPageNumber === 0) {
    const maxValue = isAttributeWholeNumber ? data[0][selectedAttribute] : 1;

    setLegendValues(prevLegends => {
      return { ...prevLegends, max: maxValue, divergeAt: divergeValue };
    });
  }

  if (getRemainingPages(pagingInfo) === 0) {
    const minValue =
      isAttributeWholeNumber && data.length === 0
        ? 1
        : isAttributeWholeNumber
          ? data[data.length - 1][selectedAttribute]
          : 0;

    setLegendValues(prevLegends => {
      return { ...prevLegends, min: minValue };
    });
  }
}

function getAllArrays(originalArray, arrayToAppend) {
  const originalIds = originalArray.map(item => item.id);
  const commonItemIds = arrayToAppend.filter(item => originalIds.includes(item.id));
  const differentItemIds = arrayToAppend.filter(item => !originalIds.includes(item.id));

  return [commonItemIds, differentItemIds];
}

function getUniqueXValues(originalData, newData) {
  const originalXValues = new Set(originalData.map(({ x }) => x));
  const dataToAppend = newData.filter(({ x }) => !originalXValues.has(x));

  return dataToAppend;
}

function unifyArrays(originalArray, arrayToAppend) {
  if (originalArray.length === 0) {
    return arrayToAppend;
  }

  const [commonItemIds, differentItemIds] = getAllArrays(originalArray, arrayToAppend);

  originalArray.forEach(item => {
    for (let element of commonItemIds) {
      if (item.id === element.id) {
        const newUniqueXValues = getUniqueXValues(item.data, element.data);
        if (newUniqueXValues.length !== 0) {
          item.data.push(...newUniqueXValues);
        }
      }
    }
  });

  const finalArray = originalArray.concat(differentItemIds);
  return finalArray;
}

function getMarginPercent(numberOfParams) {
  if (numberOfParams > 80) {
    return 0.05;
  } else if (numberOfParams > 60) {
    return 0.1;
  } else if (numberOfParams > 40) {
    return 0.15;
  } else if (numberOfParams > 20) {
    return 0.2;
  } else if (numberOfParams > 10) {
    return 0.25;
  } else if (numberOfParams > 5) {
    return 0.3;
  } else if (numberOfParams > 3) {
    return 0.35;
  } else {
    return 0.4;
  }
}

function setHeatmapXMargins(width, identReqParam) {
  const numberOfParams = identReqParam[0].split(',').length;
  const marginPercent = getMarginPercent(numberOfParams);
  const xMargin = width * marginPercent < 100 ? 100 : width * marginPercent;

  return xMargin;
}

export { uniqueIdentViewsVariables, getHeatmapData, getLegendValues, unifyArrays, setHeatmapXMargins };
