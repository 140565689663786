import React, { useState } from 'react';
import { confirmResetPassword } from 'aws-amplify/auth';

export default function NewPassword({ setReset, name }) {
  const [errorMessage, setErrorMessage] = useState('');
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [rePassword, setRePassword] = useState('');
  const [isPassVisible, setIsPassVisible] = useState(false);
  const [isRePassVisible, setIsRePassVisible] = useState(false);

  const togglePassVisibility = () => setIsPassVisible(!isPassVisible);
  const toggleRePassVisibility = () => setIsRePassVisible(!isRePassVisible);

  const validar = () => {
    if (!code.trim()) {
      setErrorMessage('Verification code is required');
      return false;
    }
    if (!password.trim()) {
      setErrorMessage('Password is required');
      return false;
    }
    if (password !== rePassword) {
      setErrorMessage('Password and their confirmation do not match');
      return false;
    }
    return true;
  };

  const handleSubmit = async evt => {
    evt.preventDefault();
    setErrorMessage('');

    if (!validar()) return;

    try {
      await confirmResetPassword({
        username: name.trim(),
        confirmationCode: code.trim(),
        newPassword: password.trim(),
      });
      setReset(false);
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  return (
    <div className="container txt-contenido">
      <div className="login-container">
        <div className="signin-container">
          <div className="col-12">
            <form className="validate-form" onSubmit={handleSubmit} autoComplete="off">
              <h5 style={{ color: 'white', textAlign: 'center', paddingBottom: '15px' }}>
                Check your email for the confirmation code:
              </h5>

              <div className="input-container">
                <label className="label-input100" style={{ color: 'white' }}>
                  Confirmation Code:
                </label>
                <div className="line-input">
                  <input
                    style={{ color: 'white' }}
                    onChange={e => setCode(e.target.value)}
                    className="input100"
                    type="text"
                    placeholder="Enter the confirmation code..."
                    autoComplete="off"
                  />
                </div>
              </div>

              <div className="input-container">
                <label className="label-input100 pr-2" style={{ color: 'white' }}>
                  Password:
                </label>
                <span className="eye-icon" onClick={togglePassVisibility}>
                  <i
                    className={`fa ${isPassVisible ? 'fa-eye' : 'fa-eye-slash'}`}
                    style={{ color: 'white' }}
                    aria-hidden="true"
                  />
                </span>
                <div className="line-input" style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    onChange={e => setPassword(e.target.value)}
                    type={isPassVisible ? 'text' : 'password'}
                    className="input100"
                    placeholder="Password"
                    autoComplete="new-password"
                    style={{ flex: 1 }}
                  />
                </div>
              </div>

              <div className="input-container">
                <label className="label-input100 pr-2" style={{ color: 'white' }}>
                  Retype Password:
                </label>
                <span className="eye-icon" onClick={toggleRePassVisibility}>
                  <i
                    className={`fa ${isRePassVisible ? 'fa-eye' : 'fa-eye-slash'}`}
                    style={{ color: 'white' }}
                    aria-hidden="true"
                  />
                </span>
                <div className="line-input" style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    style={{ color: 'white', flex: 1 }}
                    onChange={e => setRePassword(e.target.value)}
                    type={isRePassVisible ? 'text' : 'password'}
                    className="input100"
                    placeholder="Retype Password"
                    autoComplete="new-password"
                  />
                </div>
              </div>

              <a
                style={{ float: 'right', marginBottom: '20px', color: 'white', cursor: 'pointer' }}
                onClick={() => setReset(false)}
              >
                Cancel
              </a>

              <div className="container-contact100-form-btn">
                <div className="wrap-contact100-form-btn">
                  <div className="contact100-form-bgbtn" />
                  <button className="contact100-form-btn">Send</button>
                </div>
              </div>

              {errorMessage && <p className="text-center">{errorMessage}</p>}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
