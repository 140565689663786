import React from 'react';
import { userAppContext } from '../../Context/Context';
import { USER_ROLES_AWS_MAP } from '../Constants';

const allProfiles = [
  {
    type: 'TRIAL',
    limitAPI: {
      minute: '10',
      day: '10',
      month: '30',
    },
    limitWeb: {
      minute: '60',
      day: '1000',
      month: '10,000',
    },
  },
  {
    type: 'ACADEMIC',
    limitAPI: {
      minute: '50',
      day: '25,000',
      month: '500,000',
    },
    limitWeb: {
      minute: '60',
      day: '1000',
      month: '10,000',
    },
  },
  {
    type: 'ACADEMIC_REVIEW',
    limitAPI: {
      minute: '50',
      day: '25,000',
      month: '500,000',
    },
    limitWeb: {
      minute: '60',
      day: '1000',
      month: '10,000',
    },
  },
  {
    type: 'STANDARD',
    limitWeb: {
      minute: '60',
      day: '1000',
      month: '10,000',
    },
  },
  {
    type: 'ADVANCED',
    limitAPI: {
      minute: '100',
      day: '50,000',
      month: '1.5M',
    },
    limitWeb: {
      minute: '60',
      day: '1000',
      month: '10,000',
    },
  },
  {
    type: 'PREMIUM',
    limitAPI: {
      minute: '100',
      day: '50,000',
      month: '1.5M',
    },
    limitWeb: {
      minute: '60',
      day: '1000',
      month: '10,000',
    },
  },
  {
    type: 'DEVELOPER',
  },
];

function QueriesLimitTable() {
  const { groups } = userAppContext();
  const userRole = USER_ROLES_AWS_MAP.get(groups?.[0]);

  const profileInfo = allProfiles.filter(profile => profile.type === userRole)[0];

  const timeRanges = ['minute', 'day', 'month'];
  const profilesRestApiDifferentOutput = ['DEVELOPER', 'STANDARD'];

  function getQueriesPer(profile, source, timeRange) {
    return `${profile[source][timeRange]} queries/${timeRange}`;
  }

  return (
    <div className="table-responsive">
      {profileInfo?.type && (
        <table className="table pure-table text-center">
          <thead>
            <tr>
              <th scope="col">User Profile</th>
              <th scope="col">API queries limits</th>
              <th scope="col">Web queries limits</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{profileInfo?.type}</td>
              <td>
                <ul>
                  {profileInfo?.type === 'STANDARD' && <p>No access to Rest API</p>}
                  {profileInfo?.type === 'DEVELOPER' && <p>Infinite requests</p>}
                  {!profilesRestApiDifferentOutput.includes(profileInfo.type) &&
                    timeRanges.map(range => {
                      return <li key={`api${range}`}>{getQueriesPer(profileInfo, 'limitAPI', range)}</li>;
                    })}
                </ul>
              </td>
              <td>
                <ul>
                  {profileInfo?.type === 'DEVELOPER' && <p>Infinite requests</p>}
                  {profileInfo?.type !== 'DEVELOPER' &&
                    timeRanges.map(range => {
                      return <li key={`web${range}`}>{getQueriesPer(profileInfo, 'limitWeb', range)}</li>;
                    })}
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
}

export default QueriesLimitTable;
