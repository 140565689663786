import { React, useEffect, useState } from 'react';
import { patchItems } from '../Patches/PatchSearchItems';
import { DATASETS } from '../Constants';
import { searchContext } from '../../Context/Context';

const SectionItem = ({ item }) => {
  // patch item until BE is upgraded
  item = patchItems(item);

  // state objects
  const [isChecked, setIsChecked] = useState(false);
  const { searchItems, setSearchItems } = searchContext();

  // Name
  let strName;
  if (item.dataset === DATASETS.DISEASES) {
    strName = 'Name: ' + item.name + ', CUI: ' + item.id;
  }
  if (item.dataset === DATASETS.GENES) {
    strName = 'Symbol: ' + item.symbol + ', Description: ' + item.description + ', EntrezId: ' + item.ncbiID;
  }
  if (item.dataset === DATASETS.VARIANTS) {
    strName = 'SNPdb id: ' + item.strID + ', Gene Symbol: ' + item.geneSymbol;
  }
  if (item.dataset === DATASETS.CHEMICALS) {
    strName = 'Name: ' + item.chemPrefName + ', ID: ' + item.chemID;
  }

  // Count
  let strCount;
  if (item.dataset === DATASETS.DISEASES) {
    strCount =
      'N. genes: ' +
      item.numGenesAssociatedToDisease_ALL +
      ', N. snps: ' +
      item.numVariantsAssociatedToDisease_ALL;
  }
  if (item.dataset === DATASETS.GENES) {
    strCount =
      'N. diseases: ' +
      item.numDiseasesAssociatedToGene_ALL +
      ', N. snps: ' +
      item.numVariantsAssociatedToGene_ALL;
  }
  if (item.dataset === DATASETS.VARIANTS) {
    strCount = 'N. diseases: ' + item.numDiseasesAssociatedToVariant_ALL;
  }
  if (item.dataset === DATASETS.CHEMICALS) {
    strCount = 'N. PMIDS: ' + item.chemNumPublications;
  }

  // // Match info
  // const strMatch = 'Match: ' + item.highlightFields[1].replaceAll('<em>', '').replaceAll('</em>', '');

  // Match info

  // const strMatch = 'Match: ' + item.highlightFields[1].replaceAll('<em>', '').replaceAll('</em>', '');

  // Match info

  let strMatch = 'Match: ';
  if (item.highlightFields && item.highlightFields[1]) {
    strMatch += item.highlightFields[1].replaceAll('<em>', '').replaceAll('</em>', '');
  } else {
    strMatch += 'No match information available';
  }

  // handle checkbox click + item click
  const handleClick = (event, item) => {
    // update searchItems state
    // behavior: add if not in list, remove if in list
    // built on the .id parameter
    setSearchItems(prevItems =>
      prevItems.some(prevItem => prevItem.id === item.id)
        ? prevItems.filter(prevItem => prevItem.id !== item.id)
        : [...prevItems, item],
    );
  };

  useEffect(() => {
    // Checkbox state is based on searchItems state
    setIsChecked(searchItems.some(prevItem => prevItem.id === item.id));
  }, [searchItems]);

  return (
    <li className="react-dropdown__suggestion" onClick={event => handleClick(event, item)}>
      {strName}
      <br />
      {strCount}
      <br />
      {strMatch}
      <input type="checkbox" checked={isChecked} readOnly />
      <span className="checkmark" />
    </li>
  );
};

export default SectionItem;
